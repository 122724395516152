import React, { useState } from 'react';
import api from '../../../services/api';

import {
  Label,
  SelectMult,
  ActionButton,
  InputNumberField,
  UpdateError,
} from '../../../components';

import { conveniosValidator } from '../../../utils/detalhesValidator';

import * as S from './styles';

import * as I from './interface';

const DetalhesConvenios: React.FC<I.Props> = ({
  id,
  valor,
  temConvenio,
  convenios,
  readOnly,
  conveniosList,
  fetch,
}) => {
  const [load, setLoad] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [updateError, setUpdateError] = useState({
    error: false,
    message: '',
  });

  const [data, setData] = useState<I.Data>({
    valor,
    temConvenio,
    convenios,
  });

  const [errorData, setErrorData] = useState<I.ErrorData>(Object);

  const setOriginalData = () => {
    setData({
      valor,
      temConvenio,
      convenios,
    });
    setErrorData(Object());
    setUpdateError({ error: false, message: '' });
  };

  const save = async () => {
    setLoad(true);
    if (await conveniosValidator(data!, setErrorData)) {
      api
        .put(`/medicos/convenios/${id}`, {
          convenios: data.convenios.map((c) => ({
            convenio: c,
          })),
          valor_padrao: data.valor,
        })
        .then(() => {
          setEditing(false);
          setLoad(false);
          fetch();
        })
        .catch(() => {
          setUpdateError({
            error: true,
            message:
              'Erro na atualização dos dados. Preencha os campos corretamente e tente novamente!',
          });
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  const handleChange = (field: any, value: any) => {
    setErrorData({ ...errorData, [field]: '' });
    setData!({ ...data!, [field]: value });
  };

  const toggleConvenio = (e: React.ChangeEvent<HTMLInputElement>) => {
    return e.target.checked
      ? setData!({ ...data!, temConvenio: true })
      : setData!({ ...data!, temConvenio: false, convenios: [] });
  };

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Title>Particular &amp; Convênios</S.Title>
          <S.Description>
            Abaixo estão as opções de consulta particular e de convênios do
            médico selecionado.
          </S.Description>
        </S.TitleContainer>
        {!readOnly && (
          <S.ButtonContainer>
            {!editing ? (
              <ActionButton
                onClick={() => setEditing(true)}
                value="Editar"
                primary
              />
            ) : (
              <>
                {updateError.error && (
                  <UpdateError message={updateError.message} />
                )}
                <ActionButton
                  onClick={() => {
                    setOriginalData();
                    setEditing(false);
                  }}
                  value="Cancelar"
                />
                <ActionButton
                  onClick={save}
                  value="Salvar"
                  primary
                  loading={load}
                />
              </>
            )}
          </S.ButtonContainer>
        )}
      </S.Header>

      <S.PartConvContainer>
        <S.ContentRow>
          <InputNumberField
            id="valor"
            value={data.valor}
            onChange={({ value }) => handleChange('valor', value)}
            placeholder="R$ 0,00"
            labelText="Valor"
            width="109px"
            invalid={errorData!.valor}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            isDisabled={!editing}
          />
        </S.ContentRow>

        <S.CheckboxContainer>
          <S.Checkbox
            checked={data.temConvenio}
            type="checkbox"
            name="convenio"
            id="convenio"
            onChange={toggleConvenio}
            disabled={!editing}
          />
          <Label id="convenio" labelText="Atendimento com convênios" />
        </S.CheckboxContainer>

        {data.temConvenio && (
          <S.ContentRow>
            <SelectMult
              id="convOptions"
              value={data.convenios}
              onChangeValue={(opts) => handleChange('convenios', opts)}
              labelText="Convênios atendidos"
              options={conveniosList ?? []}
              invalid={errorData!.convenios}
              isDisabled={!editing}
            />
          </S.ContentRow>
        )}
      </S.PartConvContainer>
    </S.Container>
  );
};

export default DetalhesConvenios;
