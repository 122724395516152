import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import api from '../../services/api';

import {
  DetalhesAvancados,
  DetalhesConvenios,
  DetalhesAtendimento,
  DetalhesDadosBasicosAdm,
  DetalhesEspecialidades,
  DetalhesExperienciaFormacao,
} from '../../domain/Detalhes';

import SkeletonDadosBasicos from '../../domain/Detalhes/DetalhesDadosBasicos/skeleton';

import * as S from './styles';

const DetalhesAdm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState('dadosBasicos');

  const [data, setData] = useState({
    idMedico: useParams<{ id: string }>().id,
    avatar: '',
    conselho: '',
    estado: '',
    numeroRegistro: '',
    nomeCompleto: '',
    cpfMedico: '',
    rgMedico: '',
    telefoneContato: '',
    emailContato: '',
    pessoaContato: '',
    clinica: '',
    cnpj: '',
    emailResponsavel: '',
    emailSecundario: '',
    dom: false,
    seg: false,
    ter: false,
    qua: true,
    qui: false,
    sex: false,
    sab: false,
    rua: '',
    numero: '',
    bairro: '',
    uf: '',
    cidade: '',
    cep: '',
    telefone_cel: '',
    telefone_fixo: '',
    from: '',
    to: '',
    duration: '',
    experiencias: [{ titulo: '', descricao: '', ano: '' }],
    formacoes: [{ instituicao: '', curso: '', ano: '' }],
    especialidades: [{ especialidade: '', rqe: '', favorita: true }],
    valor: '',
    temConvenio: true,
    convenios: [''],
    pendente: false,
    ativo: false,
    dataInclusao: moment().clone(),
  });

  const fetch = () => {
    setLoading(true);
    api.get(`/medicos/${data.idMedico}`).then(async ({ data: medico }) => {
      let avatar = '';

      await api
        .get(`/avatar/${data.idMedico}`)
        .then(({ data: { file_url } }) => {
          avatar = file_url;
        })
        .catch(() => {
          avatar = '';
        });

      setData((state) => ({
        ...state,
        ...medico,
        avatar,
        from: medico.from.replaceAll(':', '').slice(0, 4),
        to: medico.to.replaceAll(':', '').slice(0, 4),
        duration: medico.duration.toString(),
        dataInclusao: moment(medico.dataInclusao),
        pendente: medico.is_pending,
        ativo: medico.is_active,
      }));
      setLoading(false);
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetail = () => {
    switch (option) {
      case 'dadosBasicos':
        return loading ? (
          <SkeletonDadosBasicos />
        ) : (
          <DetalhesDadosBasicosAdm
            id={data.idMedico}
            readOnly
            avatar={data.avatar}
            conselho={data.conselho}
            estado={data.estado}
            numeroRegistro={data.numeroRegistro}
            nomeCompleto={data.nomeCompleto}
            cpfMedico={data.cpfMedico}
            rgMedico={data.rgMedico}
            emailContato={data.emailContato}
            telefoneContato={data.telefoneContato}
            pessoaContato={data.pessoaContato}
            clinica={data.clinica}
            cnpj={data.cnpj}
            endereco={`${data.rua}, ${data.numero}, ${data.bairro} - ${data.cidade}, ${data.uf}`}
            email={data.emailResponsavel}
            emailSecundario={data.emailSecundario}
            telefone_cel={data.telefone_cel}
            telefone_fixo={data.telefone_fixo}
            fetch={() => {}}
            especialidades={data.especialidades}
            rua={data.rua}
            numero={data.numero}
            bairro={data.bairro}
            uf={data.uf}
            cidade={data.cidade}
            cep={data.cep}
          />
        );
      case 'atendimento':
        return (
          <DetalhesAtendimento
            readOnly
            dom={data.dom}
            seg={data.seg}
            ter={data.ter}
            qua={data.qua}
            qui={data.qui}
            sex={data.sex}
            sab={data.sab}
            from={data.from}
            to={data.to}
            duration={data.duration}
            fetch={() => {}}
          />
        );
      case 'experienciaFormacao':
        return (
          <DetalhesExperienciaFormacao
            readOnly
            experiencias={data.experiencias}
            formacoes={data.formacoes}
            fetch={() => {}}
          />
        );
      case 'especialidades':
        return (
          <DetalhesEspecialidades
            especialidades={data.especialidades}
            readOnly
            fetch={() => {}}
            edit={false}
            saving={false}
            setSaving={() => {}}
            setEdit={() => {}}
            setLoading={() => {}}
          />
        );
      case 'convenios':
        return (
          <DetalhesConvenios
            readOnly
            valor={data.valor}
            temConvenio={data.temConvenio}
            convenios={data.convenios}
            fetch={() => {}}
          />
        );
      case 'avancado':
        return (
          <DetalhesAvancados
            idMedico={data.idMedico}
            pendente={data.pendente}
            ativo={data.ativo}
            dataInclusao={data.dataInclusao}
            fetch={fetch}
          />
        );
      default:
        return <h2>Erro inesperado. Recarregue a página!</h2>;
    }
  };

  return (
    <S.Container>
      <S.Sidebar>
        <S.Header>
          <S.StyledLink to="/admin/especialistas">
            <S.IconGoBack />
            Voltar
          </S.StyledLink>
        </S.Header>
        {/* <S.Details>{id} - Dr. John Doe</S.Details> */}
        <S.Menu>
          <S.MenuItem
            onClick={() => !loading && setOption('dadosBasicos')}
            selected={option === 'dadosBasicos'}>
            Dados básicos
          </S.MenuItem>
          <S.MenuItem
            onClick={() => !loading && setOption('atendimento')}
            selected={option === 'atendimento'}>
            Atendimento
          </S.MenuItem>
          <S.MenuItem
            onClick={() => !loading && setOption('experienciaFormacao')}
            selected={option === 'experienciaFormacao'}>
            Experiência & Formação
          </S.MenuItem>
          {/* <S.MenuItem
            onClick={() => !loading && setOption('especialidades')}
            selected={option === 'especialidades'}>
            Especialidades
          </S.MenuItem> */}
          <S.MenuItem
            onClick={() => !loading && setOption('convenios')}
            selected={option === 'convenios'}>
            Convênios
          </S.MenuItem>
          <S.MenuItem
            onClick={() => !loading && setOption('avancado')}
            selected={option === 'avancado'}>
            Avançado
          </S.MenuItem>
        </S.Menu>
      </S.Sidebar>
      {renderDetail()}
    </S.Container>
  );
};

export default DetalhesAdm;
