import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const ConselhoButton: React.FC<I.Props> = ({ value, ...res }) => {
  return (
    <S.Button {...res} onClick={() => {}}>
      <S.Content>
        <S.Icon />
        <S.Text>{value}</S.Text>
      </S.Content>
      {/* <S.IconArrow /> */}
    </S.Button>
  );
};

export default ConselhoButton;
