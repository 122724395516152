import React, { useState } from 'react';
import ReactDom from 'react-dom';
import api from '../../../services/api';

import { LoadingSpinner } from '../../../components';

import * as S from './styles';

interface Props {
  id?: string;
  closeModal: () => void;
  fetch: () => void;
}

const ModalAprovarCadastro: React.FC<Props> = ({ id, closeModal, fetch }) => {
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    await api
      .put('/medicos/approve', {
        id_medico: id,
      })
      .then(() => {
        fetch();
        closeModal();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer>
        <S.Content>
          <S.Header>
            <S.Title>Aprovar cadastro</S.Title>
            {!loading ? (
              <S.Text>
                Tem certeza que gostaria de aprovar esse cadastro? <br />O
                médico passará a ser exibido nas buscas do aplicativo.
              </S.Text>
            ) : (
              'Processando...'
            )}
          </S.Header>
        </S.Content>
        <S.LinkContainer>
          {!loading ? (
            <>
              <S.Link onClick={closeModal}>Voltar</S.Link>
              <S.Link onClick={confirm} primary>
                Aprovar
              </S.Link>
            </>
          ) : (
            <LoadingSpinner color="#37d363" />
          )}
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalAprovarCadastro;
