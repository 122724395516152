import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import 'moment/locale/pt-br';

import ActionButton from '../../components/ActionButton';

import {
  ModalData,
  ModalEspec,
  ModalPrint,
  ModalStatus,
} from '../../domain/Modal';

import {
  RelatorioTabela,
  SkeletonRelatorioTabela,
} from '../../domain/Relatorio';

import * as S from './styles';

import * as I from './interface';
import TableWarning from '../../components/TableWarning';
import api from '../../services/api';

const Relatorio: React.FC = () => {
  const containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  const tableRef: React.RefObject<HTMLTableElement> = React.createRef();

  const [loading, setLoading] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [specList, setSpecList] = useState([]);

  const [data, setData] = useState<I.Data[]>([]);

  const [pagination, setPagination] = useState({
    current: 1,
    maxItensPerPage: 7,
    pageLimit: 5,
  });

  const [filterOptions, setFilterOptions] = useState<I.Filter>({
    data: {
      label: '',
      de: '',
      ate: '',
    },
    status: [],
    especialista: '',
  });

  const [modals, setModals] = useState<{ [key: string]: any }>({
    data: {
      id: 0,
      state: false,
      top: 0,
      left: 0,
    },
    status: {
      id: 1,
      state: false,
      top: 0,
      left: 0,
    },
    especialista: {
      id: 2,
      state: false,
      top: 0,
      left: 0,
    },
    print: {
      id: 3,
      state: false,
    },
  });

  const [filteredData, setFilteredData] = useState<any>([...data]);

  const toggleModal = (
    type: string,
    id?: number,
    top?: number,
    left?: number,
  ) => {
    return id
      ? setModals({
          ...modals,
          [type]: {
            id,
            state: !modals[type].state,
            top,
            left,
          },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    pageStyle: `@page {
      size: auto;
      margin: 25mm 10mm 10mm 10mm;
    }`,
  });

  const formattedDate = (date: string) => moment(date, 'DDMMYYYY').clone();

  const formatConvenio = (convenio: string) => {
    if (convenio !== 'null') return `Convenio - ${convenio}`;
    return 'Particular';
  };

  useEffect(() => {
    const newData = data
      // Filtragem por data
      .filter((consulta) => {
        const start = formattedDate(filterOptions.data.de);
        const end = formattedDate(filterOptions.data.ate);
        return start.isValid() && end.isValid()
          ? moment(consulta.data).isBetween(start, end, 'day', '[]')
          : true;
      })
      // Filtragem por status
      .filter((consulta) =>
        filterOptions.status.length !== 0
          ? filterOptions.status.includes(consulta.status)
          : true,
      )
      // Filtragem por especialista
      .filter((consulta) =>
        filterOptions.especialista !== ''
          ? consulta.especialista === filterOptions.especialista
          : true,
      );

    setFilteredData(newData);
    setPagination((state) => ({ ...state, current: 1 }));
  }, [filterOptions, data]);

  const fetchData = () => {
    setLoading(true);
    setConnectionError(false);
    api
      .get('/medicos/spec-list')
      .then(({ data: medicos }) =>
        setSpecList(
          medicos.map(
            (m: { nome_completo: any }) => `Dr(a). ${m.nome_completo}`,
          ),
        ),
      );
    api
      .get('/consult/report')
      .then(({ data: consultas }) => {
        setData([]);

        if (consultas.length > 0) {
          consultas.forEach((consulta: any) => {
            if (consulta.novaDataConsulta != null) {
              consulta.dataConsulta = consulta.novaDataConsulta;
            }

            const {
              id,
              data: dataConsulta,
              novaData: novaDataConsulta,
              paciente,
              especialista,
              especialidades,
              status,
              atendimento,
              valor_inicial: valorInicial,
              valor_final: valorFinal,
            } = consulta;

            setData((state) => [
              ...state,
              {
                id,
                data: novaDataConsulta
                  ? moment(novaDataConsulta)
                  : moment(dataConsulta), // .format('DD [de] MMMM, HH:mm'),
                paciente,
                especialista: `Dr(a). ${especialista}`,
                especialidade: `${especialidades[0]}${
                  especialidades[1] ? ` (${especialidades[1]})` : ''
                }`,
                status,
                valorFinal,
                valorInicial,
                atendimento: formatConvenio(atendimento),
              },
            ]);
          });
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setConnectionError(true);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container ref={containerRef}>
      <S.Head>
        <S.Header>
          <S.TitleContainer>
            <S.Headline>
              <S.Icon />
              <S.Title>Relatório de Consultas</S.Title>
            </S.Headline>
            <S.Description>
              Aqui você pode encontrar todas as consultas já finalizadas, sejam
              elas efetuadas, canceladas ou aquelas em que o paciente não
              compareceu.
            </S.Description>
          </S.TitleContainer>
          <S.ButtonContainer disabled={filteredData.length < 1}>
            <ActionButton
              // onClick={() => filteredData.length > 0 && handlePrint!()}
              onClick={() => toggleModal('print')}
              value="Imprimir"
            />
          </S.ButtonContainer>
        </S.Header>

        <S.FilterContainer>
          <S.Filter
            active={
              modals.data.state ||
              (formattedDate(filterOptions.data.de).isValid() &&
                formattedDate(filterOptions.data.ate).isValid())
            }
            onClick={({ currentTarget: { offsetTop, offsetLeft } }) =>
              toggleModal(
                'data',
                1,
                offsetTop - (containerRef.current?.scrollTop ?? 0),
                offsetLeft - (containerRef.current?.scrollLeft ?? 0),
              )
            }>
            <S.IconData />
            {filterOptions.data.label.trim() !== ''
              ? filterOptions.data.label
              : 'Qualquer Data'}
            <S.IconDown />
          </S.Filter>

          <S.Filter
            active={modals.status.state || filterOptions.status.length > 0}
            onClick={({ currentTarget: { offsetTop, offsetLeft } }) =>
              toggleModal(
                'status',
                1,
                offsetTop - (containerRef.current?.scrollTop ?? 0),
                offsetLeft - (containerRef.current?.scrollLeft ?? 0),
              )
            }>
            <S.IconFilter />
            {filterOptions.status.length > 0
              ? filterOptions.status.join(', ')
              : 'Status'}
            <S.IconDown />
          </S.Filter>
          <S.Filter
            active={
              modals.especialista.state ||
              filterOptions.especialista.trim() !== ''
            }
            onClick={({ currentTarget: { offsetTop, offsetLeft } }) =>
              toggleModal(
                'especialista',
                1,
                offsetTop - (containerRef.current?.scrollTop ?? 0),
                offsetLeft - (containerRef.current?.scrollLeft ?? 0),
              )
            }>
            <S.IconUser />
            {filterOptions.especialista.trim() !== ''
              ? filterOptions.especialista
              : 'Especialista'}
            <S.IconDown />
          </S.Filter>
        </S.FilterContainer>
      </S.Head>

      {loading && (
        <S.Main>
          <SkeletonRelatorioTabela />
        </S.Main>
      )}

      {!loading &&
        // eslint-disable-next-line no-nested-ternary
        (filteredData.length === 0 ? (
          connectionError ? (
            <TableWarning noConnection />
          ) : (
            <TableWarning noData />
          )
        ) : (
          <S.Main>
            <RelatorioTabela
              tableRef={tableRef}
              consultas={filteredData}
              current={pagination.current}
              maxItensPerPage={pagination.maxItensPerPage}
              enablePagination={false}
            />
          </S.Main>
        ))}

      <AnimatePresence>
        {modals.data.state && (
          <ModalData
            id={modals.data.id}
            top={modals.data.top}
            left={modals.data.left}
            closeModal={() => toggleModal('data')}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
        )}
        {modals.status.state && (
          <ModalStatus
            id={modals.status.id}
            top={modals.status.top}
            left={modals.status.left}
            closeModal={() => toggleModal('status')}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
        )}
        {modals.especialista.state && (
          <ModalEspec
            id={modals.especialista.id}
            top={modals.especialista.top}
            left={modals.especialista.left}
            closeModal={() => toggleModal('especialista')}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            options={specList}
          />
        )}
        {modals.print.state && (
          <ModalPrint
            tableRef={tableRef}
            tabela={
              <RelatorioTabela
                tableRef={tableRef}
                consultas={filteredData}
                current={pagination.current}
                maxItensPerPage={pagination.maxItensPerPage}
                enablePagination={false}
              />
            }
            type="relatorio"
            closeModal={() => toggleModal('print')}
            handlePrint={() => filteredData.length > 0 && handlePrint!()}
            filterOptions={filterOptions}
          />
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default Relatorio;
