import React from 'react';

import { InputNumberField, InputField, DelButton } from '..';

import * as S from './styles';

import * as I from './interface';

const Formacao: React.FC<I.Props> = ({
  index,
  isDisabled = false,
  formacao,
  data,
  setData,
  errorData,
  setErrorData,
}) => {
  const { formacoes } = data!;

  const error = errorData!.formacoes[index];

  const handleChange = (field: string, value: any) => {
    setData({
      ...data,
      formacoes: formacoes.map((exp) => {
        if (exp === formacao) exp[field] = value;
        return exp;
      }),
    });
    setErrorData!((state: any) => ({
      ...state,
      formacoes: [
        ...state.formacoes.slice(0, index),
        {
          ...state.formacoes[index],
          [field]: '',
        },
        ...state.formacoes.slice(index + 1),
      ],
    }));
  };

  return (
    <S.Container>
      <InputField
        id={`instituicao-${index}`}
        value={formacoes[index].instituicao}
        onChange={(e) => handleChange('instituicao', e.target.value)}
        placeholder="Nome da instituição..."
        labelText="Instituição de ensino"
        width="190px"
        invalid={error ? error.instituicao : ''}
        isDisabled={isDisabled}
      />

      <InputField
        id={`curso-${index}`}
        value={formacoes[index].curso}
        onChange={(e) => handleChange('curso', e.target.value)}
        placeholder="Digite o curso realizado..."
        labelText="Curso"
        width="180px"
        invalid={error ? error.curso : ''}
        isDisabled={isDisabled}
      />

      <InputNumberField
        id={`anoForm-${index}`}
        value={formacoes[index].ano}
        onChange={({ value }) => handleChange('ano', value)}
        placeholder="1999"
        labelText="Ano"
        width="58px"
        invalid={error ? error.ano : ''}
        format="####"
        mask="_"
        isDisabled={isDisabled}
      />

      {formacoes.length > 1 && !isDisabled && (
        <DelButton
          onClick={() => {
            setData({
              ...data,
              formacoes: formacoes.filter((e) => e !== formacao),
            });
            setErrorData!({
              ...errorData!,
              formacoes: errorData!.formacoes.filter((e, i) => i !== index),
            });
          }}
        />
      )}
    </S.Container>
  );
};

export default Formacao;
