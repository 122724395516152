import styled, { css } from 'styled-components';

import { VscHome } from 'react-icons/vsc';
import { BiCalendar, BiClipboard } from 'react-icons/bi';
import { RiDashboardLine } from 'react-icons/ri';

interface Selected {
  selected?: boolean;
}

const Icon = css`
  width: 20px;
  height: 20px;
  fill: #ffffff;
`;

export const Container = styled.div`
  width: 226px;
  height: 100vh;
  background-color: #fafafa;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Header = styled.header`
  height: 40;
  width: (100% - 2 * 24px);
  padding: 24px;
  background-color: #212c3b;
`;

export const Content = styled.div`
  padding: 24px;
  height: 100%;
  background-color: #2a3748;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MenuItems = styled.ul`
  list-style: none;
  padding: 0;

  a {
    text-decoration: none;
  }
`;

export const Label = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 12px;
`;

export const MenuItem = styled.li<Selected>`
  width: calc(100% + 24px - 3px);
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  opacity: ${({ selected }) => (selected ? '1' : '0.5')};
  border-right: ${({ selected }) => (selected ? '3px solid white' : 'none')};
  transition: all 0.2s ease-in;
`;

export const Logo = styled.img`
  width: calc(116px * 1);
  height: calc(40px * 1);
`;

export const IconInicio = styled(VscHome)`
  ${Icon};
`;

export const IconConsultas = styled(BiCalendar)`
  ${Icon};
`;

export const IconRelatorio = styled(BiClipboard)`
  ${Icon};
`;

export const IconConfig = styled(RiDashboardLine)`
  ${Icon};
`;

export const TermsPolicyContainer = styled.a`
  width: 100%;
  height: 15px;
  font-size: 12px;
  color: #fff;
  opacity: 0.5;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
