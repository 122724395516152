import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const LoadingSpinner: React.FC<I.Props> = ({ color }) => {
  return (
    <S.SVG viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <S.Circle cx="50" cy="50" r="45" color={color} />
    </S.SVG>
  );
};

export default LoadingSpinner;
