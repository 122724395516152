import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  height: fit-content;
  background-color: white;
  border-radius: 8px;
`;

export const SubTitle = styled.div`
  width: 100%;
  height: fit-content;
  font-size: 14px;
  margin: 16px 0 8px 0;
  color: #333333;
  font-weight: bold;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 0 0 0;

  button {
    margin: 0 0 0 8px;
    width: fit-content;
    white-space: nowrap;
    padding: 0 16px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  height: fit-content;
  padding: 0 30px 14px 0;
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  height: fit-content;
  padding: 14px 30px 30px 30px;
  border-top: 1px solid #e9e9e9;
`;
