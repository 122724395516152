import styled from 'styled-components';
import { BiCalendar } from 'react-icons/bi';
import ActionButton from '../../../components/ActionButton';

export const Container = styled.div`
  width: calc(100% - 64px);
  height: calc(100% - 64px);
  padding: 32px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  color: #2a3748;
  margin: 0 0 16px 0;
`;

export const Description = styled.h4`
  max-width: 470px;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 6px 0;
  color: rgba(0, 0, 0, 0.6);
`;

export const ApprovalContainer = styled.div`
  width: 546px;
  height: 151px;
  padding: 24px;
  margin-top: 24px;
  border-radius: 4px;
  border: 1px solid #2fa8d5;
  background-color: #f2fafc;
  display: flex;
  flex-direction: column;
`;

export const ApprovalTitle = styled.p`
  color: #2fa8d5;
  font-weight: bold;
  margin: 0 0 16px 0;
`;

export const ApprovalDescription = styled(Description)`
  color: #11111170;
`;

export const ApprovalAcceptButton = styled(ActionButton)`
  background-color: #37d363;
  width: fit-content;
  height: 40px;
  margin: 16px 8px 0 0;
  color: white;
  opacity: 0.8;
  padding: 0 16px;

  &:hover {
    background-color: #37d363;
    opacity: 0.9;
  }
  &:active {
    background-color: #37d363;
    opacity: 1;
  }
`;

export const ApprovalDenyButton = styled(ApprovalAcceptButton)`
  background-color: #f03434;

  &:hover,
  &:active {
    background-color: #f03434;
  }
`;

export const ContentRow = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  /* margin-top: 46px; */
`;

export const DeactivateProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const DateContainer = styled.div`
  position: relative;
  margin-bottom: 32px;

  input {
    padding: 0 14px 0 38px !important;
  }
`;

export const IconCalendar = styled(BiCalendar)`
  width: 18px;
  height: 18px;
  position: absolute;
  left: 10px;
  top: 51px;
  stroke: #11111170;
`;

export const Divisor = styled.div`
  width: 470px;
  height: 1px;
  background-color: #11111110;
  margin-bottom: 32px;
`;

export const ActivateTitle = styled.p`
  color: #37d363;
  margin: 0 0 14px 0;
`;

export const DeactivateTitle = styled.p`
  color: #f03434;
  margin: 0 0 14px 0;
`;

export const StatusDescription = styled.p`
  font-size: 14px;
  color: #11111160;
  margin: 0;
`;
