import styled from 'styled-components';
import { AiOutlineSearch } from 'react-icons/ai';

export const Container = styled.div`
  width: 450px;
  max-height: 350px;

  background-color: white;
  border-radius: 8px;
  margin: 0 24px 24px 0;
`;

export const SubTitle = styled.h1`
  width: 100%;
  height: fit-content;
  font-size: 18px;
  margin: 0 5px;
  color: #2a3748;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 10px 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);

  button {
    margin: 0 0 0 8px;
    width: fit-content;
    white-space: nowrap;
    padding: 0 16px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SearchContainer = styled.div`
  width: calc(100% - 4px);
  height: 38px;
  position: relative;
  margin: 0 0 20px 0;
`;

export const SearchInput = styled.input`
  width: calc(100% - 16px);
  height: 38px;
  color: rgba(0, 0, 0, 0.7);
  background-color: white;
  border-radius: 4px;
  border: 1px solid #11111115;
  margin: 0;
  padding-left: 16px;
  font-size: 14px;
`;

export const SearchIcon = styled(AiOutlineSearch)`
  position: absolute;
  right: 10px;
  top: calc(50% - 6px);
`;
export const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  max-height: 200px;
  overflow-y: auto;
  padding: 30px 30px 14px 30px;
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  height: fit-content;
  padding: 14px 30px 30px 30px;
  border-top: 1px solid #e9e9e9;
`;

export const TextNotFound = styled.a`
  width: 100%;
  text-align: center;
  opacity: 0.6;
  margin-top: 32px;
  font-size: 14px;
`;
