import React, { useState } from 'react';

import {
  FormEmailSenha,
  FormEqMedica,
  FormEndereco,
  FormExperiencia,
  FormTermos,
  FormSuccess,
} from '../index';

import * as S from './styles';

import * as I from '../../../shared/interfaces/formInterfaces';

export const userContext = React.createContext<Partial<I.User>>({});

export const avatarContext = React.createContext<any>({});

const FormContainer: React.FC = () => {
  const formElementRef: React.RefObject<HTMLDivElement> = React.createRef();

  const [step, setStep] = useState(1);

  const [avatar, setAvatar] = useState<FormData>(new FormData());

  const [userData, setUserData] = useState<I.Data>({
    email: '',
    senha: '',
    confirmarSenha: '',
    avatar: '',
    nomeCompleto: '',
    cpfMedico: '',
    rgMedico: '',
    emailContato: '',
    telefoneContato: '',
    pessoaContato: '',
    numeroRegistro: '',
    conselho: '',
    estadoConselho: '',
    especialidades: [{ especialidade: '', rqe: '', favorita: true }],
    valor: '',
    temConvenio: false,
    convenios: [],
    isClinica: false,
    nomeClinica: '',
    cnpj: '',
    emailPrincipal: '',
    emailSecundario: '',
    rua: '',
    numero: '',
    cidade: '',
    estado: '',
    bairro: '',
    cep: '',
    tel: '',
    cel: '',
    experiencias: [{ titulo: '', descricao: '', ano: '' }],
    formacoes: [{ instituicao: '', curso: '', ano: '' }],
    termosECondicoes: false,
  });

  /** A função 'prevstep' traz o scroll da tela para o topo e renderiza o passo anterior do cadastro. */
  const prevStep = () => {
    formElementRef.current?.scrollTo(0, 0);
    setStep(step - 1);
  };

  /** A função 'nextStep' traz o scroll da tela para o topo e renderiza o próximo passo do cadastro. */
  const nextStep = () => {
    formElementRef.current?.scrollTo(0, 0);
    setStep(step + 1);
  };

  /** A função 'renderStep' renderiza o elemento referente ao passo atual do cadastro. Se estiver no passo 1, a função retornará o formulário de email e senha, e por ai adiante. */
  const renderStep = (opt: number) => {
    switch (opt) {
      case 1:
        return <FormEmailSenha nextStep={nextStep} />;
      case 2:
        return <FormEqMedica prevStep={prevStep} nextStep={nextStep} />;
      case 3:
        return <FormEndereco prevStep={prevStep} nextStep={nextStep} />;
      case 4:
        return <FormExperiencia prevStep={prevStep} nextStep={nextStep} />;
      case 5:
        return <FormTermos prevStep={prevStep} nextStep={nextStep} />;
      case 6:
        return <FormSuccess />;
      default:
        return <h1>Ocorreu um erro! :(</h1>;
    }
  };

  return (
    <userContext.Provider value={{ userData, setUserData }}>
      <avatarContext.Provider value={{ avatar, setAvatar }}>
        <S.Container ref={formElementRef}>{renderStep(step)}</S.Container>
      </avatarContext.Provider>
    </userContext.Provider>
  );
};

export default FormContainer;
