import styled from 'styled-components';
import { BiCalendar } from 'react-icons/bi';
import { motion } from 'framer-motion';

interface ContainerPos {
  top: number;
  left: number;
}

export const Background = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const ModalContainer = styled.div<ContainerPos>`
  width: 280px;
  height: fit-content;
  max-height: 80vh;
  background-color: white;
  position: absolute;
  border-radius: 4px;
  top: ${({ top }) => `${top + 38}px`};
  left: ${({ left }) => `${left}px`};
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.14);
  padding: 16px;

  button {
    width: 100%;
    height: 34px;
    margin: 6px 0 0 0;
  }

  button :first-child {
    margin: 0;
  }
`;

export const Label = styled.p`
  margin: 0 0 6px 0;
  font-size: 14px;
`;

export const Clear = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Link = styled.a`
  color: #1a1a1a;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  margin-top: 12px;
`;

export const OptionButton = styled.button`
  width: 100%;
  height: 34px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #e7e7e7;
  border-radius: 4px;
  background-color: white;

  cursor: pointer;
  margin-bottom: 6px;
`;

export const FieldsContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;

  input {
    height: 34px;
    margin: 0 0 6px 0;
    padding: 0 0 0 26px;
  }
`;

export const Field = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const Line = styled.div`
  width: 16px;
  height: 1px;
  background-color: #dededf;
  margin-top: 40px;
`;

export const IconCalendar = styled(BiCalendar)`
  width: 14px;
  height: 14px;
  fill: #2fa8d5;
  position: absolute;
  top: 33px;
  left: 8px;
`;
