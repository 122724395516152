import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Background = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: #2a374880;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ModalContainer = styled.div`
  width: fit-content;
  height: fit-content;
  max-width: 80vw;
  max-height: 80vh;
  background-color: white;
  position: absolute;
  opacity: 1;
  border-radius: 4px;
`;

export const Content = styled.div`
  height: 60vh;
  overflow: scroll;
  /* overflow-x: hidden; */
  padding: 30px 50px;
`;

export const Title = styled.h1`
  font-size: 22px;
  color: #2fa8d5;
  padding: 0;
  margin: 0 0 28px 0;
`;

export const ButtonContainer = styled.div`
  width: calc(100% - 32px);
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e9e9e9;
  padding: 0 16px;

  button {
    width: fit-content;
    padding: 0 16px;
    margin-left: 8px;
  }
`;

export const Print = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  max-width: 700px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Logo = styled.img`
  width: 150px;
`;

export const Description = styled.h1`
  font-size: 16px;

  color: #2a3748;
  padding: 0;
  margin: 0;
`;

export const Detail = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #2a3748;
  padding: 0;
  margin: 0 8px 6px 0;
`;

export const Badge = styled.span`
  padding: 8px;
  margin-left: 6px;
  border-radius: 6px;
  font-size: 14px;
  width: fit-content;
  font-weight: bold;
  background-color: #2fa8d5;
  color: white;
  -webkit-print-color-adjust: exact;
`;
