import React from 'react';

import { ActionButton, LocationButton } from '../../../components';

import * as S from './styles';

interface Endereco {
  id_endereco: string;
  endereco: string;
}

interface Props {
  enderecos: Endereco[];
  toggleModal: (type: string, id?: number | undefined) => void;
  editing: boolean;
}

const ConfigEnderecos: React.FC<Props> = ({
  enderecos,
  toggleModal,
  editing,
}) => {
  return (
    <S.Container>
      {editing && (
        <S.SubTitleContainer>
          {enderecos.length < 2 && (
            <ActionButton
              onClick={() => toggleModal('novoEndereco')}
              value="Novo Endereço"
            />
          )}
        </S.SubTitleContainer>
      )}

      <S.DetailsContainer>
        <S.SubTitle>Endereços de atendimento</S.SubTitle>
        {enderecos.map((endereco) => (
          <LocationButton
            key={endereco.id_endereco}
            onClick={() =>
              toggleModal('alterarEndereco', +endereco.id_endereco)
            }
            value={endereco.endereco}
          />
        ))}
      </S.DetailsContainer>
    </S.Container>
  );
};

export default ConfigEnderecos;
