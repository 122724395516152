import React, { useState, useContext } from 'react';

import { Label } from '..';

import { userContext } from '../../domain/Form/FormContainer';

import * as S from './styles';

import * as I from './interfaces';

const Avatar: React.FC<I.Props> = ({ setFormData }) => {
  const { userData, setUserData } = useContext(userContext);
  const [error, setError] = useState<string>('');

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target !== null) {
      const { files } = e.target;
      const form = new FormData();
      if (files!.length === 0) return;
      if (files![0].size > 1 * 1024 * 1024) {
        setError('O arquivo escolhido ultrapassa o tamanho máximo de 5mb');
        return;
      }
      if (files![0])
        setUserData!((state) => ({
          ...state,
          avatar: URL.createObjectURL(files![0]),
        }));
      setError('');
      form.append('file', files![0]);
      setFormData(form);
    }
  };

  return (
    <S.Container>
      <Label id="foto" labelText="Foto" />
      <S.PhotoContainer>
        {userData!.avatar.trim() === '' ? (
          <S.Message>Adicionar</S.Message>
        ) : (
          <>
            <S.Photo src={userData!.avatar} />
            <S.UpdateMessage>Clique para alterar</S.UpdateMessage>
          </>
        )}
        <S.Input type="file" accept="image/*" onChange={handleChange} />
        {error.trim() !== '' && (
          <S.ErrorMessage>
            <S.ErrorIcon />
            <p>{error}</p>
          </S.ErrorMessage>
        )}
      </S.PhotoContainer>
    </S.Container>
  );
};

export default Avatar;
