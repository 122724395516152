import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { ValueType, components, OptionProps } from 'react-select';

import * as S from './styles';
import * as I from './interfaces';

import { Label } from '..';

type Option = { value: string; label: string; __isNew__?: boolean };

const SelectSearch: React.FC<I.Props> = ({
  id,
  labelText,
  options,
  sufix,
  value = 'Selecione',
  width = '125px',
  height = '42px',
  invalid = '',
  isDisabled,
  handleCreate,
  onChangeValue,
}) => {
  const error = invalid.trim() !== '';

  const selectOptions = options.map((option) => {
    return { value: option, label: option };
  });

  const customStyles = {
    control: (provided: any, state: any) => ({
      width,
      height,
      display: 'flex',
      border: error ? '1px solid red' : '1px solid #DEDEDF',
      borderRadius: '4px',
      padding: '0 0 0 8px',
      fontSize: '14px',
      alignItems: 'center',
      backgroundColor: state.isDisabled ? '#efefef4d' : 'white',
    }),
    option: (provided: any, state: any) => {
      return {
        ...provided,
        backgroundColor: state.isSelected ? '#2185D0' : 'white',
        fontSize: '13px',
        '&:hover': {
          backgroundColor: !state.isSelected && '#dce6ee',
        },
      };
    },
    singleValue: (provided: any) => ({
      ...provided,
      color: value === 'Selecione...' && '#b5b5b5',
    }),
  };

  if (sufix && value.trim() !== '') value = `${value} ${sufix}`;

  if (value.trim() === '') value = 'Selecione...';

  const Option = ({ innerProps, ...props }: OptionProps<any, any>) => {
    innerProps.onMouseMove = () => {};
    innerProps.onMouseOver = () => {};
    return <components.Option innerProps={innerProps} {...props} />;
  };

  const handleOnChange = (selectedOption: ValueType<Option, any>) => {
    const option: Option = selectedOption as Option;

    /* eslint no-underscore-dangle: 0 */
    return option.__isNew__
      ? handleCreate!(option.value)
      : onChangeValue!(option.value);
  };

  const isValidNewOption = (
    inputValue: string,
    selectValue: any,
    createSelectOptions: any,
  ) => {
    const filteredOptions = createSelectOptions.filter((option: Option) =>
      option.value.toLowerCase().includes(inputValue),
    );

    return !filteredOptions.length;
  };

  return (
    <S.Container>
      {labelText && <Label id={id} labelText={labelText} />}

      <S.SelectContainer>
        <CreatableSelect
          styles={customStyles}
          options={selectOptions}
          value={{ value, label: value }}
          components={{ Option }}
          placeholder="Selecione..."
          noOptionsMessage={() => 'Nenhuma informação a ser exibida!'}
          formatCreateLabel={(input: string) => `Criar "${input}"`}
          isDisabled={isDisabled}
          maxMenuHeight={180}
          onChange={handleOnChange}
          isValidNewOption={isValidNewOption}
        />
        {error && (
          <S.ErrorMessage>
            <S.ErrorIcon />
            <p>{invalid}</p>
          </S.ErrorMessage>
        )}
      </S.SelectContainer>
    </S.Container>
  );
};

export default SelectSearch;
