import styled from 'styled-components';
import { CgCloseO } from 'react-icons/cg';

export const TrashIcon = styled(CgCloseO)``;

export const Container = styled.div`
  position: absolute;
  right: -12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  top: 51px;
  cursor: pointer;
  animation: fade-in 0.1s ease-in-out;

  @keyframes fade-in {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  ${TrashIcon} {
    width: 20px;
    height: 20px;
    transition: all 0.1s ease-in-out;
    color: #2a3748;
    opacity: 0.2;
  }

  ${TrashIcon}:hover {
    color: #2a3748;
    opacity: 1;
  }
`;
