import React from 'react';
import Select, { ValueType, components, OptionProps } from 'react-select';

import { Label } from '..';

import * as S from './styles';

import * as I from './interfaces';

type Option = { value: string; label: string };

const SelectOne: React.FC<I.Props> = ({
  id,
  labelText,
  options,
  sufix,
  value = 'Selecione',
  width = '125px',
  height = '42px',
  onChangeValue,
  invalid = '',
  isDisabled,
}) => {
  const error = invalid.trim() !== '';

  const selectOptions = options.map((option) => {
    return { value: option, label: option };
  });

  const customStyles = {
    control: (provided: any, state: any) => ({
      width,
      height,
      display: 'flex',
      border: error ? '1px solid red' : '1px solid #DEDEDF',
      borderRadius: '4px',
      padding: '0 0 0 8px',
      fontSize: '14px',
      alignItems: 'center',
      backgroundColor: state.isDisabled ? '#efefef4d' : 'white',
    }),
    option: (provided: any, state: any) => {
      return {
        ...provided,
        backgroundColor: state.isSelected ? '#2185D0' : 'white',
        fontSize: '13px',
        '&:hover': {
          backgroundColor: !state.isSelected && '#dce6ee',
        },
      };
    },
    singleValue: (provided: any) => ({
      ...provided,
      color: value === 'Selecione...' && '#b5b5b5',
    }),
  };

  if (sufix && value.trim() !== '') value = `${value} ${sufix}`;

  if (value.trim() === '') value = 'Selecione...';

  const Option = ({ innerProps, ...props }: OptionProps<any, any>) => {
    // As funções abaixo causavam sérios problemas de performance no caso de terem diversas opções para o select.
    // O único meio de contornar esse problema foi resetando as funções e mudando o estilo do hover pro CSS do CustomStyles.
    innerProps.onMouseMove = () => {};
    innerProps.onMouseOver = () => {};
    return <components.Option innerProps={innerProps} {...props} />;
  };

  return (
    <S.Container>
      {labelText && <Label id={id} labelText={labelText} />}

      <S.SelectContainer>
        <Select
          styles={customStyles}
          options={selectOptions}
          onChange={(selectedOption: ValueType<Option, any>) => {
            onChangeValue!((selectedOption as Option).value);
          }}
          value={{ value, label: value }}
          components={{ Option }}
          placeholder="Selecione..."
          noOptionsMessage={() =>
            labelText === 'Cidade'
              ? 'Selecione um estado!'
              : 'Nenhuma informação a ser exibida!'
          }
          isDisabled={isDisabled}
          maxMenuHeight={180}
        />
        {error && (
          <S.ErrorMessage>
            <S.ErrorIcon />
            <p>{invalid}</p>
          </S.ErrorMessage>
        )}
      </S.SelectContainer>
    </S.Container>
  );
};

export default SelectOne;
