import styled from 'styled-components';
import { FaInfo } from 'react-icons/fa';

export const Container = styled.div`
  width: 86%;
  height: 80%;
  margin: 7%;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  color: #2fa8d5;
  margin: 0;
`;

export const Description = styled.h4`
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 16px 0;
`;

export const Subtitle = styled.h3`
  margin: 16px 0 0 0;
  size: 20px;
`;

export const AdressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
`;

export const TextBox = styled.div`
  width: 100%;
  height: 429px;
  overflow: scroll;
  padding: 16px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  &:not(:last-child) {
    margin: 0 0 16px 0;
  }
  margin: 0;
`;

export const CheckboxContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

export const CheckboxDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
`;

export const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  margin: 17px 6px 15px 0;
  border: 1px solid #dedede;
`;

export const ErrorIcon = styled(FaInfo)`
  width: 50%;
  height: 50%;
  color: white;
`;

export const ErrorMessage = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: calc(50% - 8.5px);
  right: -24px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  p {
    display: none;
    color: #fff;
  }

  &:hover {
    padding: 0 10px;
    width: auto;
    height: 40px;
    border-radius: 4px;

    ${ErrorIcon} {
      display: none;
    }

    p {
      display: inline;
    }
  }
`;

export const Text = styled.p``;

export const TextBold = styled.p`
  margin-left: 4px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Center = styled.div`
  display: flex;
  height: 397px;
  align-items: center;
  justify-content: center;
`;
