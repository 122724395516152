import React, { useState } from 'react';
import ReactDom from 'react-dom';
import api from '../../../services/api';

import { LoadingSpinner } from '../../../components';

import * as S from './styles';

interface Props {
  id?: string;
  closeModal: () => void;
  closeAll: () => void;
}

const ModalCancelarConsulta: React.FC<Props> = ({
  id,
  closeModal,
  closeAll,
}) => {
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    api.put(`/consult/recusar/${id}`).then(() => {
      closeModal();
      closeAll();
      setLoading(false);
    });
  };

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer>
        <S.Content>
          <S.Header>
            <S.Title>Cancelar solicitação</S.Title>
            <S.Text>
              {!loading
                ? 'Tem certeza que gostaria de cancelar a solicitação do paciente?'
                : 'Processando...'}
            </S.Text>
          </S.Header>
        </S.Content>
        <S.LinkContainer>
          {!loading ? (
            <>
              <S.Link onClick={closeModal}>Voltar</S.Link>
              <S.Link onClick={confirm} primary>
                Confirmar
              </S.Link>
            </>
          ) : (
            <LoadingSpinner color="#fc6471" />
          )}
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalCancelarConsulta;
