import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import AdminLayout from './AdminLayout';
import AuthLayout from './AuthLayout';
import GerenteLayout from './GerenteLayout';

import PolicyLayout from './Policy';
import { PrivateRoute } from './PrivateRoute';
import UsersPolicyLayout from './UsersPolicyLayout';

const NotFound = () => {
  return (
    <>
      <h1>
        Página <code>{useLocation().pathname}</code> não encontrada!
        <span role="img" aria-label="emoji triste">
          😢
        </span>
      </h1>
    </>
  );
};

const checkAuth = () => {
  if (sessionStorage.getItem('user')) {
    switch (JSON.parse(sessionStorage.getItem('user')!).tipo) {
      case 0:
        return <Redirect to="/admin" />;
      case 1:
        return <Redirect to="/gerente" />;
      default:
        return <AuthLayout />;
    }
  }
  return <AuthLayout />;
};

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth" />
        </Route>
        <Route path="/auth" render={checkAuth} />
        {/* <Route path="/gerente" component={GerenteLayout} /> */}
        {/* <Route path="/admin" component={AdminLayout} /> */}
        <PrivateRoute
          permmitedRole={1}
          path="/gerente"
          component={GerenteLayout}
        />
        <PrivateRoute permmitedRole={0} path="/admin" component={AdminLayout} />
        <PrivateRoute
          permmitedRole={2}
          path="/policy/users-data"
          component={UsersPolicyLayout}
        />
        <Route path="/policy" component={PolicyLayout} />
        <Route path="/error/:code">
          <h1>Falha na autenticação!</h1>
        </Route>
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
