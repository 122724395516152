import React, { useState, useEffect } from 'react';
import api from '../../../services/api';

import { AddButton, Especialidade } from '../../../components';

import { especialidadeValidator } from '../../../utils/detalhesValidator';

import * as S from './styles';
import * as I from './interface';
import ModalAlert from '../../Modal/ModalAlert';

const DetalhesEspecialidades: React.FC<I.Props> = ({
  id,
  especialidades,
  readOnly,
  especialidadesList,
  fetch,
  edit,
  saving,
  setSaving,
  setEdit,
  setLoading,
}) => {
  const [, setLoad] = useState<boolean>(false);
  const [editing, setEditing] = useState(edit);
  const [createOk, setCreateOk] = useState(false);

  const [createError, setCreateError] = useState({
    error: false,
    message: '',
  });

  const [, setUpdateError] = useState({
    error: false,
    message: '',
  });

  const [data, setData] = useState<I.Data>({
    id,
    especialidades: especialidades.map((e) => ({ ...e })),
  });

  const [errorData, setErrorData] = useState<I.ErrorData>({
    especialidades: [{ especialidade: '', rqe: '', favorita: '' }],
  });

  useEffect(() => {
    setEditing(edit);
  }, [edit]);

  const save = async () => {
    setLoad(true);
    if (await especialidadeValidator(data!, setErrorData)) {
      api
        .put(`/medicos/especialidades/${id}`, {
          especialidades: data.especialidades.map((e) => ({
            nome: e.especialidade,
            rqe: e.rqe,
            is_favorite: e.favorita,
          })),
        })
        .then(() => {
          setEditing(false);
          setLoad(false);
          fetch();
        })
        .catch(() => {
          setUpdateError({
            error: true,
            message:
              'Erro na atualização dos dados. Preencha os campos corretamente e tente novamente!',
          });
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (edit) {
      save();
      setEdit(false);
      setLoading(false);
    }
    setSaving(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);

  const addEsp = () => {
    setData!({
      id,
      especialidades: [
        ...data.especialidades,
        { especialidade: '', rqe: '', favorita: false },
      ],
    });
    setErrorData!({
      especialidades: [
        ...errorData.especialidades,
        { especialidade: '', rqe: '', favorita: '' },
      ],
    });
  };

  const newEsp = (titulo: string, is_pending = true) => {
    api
      .post('/lista-especialidades/medico', {
        titulo,
        is_pending,
      })
      .then(() => {
        setCreateOk(true);
      })
      .catch((err) => {
        setCreateError({
          error: true,
          message: err.response.data.msg,
        });
      });
  };

  const setErrorEsp = (esp: any) => {
    setErrorData({
      especialidades: esp,
    });
  };

  return (
    <S.Container>
      {data.especialidades.map(
        (especialidade: I.Especialidades, index: number) => {
          return (
            <S.ContentRow key={`esp-${index.toString()}`}>
              <Especialidade
                index={index}
                especialidade={especialidade}
                options={especialidadesList ?? []}
                data={data!.especialidades}
                setData={setData}
                errorData={errorData.especialidades}
                setErrorData={setErrorEsp}
                isDisabled={!editing}
                creatable={!readOnly}
                handleCreate={newEsp}
              />
            </S.ContentRow>
          );
        },
      )}

      {editing && data.especialidades.length < 2 && (
        <AddButton
          onClick={addEsp}
          id="addEspecialidade"
          text="Especialidade"
        />
      )}

      {createError.error && (
        <ModalAlert
          type="error"
          msg={createError.message}
          closeModal={() => setCreateError({ error: false, message: '' })}
        />
      )}

      {createOk && (
        <ModalAlert
          type="success"
          msg="A nova especialidade está aguardando aprovação e será adicionada assim que for aprovada."
          closeModal={() => setCreateOk(false)}
        />
      )}
    </S.Container>
  );
};

export default DetalhesEspecialidades;
