import React, { useState, useContext, useRef, useEffect } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import api from '../../../services/api';

import { ActionButton, LoadingSpinner } from '../../../components';

import { termosValidator } from '../../../utils/formValidator';

import { avatarContext, userContext } from '../FormContainer';

import * as S from './styles';

import * as I from '../../../shared/interfaces/formInterfaces';

interface Props {
  prevStep: () => void;
  nextStep: () => void;
}

const FormTermos: React.FC<Props> = ({ prevStep, nextStep }) => {
  const reRef: any = useRef<ReCAPTCHA>();
  const textBoxRef: any = useRef(null);
  const { avatar } = useContext(avatarContext);
  const { userData, setUserData } = useContext(userContext);

  const { termosECondicoes } = userData!;
  const [termsUrl, setTermsUrl] = useState('');
  const [numPages, setNumPages] = useState(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<I.ErrorTermos>({
    termosECondicoes: '',
  });

  // const getTerms = () => {
  //   api
  //     .get('/pdf')
  //     .then(({ data: { file_url } }) => window.open(file_url, '_blank'));
  // };

  const toggleTermos = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked
      ? (setUserData!({ ...userData!, termosECondicoes: true }),
        setErrorData!({ ...errorData!, termosECondicoes: '' }))
      : setUserData!({ ...userData!, termosECondicoes: false });

  const next = async () => {
    setLoading(true);
    termosValidator(userData!, setErrorData).then(async (valid) => {
      const token = await reRef.current?.executeAsync();
      reRef.current?.reset();

      if (valid) {
        const {
          isClinica,
          cnpj,
          nomeClinica,
          email,
          emailSecundario,
          senha,
          conselho,
          estadoConselho,
          numeroRegistro,
          nomeCompleto,
          cpfMedico,
          rgMedico,
          emailContato,
          telefoneContato,
          pessoaContato,
          valor,
          rua,
          numero,
          bairro,
          estado,
          cidade,
          cep,
          tel,
          cel,
          convenios,
          especialidades,
          experiencias,
          formacoes,
        } = userData!;

        const search = await api.post('/coordinates', {
          search: `${rua},${numero},${bairro},${cidade},${estado},${cep}`,
        });

        const data = {
          clinica: {
            is_clinica: isClinica,
            cnpj,
            nome: nomeClinica,
            email,
            email_secundario:
              emailSecundario.trim() === '' ? null : emailSecundario,
            senha,
          },
          medico: {
            conselho,
            estado_conselho: estadoConselho,
            registro: numeroRegistro,
            nome_completo: nomeCompleto,
            valor_padrao: valor,
            cpf: cpfMedico,
            rg: rgMedico,
            email_contato: emailContato,
            telefone_contato: telefoneContato,
            pessoa_contato: pessoaContato,
          },
          enderecos: [
            {
              rua,
              numero,
              bairro,
              uf: estado,
              cidade,
              cep,
              telefone_cel: cel,
              telefone_fixo: tel,
              coordenadas: search.data.split(',').reverse().join(','),
            },
          ],
          convenios: convenios.map((convenio) => ({
            convenio,
          })),
          especialidades: especialidades.map(
            ({ especialidade, rqe, favorita }) => ({
              nome: especialidade,
              rqe,
              is_favorite: favorita,
            }),
          ),
          experiencias: experiencias.map(({ titulo, descricao, ano }) => ({
            titulo,
            descricao,
            e_ano: ano,
          })),
          formacoes: formacoes.map(({ instituicao, curso, ano }) => ({
            instituicao,
            curso,
            f_ano: ano,
          })),
          token,
        };

        await api
          .post('/managers', data, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(({ data: { id_medico } }) => {
            if (avatar.entries().next().done) return id_medico;
            const form = avatar;
            if (form?.has('id_user')) form!.delete('id_user');
            form.append('id_user', id_medico);
            return api.post(`/avatar/medicos`, form, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
          })
          .then(() => {
            nextStep();
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            // eslint-disable-next-line no-alert
            alert(
              'Erro de comunicação com o servidor. Reinicie a página e tente novamente.',
            );
          });
      }
      setLoading(false);
    });
  };

  const onDocumentLoadSuccess = (data: any) => {
    setNumPages(data.numPages);
  };

  const fecthTerms = async (mounted: boolean) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    await api.get('/pdf').then(({ data: { file_url } }) => {
      if (mounted) setTermsUrl(file_url);
    });
  };

  useEffect(() => {
    let mounted = true;
    fecthTerms(mounted);
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>Termos e Política</S.Title>
        <S.Description>
          Para prosseguir, você deve ler e estar de acordo com os termos e
          política de privacidade do aplicativo.
        </S.Description>
      </S.TitleContainer>

      <S.TextBox ref={textBoxRef}>
        <Document
          file={termsUrl}
          loading={
            <S.Center>
              <LoadingSpinner color="#2fa8d5" />
            </S.Center>
          }
          onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              width={textBoxRef.current.offsetWidth - 50}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </S.TextBox>

      <S.CheckboxContainer>
        <S.CheckboxDetails>
          <S.Checkbox
            checked={termosECondicoes}
            type="checkbox"
            name="convenio"
            id="convenio"
            onChange={toggleTermos}
          />
          <S.Text>Li e concordo com os Termos e Condições</S.Text>
          {/* <S.TextBold onClick={getTerms}>Termos e Condições</S.TextBold> */}
        </S.CheckboxDetails>
        {errorData!.termosECondicoes && (
          <S.ErrorMessage>
            <S.ErrorIcon />
            <p>{errorData!.termosECondicoes}</p>
          </S.ErrorMessage>
        )}
      </S.CheckboxContainer>

      <S.ButtonContainer>
        <ActionButton
          onClick={next}
          value="Finalizar"
          primary
          loading={loading}
        />
        <ActionButton onClick={prevStep} value="Voltar" />
      </S.ButtonContainer>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN!}
        size="invisible"
        ref={reRef}
      />
    </S.Container>
  );
};

export default FormTermos;
