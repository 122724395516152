import React from 'react';
import NumberFormat from 'react-number-format';

import { Label } from '..';

import * as S from './styles';

import * as I from './interfaces';

const InputNumberField: React.FC<I.Props> = ({
  invalid = '',
  width = '115px',
  id,
  labelText,
  optional,
  isDisabled,
  onChange,
  ...otherProps
}) => {
  const error = invalid.trim() !== '';

  return (
    <S.Container error={error} inputWidth={width}>
      {labelText && <Label id={id} labelText={labelText} optional={optional} />}

      <S.InputContainer>
        <NumberFormat
          onValueChange={onChange}
          {...otherProps}
          isNumericString
          disabled={isDisabled}
        />

        {error && (
          <S.ErrorMessage>
            <S.ErrorIcon />
            <p>{invalid}</p>
          </S.ErrorMessage>
        )}
      </S.InputContainer>
    </S.Container>
  );
};

export default InputNumberField;
