import styled from 'styled-components';
import { FaInfo } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';

interface InputProps {
  inputWidth: string;
  error: boolean;
}

export const Container = styled.div<InputProps>`
  display: flex;
  flex-direction: column;

  input {
    margin-right: 6px;
    width: ${({ inputWidth }) => inputWidth};
    height: 42px;
    padding: 0 16px;
    border: 1px solid ${({ error }) => (error ? 'red' : '#DEDEDF')};
    border-radius: 4px;
    font-size: 14px;

    ::placeholder {
      color: #b5b5b5;
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const ErrorIcon = styled(FaInfo)`
  width: 50%;
  height: 50%;
  color: white;
`;

export const ErrorMessage = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: calc(50% - 12px);
  right: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-right: 16px; */

  p {
    display: none;
    color: #fff;
  }

  &:hover {
    padding: 0 10px;
    width: auto;
    height: 40px;
    border-radius: 4px;
    white-space: nowrap;

    ${ErrorIcon} {
      display: none;
    }

    p {
      display: inline;
    }
  }
`;

export const IconCalendar = styled(BiCalendar)`
  width: 14px;
  height: 14px;
  fill: #2fa8d5;
  position: absolute;
  top: 10px;
  left: 8px;
`;
