import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonContainer: React.FC = () => {
  return (
    <>
      <br />
      <S.SubTitleContainer>
        <Skeleton width={300} height={20} />
      </S.SubTitleContainer>
      <br />
      <S.CardContainer>
        {Array(20)
          .fill(0)
          .map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <S.CardSkeleton key={index}>
              <S.SubTitleContainer>
                <Skeleton width={32} height={32} circle />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Skeleton width={210} height={20} />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Skeleton width={210} height={20} />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Skeleton width={210} height={20} />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Skeleton width={210} height={20} />
              </S.SubTitleContainer>
            </S.CardSkeleton>
          ))}
      </S.CardContainer>
    </>
  );
};

export default SkeletonContainer;
