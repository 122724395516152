import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Background = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: #2a374880;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  width: 700px;
  height: fit-content;
  max-height: 80vh;
  background-color: white;
  position: absolute;
  opacity: 1;
  border-radius: 4px;
`;

export const Content = styled.div`
  height: 230px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 30px 50px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 22px;
  color: #2fa8d5;
  padding: 0;
  margin: 0 0 28px 0;
`;

export const Error = styled.h1`
  font-size: 14px;
  color: red;
  padding: 0;
  margin: 0;
`;

export const Description = styled.h4`
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 16px 0;
  text-align: center;
  opacity: 0.8;
`;

export const ButtonContainer = styled.div`
  width: calc(100% - 32px);
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e9e9e9;
  padding: 0 16px;

  button {
    width: fit-content;
    padding: 0 16px;
    margin-left: 8px;
  }
`;

export const SuccessMessageContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Subtitle = styled.h3`
  font-size: 18px;
  margin: 50px 0 0 0;
  size: 20px;
`;

export const Center = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  border: 4px dotted #2fa8d550;
  border-radius: 16px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 450px;
  height: 50px;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2fa8d5;
  position: relative;
`;

export const Input = styled.input`
  width: 450px;
  height: 50px;
  position: absolute;
  opacity: 0;
`;
