import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';
import api from '../../services/api';
import { login } from '../../services/auth';

import bgImg from '../../assets/images/background-cropped.png';
import logo from '../../assets/images/logo.svg';

import ActionButton from '../../components/ActionButton';
import InputField from '../../components/InputField';
import InputPasswordField from '../../components/InputPasswordField';

import { loginValidator } from '../../utils/loginValidator';

import * as S from './styles';

const LoginUsers: React.FC = () => {
  const reRef: any = useRef<ReCAPTCHA>();
  const history = useHistory();
  const [load, setLoad] = useState<boolean>(false);

  const [loginData, setLoginData] = useState({
    email: '',
    senha: '',
  });

  const [errorData, setErrorData] = useState({
    email: '',
    senha: '',
  });

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoad(true);

    const token = await reRef.current?.executeAsync();
    reRef.current?.reset();

    loginValidator(loginData, setErrorData).then(async (valid) => {
      if (valid) {
        api
          .post('/login/paciente', {
            email: loginData.email,
            senha: loginData.senha,
            token,
          })
          .then((res) => {
            login(JSON.stringify(res.data.user), res.data.token);
            return history.push('/policy/users-data');
          })
          .catch(() =>
            setErrorData({
              ...errorData,
              senha: 'Usuário ou senha incorretos!',
            }),
          );
      }
      setLoad(false);
    });
  };

  const handleChange = (data: any, value: any) => {
    setErrorData({ ...errorData, [data]: '' });
    setLoginData!({ ...loginData, [data]: value });
  };

  return (
    <S.Container>
      <S.ContentBlock>
        <S.Content>
          <S.Logo src={logo} alt="ClickConsultas" />

          <S.TitleContainer>
            <S.PrimaryMessage>Bem-vindo</S.PrimaryMessage>
            {/* <S.SecondaryMessage>
              Por favor, entre com sua conta administrativa.
            </S.SecondaryMessage> */}
          </S.TitleContainer>

          <S.FormContainer onSubmit={handleLogin}>
            <InputField
              id="email"
              value={loginData.email}
              onChange={(e) => handleChange('email', e.target.value)}
              placeholder="nome@dominio.com"
              labelText="Email"
              width="286px"
              invalid={errorData.email}
            />

            <InputPasswordField
              id="senha"
              value={loginData.senha}
              onChange={(e) => handleChange('senha', e.target.value)}
              placeholder="••••••••"
              labelText="Senha"
              width="286px"
              invalid={errorData.senha}
            />

            <S.LineContainer>
              {/* <S.CheckboxContainer>
                <S.Label>Lembrar senha</S.Label>
                <S.InputCheckbox type="checkbox" />
                <S.Checkbox />
              </S.CheckboxContainer> */}
              <S.LinkComponent to="/auth/password-recovery/adm">
                Esqueci minha senha
              </S.LinkComponent>
            </S.LineContainer>

            <S.BtnContainer>
              <ActionButton
                value="Entrar"
                primary
                onClick={() => {}}
                loading={load}
              />
            </S.BtnContainer>
          </S.FormContainer>
        </S.Content>
        <S.Background>
          <img src={bgImg} alt="cadastro" />
        </S.Background>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN!}
          size="invisible"
          ref={reRef}
        />
      </S.ContentBlock>
    </S.Container>
  );
};

export default LoginUsers;
