import { Dispatch, SetStateAction } from 'react';

import {
  validator,
  espValidator,
  expValidator,
  forValidator,
} from './validators';

import {
  vazio,
  termos,
  senhasCoincidem,
  emailValido,
  optEmailValido,
  senhaForte,
  conveniosVazios,
  tamanhoMinimo,
  optTamanhoMinimo,
  validaCep,
  registroValido,
  emailExistente,
  emailIgual,
  testaCPF,
  testaCNPJ,
} from './conditions';

import * as I from '../shared/interfaces/formInterfaces';

export const emailSenhaValidator = async (
  { email, senha, confirmarSenha }: I.Data,
  setErrorData: Dispatch<SetStateAction<I.ErrorEmailSenha>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'email',
      email,
      [emailExistente, emailValido, vazio],
      [
        'O email digitado já está em uso!',
        'O email deve ser válido!',
        'O email não pode ser vazio!',
      ],
      setErrorData,
      true,
    ),
    validator(
      'senha',
      senha,
      [senhaForte, vazio],
      [
        'A senha deve conter, no mínimo:\n\t• Um número.\n\t• Uma letra maiúscula.\n\t• Uma letra minúscula.\n\t• Oito caracteres.',
        'A senha não pode ser vazia!',
      ],
      setErrorData,
    ),
    validator(
      'confirmarSenha',
      confirmarSenha,
      [senhasCoincidem(senha), vazio],
      [
        'As senhas não coincidem!',
        'A confirmação de senha não pode ser vazia!',
      ],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const eqMedicaValidator = async (
  {
    conselho,
    estadoConselho,
    numeroRegistro,
    nomeCompleto,
    cpfMedico,
    rgMedico,
    emailContato,
    telefoneContato,
    valor,
    especialidades,
    temConvenio,
    convenios,
  }: I.Data | I.EqMedica,
  setErrorData: Dispatch<SetStateAction<I.ErrorEqMedica>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  const validArray = [
    validator(
      'conselho',
      conselho,
      [vazio],
      ['Escolha o conselho!'],
      setErrorData,
    ),
    validator(
      'estadoConselho',
      estadoConselho,
      [vazio],
      ['Escolha o estado do conselho!'],
      setErrorData,
    ),
    validator(
      // validar com o back
      'numeroRegistro',
      numeroRegistro,
      [registroValido, vazio],
      ['Número de registro já cadastrado!', 'Digite o numero de registro!'],
      setErrorData,
      true,
    ),
    validator(
      'nomeCompleto',
      nomeCompleto,
      [vazio],
      ['O nome completo não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'rgMedico',
      rgMedico,
      [tamanhoMinimo(9), vazio],
      ['Preencha o RG corretamente!', 'Digite o RG do médico!'],
      setErrorData,
    ),
    validator(
      'cpfMedico',
      cpfMedico,
      [testaCPF, tamanhoMinimo(11), vazio],
      [
        'CPF inválido!',
        'Preencha o CPF corretamente!',
        'Digite o CPF do médico!',
      ],
      setErrorData,
    ),
    validator(
      'emailContato',
      emailContato,
      [emailValido, vazio],
      [
        'O email deve ser válido!',
        'O campo de email de contato não pode ser vazio!',
      ],
      setErrorData,
    ),
    validator(
      'telefoneContato',
      telefoneContato,
      [tamanhoMinimo(10), vazio],
      ['Preencha o telefone corretamente!', 'O telefone não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'valor',
      valor,
      [vazio],
      ['O valor não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'convenios',
      convenios,
      [conveniosVazios(temConvenio)],
      ['Escolha pelo menos um convênio!'],
      setErrorData,
    ),
  ];

  especialidades.forEach(async (especialidade, index) => {
    validArray.push(
      ...[
        espValidator(
          'especialidade',
          especialidade.especialidade,
          index,
          [vazio],
          ['Escolha uma especialidade!'],
          setErrorData,
        ),
        espValidator(
          // validar com o back
          'rqe',
          especialidade.rqe,
          index,
          [vazio],
          ["O 'RQE' não pode ser vazio!"],
          setErrorData,
          true,
        ),
      ],
    );
  });

  await Promise.all(validArray).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const enderecoValidator = async (
  {
    nomeClinica,
    cnpj,
    emailPrincipal,
    emailSecundario,
    estado,
    rua,
    numero,
    cidade,
    bairro,
    cep,
    tel,
    cel,
  }: I.Data,
  setErrorData: Dispatch<SetStateAction<I.ErrorEndereco>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'cnpj',
      cnpj,
      [testaCNPJ, tamanhoMinimo(14), vazio],
      [
        'CNPJ inválido!',
        'Preencha o CNPJ corretamente!',
        'Digite o CNPJ da clínica!',
      ],
      setErrorData,
    ),
    validator(
      'cpf',
      cnpj,
      [testaCPF, tamanhoMinimo(11), vazio],
      [
        'CPF inválido!',
        'Preencha o CPF corretamente!',
        'Digite o CPF da clínica!',
      ],
      setErrorData,
    ),
    validator(
      'nomeClinica',
      nomeClinica,
      [vazio],
      ['Digite o nome da clínica!'],
      setErrorData,
    ),
    validator(
      'emailPrincipal',
      emailPrincipal,
      [emailValido, vazio],
      [
        'O email deve ser válido!',
        'O campo de email principal não pode ser vazio!',
      ],
      setErrorData,
    ),
    validator(
      'emailSecundario',
      emailSecundario,
      [emailExistente, emailIgual(emailPrincipal), optEmailValido],
      [
        'O email digitado já foi cadastrado!',
        'Você não pode utilizar o mesmo email que o principal.',
        'O email deve ser válido!',
      ],
      setErrorData,
      true,
    ),
    validator(
      'rua',
      rua,
      [vazio],
      ["O campo 'rua' não pode ser vazio!"],
      setErrorData,
    ),
    validator(
      'numero',
      numero,
      [vazio],
      ['O campo numero não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'cidade',
      cidade,
      [vazio],
      ['Escolha uma das cidade!'],
      setErrorData,
    ),
    validator(
      'estado',
      estado,
      [vazio],
      ['Escolha um dos estados!'],
      setErrorData,
    ),
    validator(
      'bairro',
      bairro,
      [vazio],
      ['O bairro não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'cep',
      cep,
      [validaCep, vazio],
      ['O CEP digito não é válido!', "O campo 'cep' não pode ser vazio!"],
      setErrorData,
      true,
    ),
    validator(
      'tel',
      tel,
      [tamanhoMinimo(10), vazio],
      ['Preencha o telefone corretamente!', 'O telefone não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'cel',
      cel,
      [optTamanhoMinimo(10)],
      ['Preencha o celular corretamente!'],
      setErrorData,
    ),
  ]).then((res) => {
    const cpfCnpjValid = res[0] || res[1];
    valid = res.slice(2, 13).every((e: boolean) => e === true) && cpfCnpjValid;
  });

  return valid;
};

export const expFormValidator = async (
  { experiencias, formacoes }: Partial<I.Data>,
  setErrorData: Dispatch<SetStateAction<I.ErrorExp>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  const validArray: Promise<boolean>[] = [];

  experiencias?.forEach((experiencia, index) => {
    validArray.push(
      ...[
        expValidator(
          'titulo',
          experiencia.titulo,
          index,
          [],
          ['O título não pode ser vazio!'],
          setErrorData,
        ),
        expValidator(
          'descricao',
          experiencia.descricao,
          index,
          [],
          ['A descrição não pode ser vazia!'],
          setErrorData,
        ),
        expValidator(
          'ano',
          experiencia.ano,
          index,
          [],
          ['O ano não pode ser vazio!'],
          setErrorData,
        ),
      ],
    );
  });

  formacoes?.forEach((formacao, index) => {
    validArray.push(
      ...[
        forValidator(
          'instituicao',
          formacao.instituicao,
          index,
          [],
          ['A institução não pode ser vazia!'],
          setErrorData,
        ),
        forValidator(
          'curso',
          formacao.curso,
          index,
          [],
          ['O curso não pode ser vazio!'],
          setErrorData,
        ),
        forValidator(
          'ano',
          formacao.ano,
          index,
          [],
          ['O ano não pode ser vazio!'],
          setErrorData,
        ),
      ],
    );
  });

  await Promise.all(validArray).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const termosValidator = async (
  { termosECondicoes }: I.Data,
  setErrorData: Dispatch<SetStateAction<I.ErrorTermos>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'termosECondicoes',
      termosECondicoes,
      [termos],
      ['Você deve ler e aceitar os termos para prosseguir!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};
