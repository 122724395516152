import styled from 'styled-components';
import { FaInfo } from 'react-icons/fa';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

export const SelectContainer = styled.div`
  position: relative;
`;

export const ErrorIcon = styled(FaInfo)`
  width: 50%;
  height: 50%;
  color: white;
`;

export const ErrorMessage = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: calc(50% - 8.5px);
  right: 48px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  p {
    display: none;
    color: #fff;
  }

  &:hover {
    padding: 0 10px;
    width: auto;
    height: 40px;
    border-radius: 4px;

    ${ErrorIcon} {
      display: none;
    }

    p {
      display: inline;
    }
  }
`;
