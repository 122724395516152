import styled from 'styled-components';
import { FaInfo } from 'react-icons/fa';

interface Disabled {
  disabled: boolean;
}

export const Container = styled.div`
  width: calc(100% - 64px);
  height: 100vh;
  padding: 32px;
  overflow: scroll;
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  color: #2a3748;
  margin: 0 0 16px 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;

  button {
    margin: 0 0 0 8px;
  }
`;

export const Description = styled.h4`
  max-width: 470px;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 32px 0;
  color: rgba(0, 0, 0, 0.6);
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const DetailRow = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  margin: 0 0 14px 0;
`;

export const Message = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #333333;
`;

export const UpdateMessage = styled.span`
  /* position: absolute; */
  display: none;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  line-height: normal;
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: circle(35px at center);
`;

export const InitialsContainer = styled.div`
  width: 100%;
  height: 100%;
  clip-path: circle(35px at center);
`;

export const ContentRow = styled.div`
  display: flex;
`;

export const Initials = styled.span`
  text-align: center;
  font-size: 28px;
  color: gray;
`;

export const Input = styled.input`
  width: 71px;
  height: 71px;
  border-radius: 50%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
`;

export const PhotoContainer = styled.div<Disabled>`
  width: 71px;
  height: 71px;
  border-radius: 50%;
  background-color: lightgrey;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 500;
  line-height: 0;

  position: relative;

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      `
    background-color: #e0e1e2;

    ${Input} {
      cursor: pointer;
    }

    ${Photo} {
      filter: grayscale(100%);
      opacity: 0.3;
      position: absolute;
    }

    ${Initials} {
      filter: grayscale(100%);
      opacity: 0.3;
      position: absolute;
    }

    ${UpdateMessage} {
      display: inline-block;
      position: absolute;
    }`}
  }
`;

export const PhotoInfoContainer = styled.div`
  margin: auto 0;
  margin-left: 10px;
  max-width: 470px;
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);

  p {
    margin: 0;

    &:last-of-type {
      margin-top: 5px;
    }
  }
`;

export const ErrorIcon = styled(FaInfo)`
  width: 50%;
  height: 50%;
  color: white;
`;

export const ErrorMessage = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 0;
  right: -10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  p {
    cursor: default;
    display: none;
    color: #fff;
  }

  &:hover {
    padding: 0 10px;
    width: auto;
    height: auto;
    border-radius: 4px;
    white-space: pre;
    right: -315px;
    z-index: 1000;

    ${ErrorIcon} {
      display: none;
    }

    p {
      display: inline;
    }
  }
`;
