import React, { useState } from 'react';
import api from '../../../services/api';

import {
  ActionButton,
  InputField,
  InputNumberField,
  InputPasswordField,
} from '../../../components';

import { ConfigEnderecos } from '../index';

import { cadastroValidator } from '../../../utils/configValidator';

import * as S from './styles';

import * as I from './interface';

const ConfigCadastro: React.FC<I.Props> = ({
  nomeClinica,
  isClinica,
  cnpj,
  emailPrincipal,
  emailSecundario,
  telefoneCel,
  telefoneFixo,
  rua,
  numero,
  bairro,
  cidade,
  uf,
  medicos,
  fetchData,
  enderecos,
  toggleModal,
}) => {
  const [load, setLoad] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [isCnpj] = useState<boolean>(cnpj.length > 11);

  const [data, setData] = useState<I.Data>({
    nomeClinica,
    isClinica,
    cnpj,
    emailPrincipal,
    emailSecundario,
    telefoneCel,
    telefoneFixo,
    rua,
    numero,
    bairro,
    cidade,
    uf,
    medicos,
    senhaAtual: '',
    novaSenha: '',
    confirmarSenha: '',
    showPwdChange: false,
  });

  const [errorData, setErrorData] = useState(Object);

  const setOriginalData = () => {
    setData({
      nomeClinica,
      isClinica,
      cnpj,
      emailPrincipal,
      emailSecundario,
      telefoneCel,
      telefoneFixo,
      rua,
      numero,
      bairro,
      cidade,
      uf,
      medicos,
      senhaAtual: '',
      novaSenha: '',
      confirmarSenha: '',
      showPwdChange: false,
    });
    setErrorData(Object());
  };

  const save = async () => {
    setLoad(true);
    if (
      await cadastroValidator(
        data!,
        setErrorData,
        emailSecundario !== data.emailSecundario,
        data.showPwdChange,
      )
    ) {
      let payload: any = { nome: data.nomeClinica };

      if (emailSecundario !== data.emailSecundario)
        payload = { ...payload, email_secundario: data.emailSecundario };

      if (data.showPwdChange)
        payload = {
          ...payload,
          senha_antiga: data.senhaAtual,
          nova_senha: data.novaSenha,
        };

      api.put('/managers', payload).then(() => {
        let user = JSON.parse(sessionStorage.getItem('user')!);
        user = { ...user, nome: data.nomeClinica };
        sessionStorage.setItem('user', JSON.stringify(user));
        setEditing(false);
        setLoad(false);
        fetchData();
      });
    } else {
      setLoad(false);
    }
  };

  const handleChange = (field: any, value: any) => {
    setErrorData({ ...errorData, [field]: '' });
    setData!({ ...data!, [field]: value });
  };

  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Cadastro Gerencial</S.SubTitle>
        {!editing ? (
          <ActionButton onClick={() => setEditing(true)} value="Editar" />
        ) : (
          <>
            <ActionButton
              onClick={() => {
                setOriginalData();
                setEditing(false);
              }}
              value="Cancelar"
            />
            <ActionButton
              onClick={save}
              value="Salvar"
              primary
              loading={load}
            />
          </>
        )}
      </S.SubTitleContainer>
      <S.DetailsContainer>
        <S.ContentContainer>
          <InputField
            id="nomeClinica"
            value={data.nomeClinica}
            onChange={(e) => handleChange('nomeClinica', e.target.value)}
            placeholder="Digite o nome da clínica"
            labelText="Nome da clínica/consultório"
            width="165px"
            invalid={errorData!.nomeClinica}
            isDisabled={!editing}
          />

          <InputNumberField
            id="cnpj"
            value={data.cnpj}
            onChange={({ value }) => handleChange('cnpj', value)}
            placeholder={isCnpj ? '00.000.000/0000-00' : '000.000.000-00'}
            labelText={isCnpj ? 'CNPJ' : 'CPF'}
            format={isCnpj ? '##.###.###/####-##' : '###.###.###-##'}
            width="170px"
            mask="_"
            invalid={errorData!.cnpj}
            isDisabled
          />
        </S.ContentContainer>

        <S.ContentContainer>
          <InputField
            id="emailPrincipal"
            value={data.emailPrincipal}
            onChange={(e) => handleChange('emailPrincipal', e.target.value)}
            placeholder="email@dominio.com"
            labelText="Email principal"
            width="168px"
            invalid={errorData!.emailPrincipal}
            isDisabled
          />

          <InputField
            id="emailSecundario"
            value={data.emailSecundario}
            onChange={(e) => handleChange('emailSecundario', e.target.value)}
            placeholder="email@dominio.com"
            labelText="Email secundário"
            width="168px"
            invalid={errorData!.emailSecundario}
            optional
            isDisabled={!editing}
          />
        </S.ContentContainer>
        <ConfigEnderecos
          enderecos={enderecos}
          toggleModal={toggleModal}
          editing={editing}
        />
      </S.DetailsContainer>
      {editing && (
        <S.PasswordContainer>
          {data.showPwdChange ? (
            <>
              <InputPasswordField
                id="senhaAtual"
                value={data.senhaAtual}
                onChange={(e) => handleChange('senhaAtual', e.target.value)}
                placeholder="••••••••"
                labelText="Senha atual"
                width="168px"
                invalid={errorData!.senhaAtual}
                isDisabled={!editing}
                doNotSave
              />
              <S.ContentContainer>
                {editing && (
                  <>
                    <InputPasswordField
                      id="novaSenha"
                      value={data.novaSenha}
                      onChange={(e) =>
                        handleChange('novaSenha', e.target.value)
                      }
                      placeholder="••••••••"
                      labelText="Nova senha"
                      width="168px"
                      invalid={errorData!.novaSenha}
                      doNotSave
                    />

                    <InputPasswordField
                      id="confirmarSenha"
                      value={data.confirmarSenha}
                      onChange={(e) =>
                        handleChange('confirmarSenha', e.target.value)
                      }
                      placeholder="••••••••"
                      labelText="Confirmar nova senha"
                      width="168px"
                      invalid={errorData!.confirmarSenha}
                      doNotSave
                    />
                  </>
                )}
              </S.ContentContainer>
            </>
          ) : (
            <ActionButton
              value="Alterar Senha"
              onClick={() =>
                setData((state) => ({
                  ...state,
                  showPwdChange: !data.showPwdChange,
                }))
              }
            />
          )}
        </S.PasswordContainer>
      )}
    </S.Container>
  );
};

export default ConfigCadastro;
