import React from 'react';
import ReactDom from 'react-dom';

import * as S from './styles';

import * as I from './interface';

const ModalStatus: React.FC<I.Props> = ({
  id,
  top,
  left,
  closeModal,
  filterOptions,
  setFilterOptions,
}) => {
  const handleChange = (status: string) => {
    const newStatusArray = filterOptions.status.includes(status)
      ? filterOptions.status.filter((value: any) => value !== status)
      : [...filterOptions.status, status];

    setFilterOptions((state: any) => ({ ...state, status: newStatusArray }));
  };

  const clear = () => {
    setFilterOptions((state: any) => ({ ...state, status: [] }));
    closeModal();
  };

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer id={id} top={top} left={left}>
        <S.Label>Selecione os status</S.Label>
        <S.OptionButton onClick={() => handleChange('Finalizada')}>
          <S.Square selected={filterOptions.status.includes('Finalizada')} />
          Finalizada
        </S.OptionButton>
        <S.OptionButton onClick={() => handleChange('Cancelada')}>
          <S.Square selected={filterOptions.status.includes('Cancelada')} />
          Cancelada
        </S.OptionButton>
        <S.Clear>
          <S.Link onClick={clear}>Limpar</S.Link>
        </S.Clear>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalStatus;
