import React, { useContext, useState } from 'react';

import {
  ActionButton,
  AddButton,
  Experiencia,
  Formacao,
} from '../../../components';

import { userContext } from '../FormContainer';

import { expFormValidator } from '../../../utils/formValidator';

import * as S from './styles';

import * as I from '../../../shared/interfaces/formInterfaces';

interface Props {
  prevStep: () => void;
  nextStep: () => void;
}

const FormExperiencia: React.FC<Props> = ({ prevStep, nextStep }) => {
  const { userData, setUserData } = useContext(userContext);
  const [load, setLoad] = useState<boolean>(false);

  const { experiencias, formacoes } = userData!;

  const [errorData, setErrorData] = useState<I.ErrorExp>({
    experiencias: [{ titulo: '', descricao: '', ano: '' }],
    formacoes: [{ instituicao: '', curso: '', ano: '' }],
  });

  const next = async () => {
    setLoad(true);
    if (await expFormValidator(userData!, setErrorData)) {
      nextStep();
    } else {
      setLoad(false);
    }
  };

  const addExp = () => {
    setUserData!({
      ...userData!,
      experiencias: [...experiencias, { titulo: '', descricao: '', ano: '' }],
    });
    setErrorData!({
      ...errorData!,
      experiencias: [
        ...errorData!.experiencias,
        { titulo: '', descricao: '', ano: '' },
      ],
    });
  };

  const addForm = () => {
    setUserData!({
      ...userData!,
      formacoes: [...formacoes, { instituicao: '', curso: '', ano: '' }],
    });
    setErrorData!({
      ...errorData!,
      formacoes: [
        ...errorData!.formacoes,
        { instituicao: '', curso: '', ano: '' },
      ],
    });
  };

  const setExpForm = (expForm: any) => {
    setUserData!((state) => ({ ...state, ...expForm }));
  };

  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>Experiência &amp; Formação</S.Title>
        <S.Description>
          Preencha os campos abaixo com as informações de experiência e formação
          do médico cadastrado
        </S.Description>
      </S.TitleContainer>

      <S.ContentContainer>
        <S.Subtitle>
          Experiência <S.Span>(opcional)</S.Span>
        </S.Subtitle>

        {experiencias.map((experiencia: I.Experiencias, index: number) => (
          <S.ContentRow key={`exp-${index.toString()}`}>
            <Experiencia
              index={index}
              experiencia={experiencia}
              data={{
                experiencias: userData!.experiencias,
                formacoes: userData!.formacoes,
              }}
              setData={setExpForm}
              errorData={errorData}
              setErrorData={setErrorData}
            />
          </S.ContentRow>
        ))}

        {experiencias.length < 10 && (
          <AddButton id="experiencia" text="Experiência" onClick={addExp} />
        )}
      </S.ContentContainer>

      <S.ContentContainer>
        <S.Subtitle>
          Formação <S.Span>(opcional)</S.Span>
        </S.Subtitle>

        {formacoes.map((formacao: I.Formacoes, index: number) => (
          <S.ContentRow key={`form-${index.toString()}`}>
            <Formacao
              index={index}
              formacao={formacao}
              data={{
                experiencias: userData!.experiencias,
                formacoes: userData!.formacoes,
              }}
              setData={setExpForm}
              errorData={errorData}
              setErrorData={setErrorData}
            />
          </S.ContentRow>
        ))}
        {formacoes.length < 10 && (
          <AddButton id="formacao" text="Formação" onClick={addForm} />
        )}
      </S.ContentContainer>

      <S.ButtonContainer>
        <ActionButton onClick={next} value="Continuar" primary loading={load} />
        <ActionButton onClick={prevStep} value="Voltar" />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default FormExperiencia;
