import styled from 'styled-components';
import { BsClock } from 'react-icons/bs';

interface StatusProps {
  status: string;
}

export const Container = styled.div`
  width: 100%;
  margin: 16px 0;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const THead = styled.thead`
  text-align: left;
  background-color: #ddf1f8;
`;

export const TRHead = styled.tr`
  width: 100%;

  th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const TH = styled.th`
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 24px 10px 20px;
  color: #39393a;
`;

export const TD = styled.td`
  font-size: 14px;
  padding: 10px 24px 10px 20px;
`;

export const TBody = styled.tbody``;

export const TRBody = styled.tr`
  height: 64px;
  position: relative;
  border-bottom: 1px solid lightgray;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }
`;

export const EspContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EspDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EspNome = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const EspFuncao = styled.p`
  font-size: 13px;
  color: #414141;
  margin: 0;
`;

export const EspAvatar = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
`;

export const InitialsContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
`;

export const Initials = styled.span`
  text-align: center;
  font-size: 16px;
  color: gray;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
`;

export const TelContainer = styled.div`
  display: flex;
`;

export const Dot = styled.div<StatusProps>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(props) => {
    switch (props.status) {
      case 'Solicitada':
        return '#FF9800';
      case 'Agendada':
        return '#00CC8F';
      case 'Pendente':
        return '#2FA8D5';
      case 'Cancelada':
        return '#CF4647';
      case 'Finalizada':
        return '#333333';
      default:
        return 'grey';
    }
  }};
  box-shadow: ${(props) => {
    switch (props.status) {
      case 'Solicitada':
        return '0px 0px 4px #FF9800';
      case 'Agendada':
        return '0px 0px 4px #00CC8F';
      case 'Pendente':
        return '0px 0px 4px #2FA8D5';
      case 'Cancelada':
        return '0px 0px 4px #CF4647';
      case 'Finalizada':
        return '0px 0px 4px #333333';
      default:
        return 'grey';
    }
  }};
`;

export const Bold = styled.strong``;

export const Update = styled.p`
  color: #585858;
`;

export const Clock = styled(BsClock)`
  margin-right: 4px;
`;
