import React, { useState } from 'react';
import ReactDom from 'react-dom';

import { LoadingSpinner } from '../../../components';

import * as S from './styles';

interface Props {
  titulo: string;
  closeModal: () => void;
  handleCreate: () => void;
}

const ModalConfirmarEspecialidade: React.FC<Props> = ({
  titulo,
  handleCreate,
  closeModal,
}) => {
  const [loading] = useState(false);

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer>
        <S.Content>
          <S.Header>
            <S.Title>Confirmação</S.Title>
            <S.Text>
              {!loading
                ? `Deseja realmente criar a especialidade ${titulo}?`
                : 'Processando...'}
            </S.Text>
          </S.Header>
        </S.Content>
        <S.LinkContainer>
          {!loading ? (
            <>
              <S.Link onClick={closeModal}>Cancelar</S.Link>
              <S.Link onClick={handleCreate} primary>
                Confirmar
              </S.Link>
            </>
          ) : (
            <LoadingSpinner color="#2fa8d5" />
          )}
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalConfirmarEspecialidade;
