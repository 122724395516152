import styled from 'styled-components';

export const Container = styled.div`
  width: 86%;
  height: 85%;
  margin: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const PrimaryMessage = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: #2fa8d5;
`;

export const SecondaryMessage = styled.h4`
  font-size: 14px;
  font-weight: 400;
  margin: 25px 0 32px 0;
`;

export const FormContainer = styled.form`
  margin-right: -16px;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 16px 0 0;
`;
