import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import WhiteLogo from '../../../assets/images/logo-white.svg';

import * as S from './styles';

const SidebarMenuAdm: React.FC = () => {
  const [selected, setSelected] = useState('especialistas');

  useEffect(() => {
    setSelected(window.location.pathname.split('/')[2]);
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.Logo src={WhiteLogo} />
      </S.Header>
      <S.Content>
        <S.MenuItems>
          <Link
            to="/admin/especialistas"
            onClick={() => setSelected('especialistas')}>
            <S.MenuItem selected={selected === 'especialistas'}>
              <S.IconInicio />
              <S.Label>Especialistas</S.Label>
            </S.MenuItem>
          </Link>
          <Link
            to="/admin/monitoramento"
            onClick={() => setSelected('monitoramento')}>
            <S.MenuItem selected={selected === 'monitoramento'}>
              <S.IconConsultas />
              <S.Label>Monitoramento</S.Label>
            </S.MenuItem>
          </Link>
          <Link
            to="/admin/historico-consultas"
            onClick={() => setSelected('historico-consultas')}>
            <S.MenuItem selected={selected === 'historico-consultas'}>
              <S.IconRelatorio />
              <S.Label>Histórico de Consultas</S.Label>
            </S.MenuItem>
          </Link>
          <Link
            to="/admin/configuracoes"
            onClick={() => setSelected('configuracoes')}>
            <S.MenuItem selected={selected === 'configuracoes'}>
              <S.IconConfig />
              <S.Label>Configurações</S.Label>
            </S.MenuItem>
          </Link>
        </S.MenuItems>
      </S.Content>
    </S.Container>
  );
};

export default SidebarMenuAdm;
