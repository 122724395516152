import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import api from '../../services/api';

import {
  ConfigCadastro,
  ConfigEqMedica,
  SkeletonCadastro,
  SkeletonEqMedica,
} from '../../domain/Config';

import {
  ModalNovoMedico,
  ModalNovoEndereco,
  ModalAlterarEndereco,
} from '../../domain/Modal';

import * as S from './styles';
import { ActionButton, TableWarning } from '../../components';

const Configuracoes: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);
  const [data, setData] = useState({
    nomeClinica: '',
    cnpj: '',
    telefone: '',
    celular: '',
    emailPrincipal: '',
    emailSecundario: '',
    bairro: '',
    cep: '',
    cidade: '',
    rua: '',
    telefoneCel: '',
    telefoneFixo: '',
    numero: '',
    uf: '',
    isClinica: true,
    enderecos: [
      {
        id_endereco: '',
        endereco: '',
      },
    ],
    eqMedica: [
      {
        id: '',
        nomeMedico: '',
        cpf: '',
        isPending: false,
        isActive: false,
      },
    ],
  });

  const [modals, setModals] = useState<{ [key: string]: any }>({
    novoMedico: {
      id: 0,
      state: false,
    },
    novoEndereco: {
      id: 1,
      state: false,
    },
    alterarEndereco: {
      id: 40,
      state: false,
    },
  });

  const toggleModal = (type: string, id?: number) => {
    return id
      ? setModals({
          ...modals,
          [type]: { id, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  const fetchData = async () => {
    setLoading(true);
    await api.get('/managers/list').then(async ({ data: dados }) => {
      const {
        nome: nomeClinica,
        is_clinica: isClinica,
        cnpj,
        email: emailPrincipal,
        email_secundario: emailSecundario,
        cep,
        rua,
        bairro,
        cidade,
        uf,
        numero,
        telefone_fixo: telefoneFixo,
        telefone_cel: telefoneCel,
      } = dados;

      setData((state) => ({
        ...state,
        nomeClinica,
        isClinica,
        cnpj,
        emailPrincipal,
        emailSecundario,
        cep,
        rua,
        bairro,
        cidade,
        uf,
        numero,
        telefoneFixo,
        telefoneCel,
      }));

      await api
        .get('/address')
        .then(async ({ data: enderecos }) => {
          setData((state) => ({ ...state, enderecos }));

          await api.get('/medicos/list').then(({ data: listMedicos }) => {
            setData((state) => ({
              ...state,
              eqMedica: listMedicos.map((medico: any) => ({
                id: medico.id_medico,
                nomeMedico: medico.nome_completo,
                cpf: medico.cpf,
                isPending: medico.is_pending,
                isActive: medico.is_active,
              })),
            }));
          });
        })

        .then(() => setLoading(false))
        .catch(() => {
          setLoading(false);
          setConnectionError(true);
        });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logoff = useCallback(() => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('jwt');
    history.push('/');
  }, [history]);

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Headline>
            <S.Icon />
            <S.Title>Configurações</S.Title>
          </S.Headline>
          <S.Description>
            Encontre todas as informações da sua conta, equipe médica, endereço,
            etc.
          </S.Description>
        </S.TitleContainer>
        <S.ButtonContainer>
          <ActionButton onClick={logoff} value="Desconectar" />
        </S.ButtonContainer>
      </S.Header>
      {connectionError && <TableWarning noConnection />}
      <S.Content>
        <S.ContentL>
          {loading ? (
            <>
              <SkeletonEqMedica />
            </>
          ) : (
            !connectionError && (
              <>
                <ConfigEqMedica
                  medicos={data.eqMedica}
                  toggleModal={toggleModal}
                  fetchData={fetchData}
                />
                {/* <ConfigEnderecos
                  enderecos={data.enderecos}
                  toggleModal={toggleModal}
                /> */}
              </>
            )
          )}
        </S.ContentL>
        <S.ContentR>
          {loading ? (
            <>
              <SkeletonCadastro />
            </>
          ) : (
            !connectionError && (
              <>
                <ConfigCadastro
                  nomeClinica={data.nomeClinica}
                  isClinica={data.isClinica}
                  cnpj={data.cnpj}
                  emailPrincipal={data.emailPrincipal}
                  emailSecundario={data.emailSecundario}
                  telefoneFixo={data.telefoneFixo}
                  telefoneCel={data.telefoneCel}
                  fetchData={fetchData}
                  rua={data.rua}
                  numero={data.numero}
                  bairro={data.bairro}
                  cidade={data.cidade}
                  uf={data.uf}
                  enderecos={data.enderecos}
                  toggleModal={toggleModal}
                  medicos={data.eqMedica}
                />
              </>
            )
          )}
        </S.ContentR>
      </S.Content>
      <AnimatePresence>
        {modals.novoMedico.state && (
          <ModalNovoMedico
            id={modals.novoMedico.id}
            closeModal={() => toggleModal('novoMedico')}
            fetchData={fetchData}
          />
        )}
        {modals.novoEndereco.state && (
          <ModalNovoEndereco
            id={modals.novoEndereco.id}
            closeModal={() => toggleModal('novoEndereco')}
            fetchData={fetchData}
          />
        )}
        {modals.alterarEndereco.state && (
          <ModalAlterarEndereco
            id={modals.alterarEndereco.id}
            onlyOneLeft={data.enderecos.length < 2}
            closeModal={() => toggleModal('alterarEndereco')}
            fetchData={fetchData}
          />
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default Configuracoes;
