import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const Label: React.FC<I.Props> = ({ id, labelText, optional }) => {
  return (
    <S.Container htmlFor={id}>
      {labelText}
      {optional && <S.Optional>(Opcional)</S.Optional>}
    </S.Container>
  );
};

export default Label;
