import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const Background = styled.div`
  width: 100%;

  z-index: -1;
  overflow: hidden;

  position: absolute;
  bottom: -30px;

  display: flex;
  justify-content: center;

  @media (max-height: 800px) {
    display: none;
  }
`;

export const Logo = styled.img`
  max-width: 222px;

  width: 100%;

  margin-bottom: 36px;

  position: absolute;
  top: -50%;
  transform: translateY(50%);

  z-index: 1;
`;

export const ContentBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.05);
  z-index: 2;

  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  padding: 20px;
  box-sizing: border-box;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;

  color: #2a3748;
`;

export const PrimaryMessage = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
`;

export const SecondaryMessage = styled.h4`
  font-size: 14px;
  font-weight: 400;
  margin: 25px 0 32px 0;
  opacity: 0.6;
`;

export const FormContainer = styled.form`
  max-width: 320px;
  width: 100%;

  div {
    margin-right: 0;

    input {
      box-sizing: border-box;
      max-width: 320px;
      width: 100%;
    }
  }
`;

export const LineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  height: 18px;
  padding-left: 24px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.8);
`;

export const InputCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  & ~ span {
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }

  &:checked ~ span {
    background: #2185d0;
    border: 1px solid #2185d0;
  }

  &:checked ~ span:after {
    display: block;
    width: 20%;
    height: 60%;
  }
`;

export const Checkbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #dedede;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;

    left: 5px;
    top: 0.5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const LinkComponent = styled(Link)`
  cursor: pointer;
  text-align: center;
  align-items: center;

  font-family: 'Lato';
  font-size: 14px;
  font-weight: 500;
  color: #2185d0;
  font-weight: 500;
  text-decoration-line: none;
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  margin-top: 1rem;

  button {
    flex: 1;
  }
`;
