import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonDadosBasicos: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Title>
            <Skeleton width={150} height={20} />
          </S.Title>
          <S.Description>
            <Skeleton width={500} height={15} />
            <Skeleton width={450} height={15} />
          </S.Description>
        </S.TitleContainer>
        <Skeleton width={130} height={40} />
      </S.Header>

      <S.AvatarContainer>
        <Skeleton circle width={71} height={71} />
      </S.AvatarContainer>

      <br />

      <S.DetailContainer>
        <S.DetailRow>
          <S.Column>
            <Skeleton width={71} height={15} />
            <Skeleton width={160} height={44} />
          </S.Column>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <S.Column>
            <Skeleton width={71} height={15} />
            <Skeleton width={160} height={44} />
          </S.Column>
        </S.DetailRow>

        <br />
        <S.DetailRow>
          <S.Column>
            <Skeleton width={141} height={15} />
            <Skeleton width={200} height={44} />
          </S.Column>
        </S.DetailRow>

        <br />
        <S.DetailRow>
          <S.Column>
            <Skeleton width={271} height={15} />
            <Skeleton width={360} height={44} />
          </S.Column>
        </S.DetailRow>
      </S.DetailContainer>
    </S.Container>
  );
};

export default SkeletonDadosBasicos;
