import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';

import DetalhesAdm from '../../pages/DetalhesAdm';
import Especialistas from '../../pages/Especialistas';
import Monitoramento from '../../pages/Monitoramento';
import Historico from '../../pages/Historico';

import SidebarMenuAdm from '../../domain/Sidebar/SidebarMenuAdm';

import { Container } from './styles';
import ConfiguracoesAdm from '../../pages/ConfiguracoesAdm';

const NotFound = () => {
  return (
    <>
      <h2 style={{ margin: '30px' }}>
        Página <code>{useLocation().pathname}</code> não encontrada!
        <span role="img" aria-label="emoji triste">
          😢
        </span>
      </h2>
    </>
  );
};

const AdminLayout: React.FC = () => {
  return (
    <Container>
      <SidebarMenuAdm />
      <Switch>
        <Route exact path="/admin">
          <Redirect to="/admin/especialistas" />
        </Route>
        <Route path="/admin/especialistas" exact component={Especialistas} />
        <Route path="/admin/monitoramento" exact component={Monitoramento} />
        <Route path="/admin/historico-consultas" exact component={Historico} />
        <Route path="/admin/configuracoes" exact component={ConfiguracoesAdm} />
        <Route path="/admin/detalhes/:id" exact>
          <DetalhesAdm />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Container>
  );
};

export default AdminLayout;
