import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Cadastro from '../../pages/Cadastro';
import LoginAdm from '../../pages/LoginAdm';
import LoginGerente from '../../pages/LoginGerente';
import LoginUsers from '../../pages/LoginUsers';
import PasswordRecovery from '../../pages/PasswordRecovery';
import PasswordRecoveryAdm from '../../pages/PasswordRecoveryAdm';

const AuthLayout: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/auth/password-recovery/adm"
        exact
        component={PasswordRecoveryAdm}
      />
      <Route
        path="/auth/password-recovery"
        exact
        component={PasswordRecovery}
      />
      <Route path="/auth/cadastro" exact component={Cadastro} />
      <Route path="/auth/login" exact component={LoginGerente} />
      <Route path="/auth/login/adm" exact component={LoginAdm} />
      <Route path="/auth/login/users-policy" exact component={LoginUsers} />
      <Route>
        <Redirect to="/auth/login" />
      </Route>
    </Switch>
  );
};

export default AuthLayout;
