export const TOKEN_KEY = 'jwt';

export const isAuthenticatedAs = (role: number): boolean => {
  if (
    sessionStorage.getItem(TOKEN_KEY) !== null &&
    sessionStorage.getItem('user') !== null
  ) {
    if (JSON.parse(sessionStorage.getItem('user')!).tipo === role) return true;
  }
  return false;
};

export const getUser = (): Record<string, number> =>
  sessionStorage.getUser('user');

export const getToken = (): string | null => sessionStorage.getItem(TOKEN_KEY);

export const login = (user: string, token: string): void => {
  sessionStorage.setItem('user', user);
  sessionStorage.setItem(TOKEN_KEY, token);
};

export const logout = (): void => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem(TOKEN_KEY);
};
