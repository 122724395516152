import axios from 'axios';
import { getToken, logout } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      alert('Sua sessão expirou! Faça login novamente para continuar.');
      logout();
      return window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default api;
