import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import TableWarning from '../../components/TableWarning';
import InputField from '../../components/InputField';

import ModalData from '../../domain/Modal/ModalData';
import ModalCidade from '../../domain/Modal/ModalCidade';
import ModalEspecialidade from '../../domain/Modal/ModalEspecialidade';

import * as S from './styles';

import * as I from './interface';

import SkeletonContainer from './skeleton';
import api from '../../services/api';

const Especialistas: React.FC = () => {
  const containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  const [loading, setLoading] = useState(false);
  const [connectionError, setConnectionError] = useState(false);

  const [specList, setSpecList] = useState(['']);
  const [cityList, setCityList] = useState(['']);

  const [filterOptions, setFilterOptions] = useState<I.Filter>({
    nome: '',
    data: {
      label: '',
      de: '',
      ate: '',
    },
    cidade: '',
    especialidade: '',
  });

  const [modals, setModals] = useState<{ [key: string]: any }>({
    especialidade: {
      id: 2,
      state: false,
      top: 0,
      left: 0,
    },
    cidade: {
      id: 1,
      state: false,
      top: 0,
      left: 0,
    },
    data: {
      id: 0,
      state: false,
      top: 0,
      left: 0,
    },
    status: {
      id: 0,
      state: false,
      top: 0,
      left: 0,
    },
  });

  const [data, setData] = useState<I.Data[]>([]);

  const [filteredData, setFilteredData] = useState<I.Data[]>([...data]);

  const toggleModal = (
    type: string,
    id?: number,
    top?: number,
    left?: number,
  ) => {
    return id
      ? setModals({
          ...modals,
          [type]: {
            id,
            state: !modals[type].state,
            top,
            left,
          },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  const formattedDate = (date: string) => moment(date, 'DDMMYYYY').clone();

  const getInitials = (name: string) => {
    const initials = name
      .split(' ')
      .map((n) => n[0])
      .filter((w) => w !== undefined);
    if (!initials[0]) return '';
    if (!initials[1]) return initials[0].toUpperCase();
    return (
      initials[0].toUpperCase() + initials[initials.length - 1].toUpperCase()
    );
  };

  useEffect(() => {
    const newData = data
      // Filtragem por nome
      .filter((consulta) =>
        filterOptions.nome!.trim() !== ''
          ? consulta.especialista
              .toLowerCase()
              .includes(filterOptions.nome!.toLowerCase())
          : true,
      )
      // Filtragem por especialidade
      .filter((consulta) =>
        filterOptions.especialidade !== ''
          ? consulta.especialidade.includes(filterOptions.especialidade)
          : true,
      )
      // Filtragem por cidade
      .filter((consulta) =>
        filterOptions.cidade !== ''
          ? consulta.cidade === filterOptions.cidade
          : true,
      )
      // Filtragem por data
      .filter((consulta) => {
        const start = formattedDate(filterOptions.data.de);
        const end = formattedDate(filterOptions.data.ate);
        return start.isValid() && end.isValid()
          ? moment(consulta.data).isBetween(start, end, 'day', '[]')
          : true;
      });

    setFilteredData(newData);
  }, [filterOptions, data]);

  const fetchData = async () => {
    setLoading(true);
    await api
      .get('/adm/medicos')
      .then(({ data: medicos }) => {
        // Recuperando médicos
        const newData = medicos.map((medico: any) => ({
          id: medico.id_medico,
          data: moment(medico.data_cadastro),
          cidade: medico.cidade,
          especialista: medico.nome_completo,
          especialidade: medico.especialidades,
          avatar: medico.avatar ? medico.avatar : '',
          pendente: medico.is_pending,
          ativo: medico.is_active,
        }));
        setData(newData);

        // Extraindo especialidades dos médicos recuperados para posterior filtragem
        const specialities = []
          .concat(...medicos.map((m: any) => m.especialidades))
          .filter((v, i, a) => a.indexOf(v) === i);
        setSpecList(specialities);

        // Extraindo cidades dos médicos recuperados para posterior filtragem
        const cities = medicos
          .map((m: any) => m.cidade)
          .filter((v: any, i: any, a: any) => a.indexOf(v) === i);
        setCityList(cities);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setConnectionError(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <S.Container ref={containerRef}>
      <S.Head>
        <S.Header>
          <S.TitleContainer>
            <S.Headline>
              <S.Icon />
              <S.Title>Especialistas</S.Title>
            </S.Headline>
            <S.Description>
              Encontre e filtre todas os especialistas cadastradas no sistema.
            </S.Description>
          </S.TitleContainer>
        </S.Header>

        <S.FilterContainer>
          <S.InputContainer>
            <InputField
              id="busca"
              value={filterOptions.nome}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFilterOptions({ ...filterOptions, nome: e.target.value })
              }
              placeholder="Procure por nome..."
              width="200px"
            />
            <S.IconSearch />
          </S.InputContainer>

          <S.Filter
            active={
              modals.especialidade.state ||
              filterOptions.especialidade.trim() !== ''
            }
            onClick={({ currentTarget: { offsetTop, offsetLeft } }) =>
              toggleModal(
                'especialidade',
                1,
                offsetTop - (containerRef.current?.scrollTop ?? 0),
                offsetLeft - (containerRef.current?.scrollLeft ?? 0),
              )
            }>
            <S.IconUser />
            {filterOptions.especialidade.trim() !== ''
              ? filterOptions.especialidade
              : 'Especialidade'}
            <S.IconDown />
          </S.Filter>

          <S.Filter
            active={modals.cidade.state || filterOptions.cidade.trim() !== ''}
            onClick={({ currentTarget: { offsetTop, offsetLeft } }) =>
              toggleModal(
                'cidade',
                1,
                offsetTop - (containerRef.current?.scrollTop ?? 0),
                offsetLeft - (containerRef.current?.scrollLeft ?? 0),
              )
            }>
            <S.IconLocation />
            {filterOptions.cidade.trim() !== ''
              ? filterOptions.cidade
              : 'Cidade'}
            <S.IconDown />
          </S.Filter>

          <S.Filter
            active={
              modals.data.state ||
              (formattedDate(filterOptions.data.de).isValid() &&
                formattedDate(filterOptions.data.ate).isValid())
            }
            onClick={({ currentTarget: { offsetTop, offsetLeft } }) =>
              toggleModal(
                'data',
                1,
                offsetTop - (containerRef.current?.scrollTop ?? 0),
                offsetLeft - (containerRef.current?.scrollLeft ?? 0),
              )
            }>
            <S.IconData />
            {filterOptions.data.label.trim() !== ''
              ? filterOptions.data.label
              : 'Qualquer Data'}
            <S.IconDown />
          </S.Filter>
        </S.FilterContainer>
      </S.Head>

      {loading && <SkeletonContainer />}
      {!loading &&
        // eslint-disable-next-line no-nested-ternary
        (filteredData.length === 0 ? (
          connectionError ? (
            <TableWarning noConnection />
          ) : (
            <TableWarning noData />
          )
        ) : (
          <S.Main>
            <S.SubTitleContainer>
              <S.IconContainer>
                <S.IconNotify />
                <S.RedDot />
              </S.IconContainer>
              <S.SubTitle>Solicitações</S.SubTitle>
              <S.Result>
                ({filteredData.filter((s) => s.pendente).length} encontrada(s))
              </S.Result>
            </S.SubTitleContainer>
            <S.CardContainer>
              {filteredData
                .filter((s) => s.pendente)
                .map((specialist) => (
                  <Link
                    key={specialist.id}
                    to={`/admin/detalhes/${specialist.id}`}>
                    <S.Card>
                      {specialist.avatar.trim() !== '' ? (
                        <S.AvatarContainer>
                          <S.Avatar src={specialist.avatar} request />
                        </S.AvatarContainer>
                      ) : (
                        <S.InitialsContainer request>
                          <S.Initials>
                            {getInitials(specialist.especialista)}
                          </S.Initials>
                        </S.InitialsContainer>
                      )}
                      <S.CardName>Dr(a). {specialist.especialista}</S.CardName>
                      <S.CardInfo>{`${specialist.especialidade[0]}${
                        specialist.especialidade[1]
                          ? ` (${specialist.especialidade[1]})`
                          : ''
                      }`}</S.CardInfo>
                      <S.CardInfo>{specialist.cidade}</S.CardInfo>
                      <S.CardInfo>
                        Cadastro em
                        {specialist.data.format('[ ] D[/]MM[/]YYYY')}
                      </S.CardInfo>
                    </S.Card>
                  </Link>
                ))}
            </S.CardContainer>
            <S.SubTitleContainer>
              <S.IconContainer>
                <S.IconResult />
              </S.IconContainer>
              <S.SubTitle>Resultados</S.SubTitle>
              <S.Result>
                ({filteredData.filter((s) => !s.pendente).length} encontrado(s))
              </S.Result>
            </S.SubTitleContainer>
            <S.CardContainer>
              {filteredData
                .filter((s) => !s.pendente)
                .map((specialist) => (
                  <Link
                    key={specialist.id}
                    to={`/admin/detalhes/${specialist.id}`}>
                    <S.Card>
                      {specialist.avatar.trim() !== '' ? (
                        <S.AvatarContainer>
                          <S.Avatar src={specialist.avatar} />
                        </S.AvatarContainer>
                      ) : (
                        <S.InitialsContainer>
                          <S.Initials>
                            {getInitials(specialist.especialista)}
                          </S.Initials>
                        </S.InitialsContainer>
                      )}

                      <S.CardName>Dr(a). {specialist.especialista}</S.CardName>
                      <S.CardInfo>{`${specialist.especialidade[0]}${
                        specialist.especialidade[1]
                          ? ` (${specialist.especialidade[1]})`
                          : ''
                      }`}</S.CardInfo>
                      <S.CardInfo>{specialist.cidade}</S.CardInfo>
                      <S.CardInfo>
                        Cadastro em
                        {specialist.data.format('[ ] DD[/]MM[/]YYYY')}
                      </S.CardInfo>
                      <S.Status active={specialist.ativo}>
                        {specialist.ativo ? ' Ativo' : ' Inativo'}
                      </S.Status>
                    </S.Card>
                  </Link>
                ))}
            </S.CardContainer>
          </S.Main>
        ))}

      <AnimatePresence>
        {modals.cidade.state && (
          <ModalCidade
            id={modals.cidade.id}
            top={modals.cidade.top}
            left={modals.cidade.left}
            closeModal={() => toggleModal('cidade')}
            options={cityList}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
        )}
        {modals.especialidade.state && (
          <ModalEspecialidade
            id={modals.especialidade.id}
            top={modals.especialidade.top}
            left={modals.especialidade.left}
            closeModal={() => toggleModal('especialidade')}
            options={specList}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
        )}
        {modals.data.state && (
          <ModalData
            id={modals.data.id}
            top={modals.data.top}
            left={modals.data.left}
            closeModal={() => toggleModal('data')}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            onlyAtThisMoment
          />
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default Especialistas;
