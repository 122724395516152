import React from 'react';

import Empty from '../../assets/images/undraw_empty.svg';
import Cancel from '../../assets/images/undraw_cancel.svg';

import * as S from './styles';

import * as I from './interfaces';

const TableWarning: React.FC<I.Props> = ({ noData, noConnection }) => {
  return (
    <S.Container>
      {noData && (
        <>
          <S.Svg src={Empty} />
          <S.Title>Nenhum registro</S.Title>
          <S.Description>
            Não há consultas, experimente mudar o status da consulta
          </S.Description>
        </>
      )}
      {noConnection && (
        <>
          <S.Svg src={Cancel} />
          <S.Title>Problemas de conexão</S.Title>
          <S.Description>
            Houve algum problema, verifique sua internet ou
            <S.Link onClick={() => window.location.reload()} href="">
              recarregue a página
            </S.Link>
          </S.Description>
        </>
      )}
    </S.Container>
  );
};

export default TableWarning;
