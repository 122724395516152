import styled from 'styled-components';

export const Container = styled.div`
  width: 86%;
  height: 85%;
  margin: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const PrimaryMessage = styled.h1`
  font-size: 28px;
  font-weight: 900;
  margin: 0;
`;

export const SecondaryMessage = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin: 18px 0 0 0;
  opacity: 0.6;
`;

export const Footnote = styled.p`
  position: absolute;
  bottom: 0;
  font-size: 13px;
  opacity: 0.6;
  margin-bottom: 30px;
`;
