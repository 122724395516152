import styled, { css } from 'styled-components';
import { RiMapPinLine } from 'react-icons/ri';
import {
  BiClipboard,
  BiCalendar,
  BiFilter,
  BiUser,
  BiChevronDown,
  BiUserPin,
} from 'react-icons/bi';

interface Active {
  active: boolean;
}

interface Disabled {
  disabled: boolean;
}

const iconStyle = css`
  width: 18px;
  height: 18px;
  margin-right: 6px;
  transition: all 0.2s ease-in;
`;

export const Container = styled.div`
  width: calc(100%-64px);
  display: flex;
  flex-direction: column;
  height: 100vh;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Logo = styled.img`
  width: 400px;
`;

export const Head = styled.div`
  width: calc(100% - 290px);
  position: absolute;
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  padding: 32px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-right: 6px;
`;

export const Main = styled.div`
  padding: 10px 32px;
  margin-top: 222px;
  overflow: auto;
`;

export const TitleContainer = styled.div`
  max-width: 600px;
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled(BiClipboard)`
  width: 24px;
  height: 24px;
  fill: #2fa8d5;
  margin-right: 8px;
`;

export const Title = styled.h1`
  font-size: 22px;
  color: #2fa8d5;
  font-weight: 600;
  margin: 0;
`;

export const Description = styled.p`
  line-height: 1.3rem;
  color: #333;
  font-size: 14px;
`;

export const ButtonContainer = styled.div<Disabled>`
  display: flex;

  button {
    margin-left: 12px;
    opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

export const FilterContainer = styled.div`
  width: calc(100%-40px);
  height: 18px;
  border-radius: 6px;
  border: 1px solid #dfdfdf;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-right: 6px;
`;

export const IconData = styled(BiCalendar)`
  ${iconStyle}
`;

export const IconFilter = styled(BiFilter)`
  ${iconStyle}
`;

export const IconUser = styled(BiUser)`
  ${iconStyle}
`;

export const IconEspecialidade = styled(BiUserPin)`
  ${iconStyle}
`;

export const IconLocation = styled(RiMapPinLine)`
  ${iconStyle}
`;

export const IconDown = styled(BiChevronDown)`
  ${iconStyle}
  margin: 0;
`;

export const Filter = styled.div<Active>`
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  font-size: 14px;
  margin-right: 32px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${IconData} {
    fill: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  }
  ${IconFilter} {
    fill: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  }
  ${IconUser} {
    fill: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  }
  ${IconDown} {
    fill: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  }
`;
