/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

import * as S from './styles';

interface Props {
  msg: string;
  type: 'success' | 'error' | 'info';
  closeModal: () => void;
}

const ModalAlert: React.FC<Props> = ({ msg, closeModal, type = 'info' }) => {
  const [titulo, color] = {
    info: ['INFORMAÇÃO', '#2fa8d5'],
    success: ['SUCESSO', '#00cc8f'],
    error: ['ERRO', '#f03434'],
  }[type];

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer>
        <S.Header>
          <S.Title color={color}> {titulo} </S.Title>
          <S.Text> {msg} </S.Text>
        </S.Header>
        <S.LinkContainer>
          <S.Link onClick={closeModal}>Fechar</S.Link>
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalAlert;
