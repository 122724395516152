import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';

export const AddIcon = styled(FaPlus)``;

export const TextContent = styled.p`
  margin-left: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #2a3748;
`;

export const Container = styled.div`
  width: 143px;
  height: 36px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2a3748;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  transition: 0.1s all ease-in;

  ${AddIcon} {
    color: #2a3748;
  }

  &:hover {
    background-color: #2a3748;

    ${TextContent} {
      color: #fff;
    }

    ${AddIcon} {
      color: #fff;
    }

    &:active {
      background-color: #2a3748;
    }
  }
`;
