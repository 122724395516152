import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fade-in 0.25s ease-in;
  opacity: 0.8;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Username = styled.p`
  font-size: 24px;
  color: #2a3748;
  margin: 0;
`;

export const Time = styled.h1`
  font-size: 92px;
  color: #2a3748;
  margin: 10px;
`;

export const Date = styled.p`
  font-size: 18px;
  color: #2a3748;
  margin: 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

export const Logo = styled.img`
  width: 400px;
`;

export const Separator = styled.div`
  width: 15px;
  height: 2px;
  /* border-radius: 50%; */
  background-color: #2a374870;
  margin-bottom: 50px;
`;
