import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ImageContainer = styled.div`
  width: 50vw;
  height: 100vh;
  overflow: hidden;
`;

export const BackgroundContainer = styled.div`
  width: 50vw;
  height: 100vh;
  position: relative;
`;

export const Background = styled.img`
  width: 200%;
  height: 200%;
  position: absolute;
  left: -50%;
  top: 15%;
`;

export const Logo = styled.img`
  width: 222px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
