import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import api from '../../../services/api';

import { LoadingSpinner } from '../../../components';

import * as S from './styles';

interface Props {
  id?: string;
  closeModal: () => void;
  closeAll: () => void;
}

const ModalFinalizarConsulta: React.FC<Props> = ({
  id,
  closeModal,
  closeAll,
}) => {
  const [loading, setLoading] = useState(false);

  const close = () => {
    closeModal();
    closeAll();
  };

  const confirm = async () => {
    setLoading(true);
    api.put(`/consult/finalizar/${id}`).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    confirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && close()}>
      <S.ModalContainer>
        <S.Header>
          <S.Title>Consulta finalizada</S.Title>
          <S.Text>
            {loading
              ? 'Finalizando consulta...'
              : 'Consulta finalizada. Você ainda pode vê-la no painel de relatório.'}
          </S.Text>
        </S.Header>
        <S.LinkContainer>
          {!loading ? (
            <>
              <S.Link onClick={close}>Fechar</S.Link>
            </>
          ) : (
            <LoadingSpinner color="#00cc8f" />
          )}
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalFinalizarConsulta;
