import React from 'react';

import { ConselhoButton } from '../../../components';

import * as S from './styles';

interface Conselho {
  id: number;
  conselho: string;
}

interface Props {
  conselhos: Conselho[];
  toggleModal: (type: string, id?: number | undefined) => void;
}

const ConfigConselhos: React.FC<Props> = ({ conselhos, toggleModal }) => {
  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Lista de Conselhos</S.SubTitle>
        {/* <ActionButton
          onClick={() => toggleModal('novoConselho')}
          value="Novo Conselho"
        /> */}
      </S.SubTitleContainer>
      <S.DetailsContainer>
        {conselhos
          .sort((a, b) => a.conselho.localeCompare(b.conselho))
          .map((conselho) => (
            <ConselhoButton
              key={conselho.id}
              onClick={() => toggleModal('alterarconselho', +conselho.id)}
              value={`${conselho.conselho}`}
            />
          ))}
      </S.DetailsContainer>
    </S.Container>
  );
};

export default ConfigConselhos;
