import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import api from '../../../services/api';

import { LoadingSpinner } from '../../../components';

import * as S from './styles';

interface Especialidade {
  id: number;
  especialidade: string;
  is_pending: boolean;
}

interface Props {
  id: number;
  closeModal: () => void;
  fetchData: () => void;
}

const ModalAceitarEspecialidade: React.FC<Props> = ({
  id,
  closeModal,
  fetchData,
}) => {
  const [loading, setLoading] = useState(false);
  const [especialidade, setEspecialidade] = useState<Especialidade>();

  const getEspecialidade = () => {
    setLoading(true);

    api
      .get<Especialidade>(`/lista-especialidades/${id}`)
      .then((resp) => setEspecialidade(resp.data))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const updateEspecialidade = () => {
    api
      .put(`/lista-especialidades/${especialidade?.id}`, {
        is_pending: false,
      })
      .catch(() => {})
      .finally(() => {
        closeModal();
        fetchData();
      });
  };

  useEffect(() => {
    getEspecialidade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer>
        <S.Content>
          <S.Header>
            <S.Title>Aceitar especialidade</S.Title>
            <S.Text>
              {!loading && especialidade
                ? `Gostaria de aceitar a especialidade ${especialidade.especialidade}?`
                : 'Processando...'}
            </S.Text>
          </S.Header>
        </S.Content>
        <S.LinkContainer>
          {!loading && especialidade ? (
            <>
              <S.Link onClick={closeModal}>Fechar</S.Link>
              <S.Link onClick={updateEspecialidade} primary>
                Confirmar
              </S.Link>
            </>
          ) : (
            <LoadingSpinner color="#2fa8d5" />
          )}
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalAceitarEspecialidade;
