import styled from 'styled-components';

interface WeekButtonProps {
  active?: boolean;
}

export const Container = styled.div`
  width: calc(100% - 64px);
  height: calc(100% - 64px);
  padding: 32px;
`;

export const TitleContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;

  button {
    margin: 0 0 0 8px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  color: #2a3748;
  margin: 0 0 16px 0;
`;

export const Description = styled.h4`
  max-width: 470px;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 32px 0;
  color: rgba(0, 0, 0, 0.6);
`;

export const Label = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const WeekButtonContainer = styled.div`
  display: flex;
  align-items: center;

  button:not(:last-child) {
    margin-right: 16px;
  }
`;

export const WeekButton = styled.button<WeekButtonProps>`
  width: 48px;
  height: 44px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: ${(props) => (props.active ? 'none' : '1px solid #c2c2c2')};
  background-color: ${(props) => (props.active ? '#2fa8d5' : 'white')};
  color: ${(props) => (props.active ? 'white' : '#717171')};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.active ? '1' : '0.5')};
  filter: ${(props) =>
    props.disabled ? 'opacity(90%) grayscale(100%)' : 'none'};

  &:focus {
    outline: none;
  }
`;

export const Hour = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  input {
    margin-left: 16px;
    opacity: 0.85;
  }
`;

export const Duration = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
