import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import axios from 'axios';

import {
  Label,
  Formacao,
  AddButton,
  SelectOne,
  InputField,
  SelectMult,
  Experiencia,
  ActionButton,
  Especialidade,
  InputNumberField,
} from '../../../components';

import {
  eqMedicaValidator,
  expFormValidator,
} from '../../../utils/formValidator';

import * as S from './styles';

import * as I from './interface';
import api from '../../../services/api';

interface Props {
  id?: string;
  readOnly?: boolean;
  closeModal: () => void;
  fetchData: () => void;
}

const ModalNovoMedico: React.FC<Props> = ({ closeModal, fetchData }) => {
  const [step, setStep] = useState(1);
  const [load, setLoad] = useState<boolean>(false);

  const [conselhos, setConselhos] = useState<string[]>([]);
  const [estados, setEstados] = useState<string[]>([]);
  const [especialidades, setEspecialidades] = useState<string[]>([]);
  const [convenios, setConvenios] = useState<string[]>([]);

  const [imageUpload, setImageUpload] = useState<FormData>();
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    message: '',
  });

  const [data, setData] = useState<I.Data>({
    avatar: '',
    conselho: '',
    estadoConselho: '',
    numeroRegistro: '',
    nomeCompleto: '',
    cpfMedico: '',
    rgMedico: '',
    emailContato: '',
    telefoneContato: '',
    pessoaContato: '',
    especialidades: [{ especialidade: '', rqe: '', favorita: true }],
    valor: '',
    temConvenio: false,
    convenios: [],
    experiencias: [{ titulo: '', descricao: '', ano: '' }],
    formacoes: [{ instituicao: '', curso: '', ano: '' }],
  });

  const [errorDataStep1, setErrorDataStep1] = useState<I.ErrorDataStep1>({
    conselho: '',
    estadoConselho: '',
    numeroRegistro: '',
    nomeCompleto: '',
    cpfMedico: '',
    rgMedico: '',
    emailContato: '',
    telefoneContato: '',
    pessoaContato: '',
    especialidades: [{ especialidade: '', rqe: '', favorita: '' }],
    valor: '',
    temConvenio: '',
    convenios: '',
  });

  const [errorDataStep2, setErrorDataStep2] = useState<I.ErrorDataStep2>({
    experiencias: [{ titulo: '', descricao: '', ano: '' }],
    formacoes: [{ instituicao: '', curso: '', ano: '' }],
  });

  const toggleConvenio = (e: React.ChangeEvent<HTMLInputElement>) => {
    return e.target.checked
      ? setData!({ ...data, temConvenio: true })
      : setData!({ ...data, temConvenio: false, convenios: [] });
  };

  const addEsp = () => {
    setData!({
      ...data!,
      especialidades: [
        ...data.especialidades,
        { especialidade: '', rqe: '', favorita: false },
      ],
    });
    setErrorDataStep1({
      ...errorDataStep1,
      especialidades: [
        ...errorDataStep1.especialidades,
        { especialidade: '', rqe: '', favorita: '' },
      ],
    });
  };

  const handleChangePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target !== null) {
      const { files } = e.target;
      const form = new FormData();

      if (files!.length === 0) return;
      if (files![0].size > 1 * 1024 * 1024) {
        setImageUploadError({
          error: true,
          message: 'O arquivo escolhido ultrapassa o tamanho máximo de 5mb',
        });
        return;
      }
      if (files![0])
        setData((state) => ({
          ...state,
          avatar: URL.createObjectURL(files![0]),
        }));
      setImageUploadError({ error: false, message: '' });
      form.append('file', files![0]);
      setImageUpload(form);
    }
  };

  const handleChange = (field: any, value: any) => {
    setErrorDataStep1({ ...errorDataStep1, [field]: '' });
    setData!({ ...data, [field]: value });
  };

  const setErrorEsp = (esp: any) => {
    setErrorDataStep1({ ...errorDataStep1, especialidades: esp });
  };

  const setEsp = (esp: any) => {
    setData!({ ...data, ...esp });
  };

  const addExp = () => {
    setData({
      ...data,
      experiencias: [
        ...data.experiencias,
        { titulo: '', descricao: '', ano: '' },
      ],
    });
    setErrorDataStep2({
      ...errorDataStep2,
      experiencias: [
        ...errorDataStep2.experiencias,
        { titulo: '', descricao: '', ano: '' },
      ],
    });
  };

  const addForm = () => {
    setData({
      ...data,
      formacoes: [...data.formacoes, { instituicao: '', curso: '', ano: '' }],
    });
    setErrorDataStep2({
      ...errorDataStep2,
      formacoes: [
        ...errorDataStep2.formacoes,
        { instituicao: '', curso: '', ano: '' },
      ],
    });
  };

  const setExpForm = (expForm: any) => {
    setData!((state) => ({ ...state, ...expForm }));
  };

  const next = async () => {
    const { experiencias, formacoes, ...dataToValidate } = data;
    setLoad(true);
    if (await eqMedicaValidator(dataToValidate, setErrorDataStep1)) {
      setStep(step + 1);
    }
    setLoad(false);
  };

  const confirm = async () => {
    const { experiencias, formacoes } = data;
    setLoad(true);
    if (
      await expFormValidator({ experiencias, formacoes }, setErrorDataStep2)
    ) {
      api
        .post('/medico', {
          medico: {
            conselho: data.conselho,
            estado_conselho: data.estadoConselho,
            registro: data.numeroRegistro,
            nome_completo: data.nomeCompleto,
            cpf: data.cpfMedico,
            rg: data.rgMedico,
            email_contato: data.emailContato,
            telefone_contato: data.telefoneContato,
            pessoa_contato: data.pessoaContato,
            valor_padrao: data.valor,
          },
          convenios: data.convenios.map((convenio) => ({ convenio })),
          especialidades: data.especialidades.map(
            ({ especialidade, rqe, favorita }) => ({
              nome: especialidade,
              rqe,
              is_favorite: favorita,
            }),
          ),
          experiencias: data.experiencias.map(({ titulo, descricao, ano }) => ({
            titulo,
            descricao,
            e_ano: ano,
          })),
          formacoes: data.formacoes.map(({ instituicao, curso, ano }) => ({
            instituicao,
            curso,
            f_ano: ano,
          })),
        })
        .then(({ data: { id_medico } }) => {
          if (!imageUpload || imageUpload!.entries().next().done)
            return id_medico;
          const form = imageUpload!;
          form.append('id_user', id_medico);
          return api.post(`/avatar/medicos`, form, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        })
        .then(() => {
          setLoad(false);
          setStep(step + 1);
        });
    } else {
      setLoad(false);
    }
  };

  const prev = () => {
    setStep(step - 1);
  };

  const closeAndFetch = () => {
    closeModal();
    fetchData();
  };

  const getInitials = (name: string) => {
    const initials = name
      .split(' ')
      .map((n) => n[0])
      .filter((w) => w !== undefined);
    if (!initials[0]) return '';
    if (!initials[1]) return initials[0].toUpperCase();
    return (
      initials[0].toUpperCase() + initials[initials.length - 1].toUpperCase()
    );
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <S.AvatarContainer>
              <S.PhotoContainer>
                <>
                  {data.avatar.trim() !== '' ? (
                    <S.Photo src={data.avatar} />
                  ) : (
                    <S.Initials>{getInitials(data.nomeCompleto)}</S.Initials>
                  )}
                  <S.UpdateMessage>Clique para alterar</S.UpdateMessage>
                </>
                <S.Input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={handleChangePicture}
                />
                {imageUploadError.error && (
                  <S.ErrorMessage>
                    <S.ErrorIcon />
                    <p>{imageUploadError.message}</p>
                  </S.ErrorMessage>
                )}
              </S.PhotoContainer>
            </S.AvatarContainer>

            <S.DetailContainer>
              <S.DetailRow>
                <InputField
                  id="nomeCompleto"
                  value={data.nomeCompleto}
                  onChange={(e) => handleChange('nomeCompleto', e.target.value)}
                  placeholder="John Smith Doe"
                  labelText="Nome completo (como consta no conselho)"
                  width="329px"
                  invalid={errorDataStep1.nomeCompleto}
                />
              </S.DetailRow>
              <S.DetailRow>
                <SelectOne
                  id="conselho"
                  value={data.conselho}
                  onChangeValue={(opt) => handleChange('conselho', opt)}
                  labelText="Conselho"
                  width="150px"
                  options={conselhos}
                  invalid={errorDataStep1.conselho}
                />

                <SelectOne
                  id="estadoConselho"
                  value={data.estadoConselho}
                  onChangeValue={(opt) => handleChange('estadoConselho', opt)}
                  labelText="Estado"
                  width="150px"
                  options={estados}
                  invalid={errorDataStep1.estadoConselho}
                />
              </S.DetailRow>

              <S.DetailRow>
                <InputNumberField
                  id="numeroRegistro"
                  value={data.numeroRegistro}
                  onChange={({ value }) =>
                    handleChange('numeroRegistro', value)
                  }
                  placeholder="00000000"
                  labelText="Número de registro"
                  width="165px"
                  invalid={errorDataStep1.numeroRegistro}
                />
              </S.DetailRow>
              {data.especialidades.map(
                (especialidade: I.Especialidades, index: number) => {
                  return (
                    <S.DetailRow key={`esp-${index.toString()}`}>
                      <Especialidade
                        index={index}
                        especialidade={especialidade}
                        options={especialidades}
                        data={data.especialidades}
                        setData={setEsp}
                        errorData={errorDataStep1.especialidades}
                        setErrorData={setErrorEsp}
                      />
                    </S.DetailRow>
                  );
                },
              )}

              {data.especialidades.length < 2 && (
                <AddButton
                  onClick={addEsp}
                  id="addEspecialidade"
                  text="Especialidade"
                />
              )}
              <S.DetailRow>
                <InputNumberField
                  id="cpfMedico"
                  value={data.cpfMedico}
                  onChange={({ value }) => handleChange('cpfMedico', value)}
                  placeholder="000.000.000-00"
                  labelText="CPF"
                  width="145px"
                  format="###.###.###-##"
                  mask="_"
                  invalid={errorDataStep1.cpfMedico}
                />

                <InputNumberField
                  id="rgMedico"
                  value={data.rgMedico}
                  onChange={({ value }) => handleChange('rgMedico', value)}
                  placeholder="00.000.000-0"
                  labelText="RG"
                  width="135px"
                  format="##.###.###-#"
                  mask="_"
                  invalid={errorDataStep1.rgMedico}
                />
              </S.DetailRow>

              <S.DetailRow>
                <InputField
                  id="emailContato"
                  value={data.emailContato}
                  onChange={(e) => handleChange('emailContato', e.target.value)}
                  placeholder="email@dominio.com"
                  labelText="E-mail de contato"
                  width="160px"
                  invalid={errorDataStep1.emailContato}
                />

                <InputNumberField
                  id="telefoneContato"
                  value={data.telefoneContato}
                  onChange={({ value }) =>
                    handleChange('telefoneContato', value)
                  }
                  placeholder="(00) 00000-0000"
                  labelText="Celular de contato"
                  width="120px"
                  format="(##) #####-####"
                  mask="_"
                  invalid={errorDataStep1.telefoneContato}
                />
              </S.DetailRow>

              <S.DetailRow>
                <InputField
                  id="pessoaContato"
                  value={data.pessoaContato}
                  onChange={(e) =>
                    handleChange('pessoaContato', e.target.value)
                  }
                  placeholder="Jane Smith Doe"
                  labelText="Pessoa de Contato"
                  width="329px"
                  invalid={errorDataStep1.pessoaContato}
                  optional
                />
              </S.DetailRow>
            </S.DetailContainer>

            {/* <S.SpecContainer>
              <S.Subtitle>Especialidades atendidas</S.Subtitle>
              <S.Description>
                Adicione uma ou mais especialidades. Escolha uma ou duas
                especialidades para aparecerem aos pacientes clicando no icone
                &quot;
                <S.Bookmark />
                &quot;. Não se preocupe! Você poderá fazer alterações após
                concluir o cadastro.
              </S.Description>

              
            </S.SpecContainer> */}

            <S.PartConvContainer>
              <S.Subtitle>Particular &amp; Convênios</S.Subtitle>
              <S.Description>Consulta particular</S.Description>

              <S.DetailRow>
                <InputNumberField
                  id="valor"
                  value={data.valor}
                  onChange={({ value }) => handleChange('valor', value)}
                  placeholder="R$ 0,00"
                  labelText="Valor"
                  width="109px"
                  invalid={errorDataStep1.valor}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                />
              </S.DetailRow>

              <S.CheckboxContainer>
                <S.Checkbox
                  checked={data.temConvenio}
                  type="checkbox"
                  name="convenio"
                  id="convenio"
                  onChange={toggleConvenio}
                />
                <Label id="convenio" labelText="Atendimento com convênios" />
              </S.CheckboxContainer>

              {data.temConvenio && (
                <S.DetailRow>
                  <SelectMult
                    id="convOptions"
                    value={data.convenios}
                    onChangeValue={(opts) => handleChange('convenios', opts)}
                    labelText="Convênios atendidos"
                    options={convenios}
                    invalid={errorDataStep1.convenios}
                  />
                </S.DetailRow>
              )}
            </S.PartConvContainer>
          </>
        );
      case 2:
        return (
          <>
            <S.ContentContainer>
              <S.Subtitle>Experiência</S.Subtitle>

              {data.experiencias.map(
                (experiencia: I.Experiencias, index: number) => (
                  <S.ContentRow key={`exp-${index.toString()}`}>
                    <Experiencia
                      index={index}
                      experiencia={experiencia}
                      data={{
                        experiencias: data.experiencias,
                        formacoes: data.formacoes,
                      }}
                      setData={setExpForm}
                      errorData={errorDataStep2}
                      setErrorData={setErrorDataStep2}
                    />
                  </S.ContentRow>
                ),
              )}

              <AddButton id="experiencia" text="Experiência" onClick={addExp} />
            </S.ContentContainer>

            <S.ContentContainer>
              <S.Subtitle>Formação</S.Subtitle>

              {data.formacoes.map((formacao: I.Formacoes, index: number) => (
                <S.ContentRow key={`form-${index.toString()}`}>
                  <Formacao
                    index={index}
                    formacao={formacao}
                    data={{
                      experiencias: data.experiencias,
                      formacoes: data.formacoes,
                    }}
                    setData={setExpForm}
                    errorData={errorDataStep2}
                    setErrorData={setErrorDataStep2}
                  />
                </S.ContentRow>
              ))}

              <AddButton id="formacao" text="Formação" onClick={addForm} />
            </S.ContentContainer>
          </>
        );
      case 3:
        return (
          <S.SuccessMessageContainer>
            <S.Subtitle>Cadastro realizado com sucesso!</S.Subtitle>
            <S.Description>
              Você pode acessar o cadastro do novo médico no painel de
              configurações.
            </S.Description>
          </S.SuccessMessageContainer>
        );
      default:
        return <h3>Ocorreu um erro! Reinicie a página e tente novamente.</h3>;
    }
  };

  useEffect(() => {
    api
      .get('/lista-especialidades')
      .then(({ data: listaEspecs }) =>
        setEspecialidades(
          listaEspecs
            .map((e: { especialidade: string }) => e.especialidade)
            .sort(),
        ),
      );
    api
      .get('/lista-conselhos')
      .then(({ data: listaConselhos }) =>
        setConselhos(
          listaConselhos.map((c: { conselho: string }) => c.conselho).sort(),
        ),
      );
    api
      .get('/lista-convenios')
      .then(({ data: listaConvenios }) =>
        setConvenios(
          listaConvenios.map((c: { convenio: string }) => c.convenio).sort(),
        ),
      );
    axios
      .get<{ sigla: string }[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
      )
      .then((res) => {
        const ufs = res.data.map((uf) => uf.sigla).sort();
        setEstados(ufs);
      });
  }, []);

  return ReactDom.createPortal(
    <>
      <S.Background
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
        onClick={(e: { target: any; currentTarget: any }) =>
          e.target === e.currentTarget &&
          (step === 1 ? closeModal() : closeAndFetch())
        }>
        <S.ModalContainer>
          <S.Content>
            <S.Title>Novo Médico</S.Title>
            {renderStep()}
          </S.Content>
          <S.ButtonContainer>
            {step === 1 && (
              <>
                <ActionButton onClick={closeModal} value="Cancelar" />
                <ActionButton onClick={next} value="Continuar" primary />
              </>
            )}
            {step === 2 && (
              <>
                <ActionButton onClick={prev} value="Voltar" />
                <ActionButton
                  onClick={confirm}
                  value="Confirmar"
                  primary
                  loading={load}
                />
              </>
            )}
            {step === 3 && (
              <>
                <ActionButton onClick={closeAndFetch} value="Fechar" />
              </>
            )}
          </S.ButtonContainer>
        </S.ModalContainer>
      </S.Background>
    </>,

    document.getElementById('portal')!,
  );
};

export default ModalNovoMedico;
