import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';

export const Container = styled.div`
  width: 86%;
  height: 85%;
  margin: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 11px;
`;

export const PrimaryMessage = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: #2fa8d5;
`;

export const FormContainer = styled.form`
  margin-right: -16px;
`;

export const SuccessContainer = styled.div`
  background-color: #fcfff5;
  border: 1px solid #abc599;
  border-radius: 4px;
  margin: 30px 16px 0 0;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const SuccessMessage = styled.p`
  color: #1e561f;
  font-weight: bold;
  font-size: 18px;
  line-height: 0;
`;

export const SuccessDescription = styled.p`
  color: #1e561f;
  opacity: 0.7;
  font-size: 14px;
  line-height: 0;
`;

export const ArrowContainer = styled.div`
  width: 41px;
  height: 100%;
  background-color: #1d7cc4;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Arrow = styled(FaArrowRight)`
  color: #fff;
`;

export const BtnLogin = styled.button`
  width: 110px;
  height: 38px;
  padding: 12px 16px;
  display: flex;
  border: none;
  border-radius: 4px;
  background-color: #2185d0;
  color: #fff;
  font-weight: 600;
  margin-top: 27px;
  position: relative;
  cursor: pointer;

  &:focus {
    ${ArrowContainer} {
      top: 1px;
      right: 1px;
      height: 36px;
      width: 40px;
    }
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 16px 0 0;
`;
