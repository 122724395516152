import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const ConvenioButton: React.FC<I.Props> = ({ value, onClick, ...res }) => {
  return (
    <S.Button {...res}>
      <S.Content>
        <S.Icon />
        <S.Text>{value}</S.Text>
      </S.Content>
      <S.IconArrow onClick={onClick} />
    </S.Button>
  );
};

export default ConvenioButton;
