import styled from 'styled-components';

import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';

interface BookmarkProps {
  active: string;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Bookmark = styled(BsBookmark)<BookmarkProps>`
  width: 20px;
  height: 20px;
  color: #2a3748;

  margin: 40px 16px 0 -6px;
  opacity: ${(props) => (props.active === 'true' ? '0.75' : '0.2')};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
export const BookmarkFill = styled(BsBookmarkFill)`
  width: 20px;
  height: 20px;
  color: #2a3748;
  margin: 40px 16px 0 -6px;
  opacity: 0.65;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
