import React, { useState, useContext, useRef } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import api from '../../../services/api';

import { InputNumberField, ActionButton } from '../../../components';

import { recoveryContext } from '../RecoveryContainer';
import { recoveryCodeValidator } from '../../../utils/recoveryValidator';

import * as S from './styles';

interface Props {
  nextStep: () => void;
  prevStep: () => void;
  user_type: string;
}
const RecoveryCode: React.FC<Props> = ({ nextStep, prevStep, user_type }) => {
  const reRef: any = useRef<ReCAPTCHA>();
  const { recoveryData, setRecoveryData } = useContext(recoveryContext);

  const [load, setLoad] = useState<boolean>(false);

  const [errorData, setErrorData] = useState({
    code: '',
  });

  const next = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoad(true);
    if (await recoveryCodeValidator(recoveryData!, setErrorData)) {
      const token = await reRef.current?.executeAsync();
      reRef.current?.reset();
      api
        .post(`/code-validation/${user_type}`, {
          email: recoveryData?.email,
          code: recoveryData?.code,
          token,
        })
        .then(() => {
          nextStep();
        })
        .catch(() => {
          setErrorData({
            ...errorData,
            code: 'Código inválido!',
          });
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  const handleChange = (data: any, value: any) => {
    setErrorData({ ...errorData, [data]: '' });
    setRecoveryData!({ ...recoveryData!, [data]: value });
  };

  return (
    <S.Container>
      <S.Content>
        <S.TitleContainer>
          <S.PrimaryMessage>Recuperar senha</S.PrimaryMessage>
          <S.SecondaryMessage>
            Acabamos de enviar um código de <br />
            recuperação de senha para <br /> &#34;
            <strong>{recoveryData!.email}</strong>&#34;
          </S.SecondaryMessage>
        </S.TitleContainer>

        <S.FormContainer onSubmit={next}>
          <InputNumberField
            id="code"
            value={recoveryData!.code}
            onChange={(e) => handleChange('code', e.value)}
            placeholder="123456"
            labelText="Código"
            width="286px"
            format="######"
            mask="_"
            invalid={errorData.code}
          />
          <S.BtnContainer>
            <ActionButton
              value="Enviar"
              primary
              onClick={() => {}}
              loading={load}
            />
            <ActionButton value="Voltar" onClick={prevStep} />
          </S.BtnContainer>
        </S.FormContainer>
      </S.Content>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN!}
        size="invisible"
        ref={reRef}
      />
    </S.Container>
  );
};

export default RecoveryCode;
