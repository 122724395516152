/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import api from '../../../services/api';

import {
  ActionButton,
  Especialidade,
  InputNumberField,
  InputField,
  SelectOne,
  SelectMult,
  AddButton,
  Label,
  Avatar,
} from '../../../components';

import { eqMedicaValidator } from '../../../utils/formValidator';

import { avatarContext, userContext } from '../FormContainer';

import * as S from './styles';

import * as I from '../../../shared/interfaces/formInterfaces';

interface Props {
  prevStep: () => void;
  nextStep: () => void;
}

const FormEqMedica: React.FC<Props> = ({ prevStep, nextStep }) => {
  const { userData, setUserData } = useContext(userContext);
  const { avatar, setAvatar } = useContext(avatarContext);
  const [load, setLoad] = useState<boolean>(false);

  const {
    conselho,
    estadoConselho,
    numeroRegistro,
    nomeCompleto,
    rgMedico,
    cpfMedico,
    emailContato,
    telefoneContato,
    pessoaContato,
    especialidades,
    valor,
    temConvenio,
    convenios,
  } = userData!;

  const [errorData, setErrorData] = useState<I.ErrorEqMedica>({
    conselho: '',
    estadoConselho: '',
    numeroRegistro: '',
    nomeCompleto: '',
    cpfMedico: '',
    rgMedico: '',
    emailContato: '',
    telefoneContato: '',
    pessoaContato: '',
    especialidades: [{ especialidade: '', rqe: '', favorita: '' }],
    valor: '',
    temConvenio: '',
    convenios: '',
  });

  // Valores para conselho, convenios e especialidades
  const [conselhos, setConselhos] = useState<string[]>([]);
  const [especialidadesList, setEspecialidadesList] = useState<string[]>([]);
  const [conveniosList, setConveniosList] = useState<string[]>([]);

  const [estados, setEstados] = useState<string[]>([]);

  const next = async () => {
    setLoad(true);
    if (await eqMedicaValidator(userData!, setErrorData)) {
      nextStep();
    } else {
      setLoad(false);
    }
  };

  const toggleConvenio = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? setUserData!({ ...userData!, temConvenio: true })
      : setUserData!({ ...userData!, temConvenio: false, convenios: [] });
  };

  const addEsp = () => {
    setUserData!({
      ...userData!,
      especialidades: [
        ...especialidades,
        { especialidade: '', rqe: '', favorita: false },
      ],
    });
    setErrorData!({
      ...errorData!,
      especialidades: [
        ...errorData.especialidades,
        { especialidade: '', rqe: '', favorita: '' },
      ],
    });
  };

  useEffect(() => {
    api
      .get('/lista-especialidades')
      .then(({ data: listaEspecs }) =>
        setEspecialidadesList(
          listaEspecs
            .map((e: { especialidade: string }) => e.especialidade)
            .sort(),
        ),
      );
    api
      .get('/lista-conselhos')
      .then(({ data: listaConselhos }) =>
        setConselhos(
          listaConselhos.map((c: { conselho: string }) => c.conselho).sort(),
        ),
      );
    api
      .get('/lista-convenios')
      .then(({ data: listaConvenios }) =>
        setConveniosList(
          listaConvenios.map((c: { convenio: string }) => c.convenio).sort(),
        ),
      );
    axios
      .get<{ sigla: string }[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
      )
      .then((res) => {
        const ufs = res.data.map((uf) => uf.sigla).sort();
        setEstados(ufs);
      });
  }, []);

  const handleChange = (data: any, value: any, regex?: any) => {
    if (regex && !value.match(regex)) return;
    setErrorData({ ...errorData, [data]: '' });
    setUserData!({ ...userData!, [data]: value });
  };

  const setErrorEsp = (esp: any) => {
    setErrorData!({ ...errorData!, especialidades: esp });
  };

  const setEsp = (esp: any) => {
    setUserData!({ ...userData!, ...esp });
  };

  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>Equipe Médica</S.Title>
        <S.Description>
          Preencha o perfil de um médico ou especialista. <br />
          Fique tranquilo, você poderá adicionar toda sua equipe médica depois.
        </S.Description>
      </S.TitleContainer>

      <Avatar formData={avatar} setFormData={setAvatar} />

      <S.DetailContainer>
        <S.DetailRow>
          <InputField
            id="nomeCompleto"
            value={nomeCompleto}
            onChange={(e) => handleChange('nomeCompleto', e.target.value)}
            placeholder="John Smith Doe"
            labelText="Nome completo (como consta no conselho)"
            width="329px"
            invalid={errorData!.nomeCompleto}
          />
        </S.DetailRow>
        <S.DetailRow>
          <SelectOne
            id="conselho"
            value={conselho}
            onChangeValue={(opt) => handleChange('conselho', opt)}
            labelText="Conselho"
            width="150px"
            options={conselhos}
            invalid={errorData!.conselho}
          />

          <SelectOne
            id="estadoConselho"
            value={estadoConselho}
            onChangeValue={(opt) => handleChange('estadoConselho', opt)}
            labelText="Estado"
            width="150px"
            options={estados}
            invalid={errorData!.estadoConselho}
          />
        </S.DetailRow>

        <S.DetailRow>
          <InputNumberField
            id="numeroRegistro"
            value={numeroRegistro}
            onChange={({ value }) => handleChange('numeroRegistro', value)}
            placeholder="00000000"
            labelText="Número de registro"
            width="165px"
            invalid={errorData!.numeroRegistro}
          />
        </S.DetailRow>
        {especialidades.map(
          (especialidade: I.Especialidades, index: number) => (
            <S.DetailRow key={`esp-${index.toString()}`}>
              <Especialidade
                index={index}
                especialidade={especialidade}
                options={especialidadesList}
                data={userData!.especialidades}
                setData={setEsp}
                errorData={errorData.especialidades}
                setErrorData={setErrorEsp}
              />
            </S.DetailRow>
          ),
        )}

        {userData!.especialidades.length < 2 && (
          <AddButton
            onClick={addEsp}
            id="addEspecialidade"
            text="Especialidade"
          />
        )}
        <S.DetailRow>
          <InputNumberField
            id="cpfMedico"
            value={cpfMedico}
            onChange={({ value }) => handleChange('cpfMedico', value)}
            placeholder="000.000.000-00"
            labelText="CPF"
            width="145px"
            format="###.###.###-##"
            mask="_"
            invalid={errorData!.cpfMedico}
          />

          <InputNumberField
            id="rgMedico"
            value={rgMedico}
            onChange={({ value }) => handleChange('rgMedico', value)}
            placeholder="00.000.000-0"
            labelText="RG"
            width="135px"
            format="##.###.###-#"
            mask="_"
            invalid={errorData!.rgMedico}
          />
        </S.DetailRow>

        <S.DetailRow>
          <InputField
            id="emailContato"
            value={emailContato}
            onChange={(e) => handleChange('emailContato', e.target.value)}
            placeholder="email@dominio.com"
            labelText="E-mail de contato"
            width="160px"
            invalid={errorData!.emailContato}
          />

          <InputNumberField
            id="telefoneContato"
            value={telefoneContato}
            onChange={({ value }) => handleChange('telefoneContato', value)}
            placeholder="(00) 00000-0000"
            labelText="Celular de contato"
            width="120px"
            format="(##) #####-####"
            mask="_"
            invalid={errorData!.telefoneContato}
          />
        </S.DetailRow>

        <S.DetailRow>
          <InputField
            id="pessoaContato"
            value={pessoaContato}
            onChange={(e) => handleChange('pessoaContato', e.target.value)}
            placeholder="Jane Smith Doe"
            labelText="Pessoa de Contato"
            width="329px"
            invalid={errorData!.pessoaContato}
            optional
          />
        </S.DetailRow>
      </S.DetailContainer>

      <S.PartConvContainer>
        <S.Subtitle>Particular &amp; Convênios</S.Subtitle>
        <S.Description>Consulta particular</S.Description>

        <S.DetailRow>
          <InputNumberField
            id="valor"
            value={valor}
            onChange={({ value }) => handleChange('valor', value)}
            placeholder="R$ 0,00"
            labelText="Valor"
            width="109px"
            invalid={errorData!.valor}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
          />
        </S.DetailRow>

        <S.CheckboxContainer>
          <S.Checkbox
            checked={temConvenio}
            type="checkbox"
            name="convenio"
            id="convenio"
            onChange={toggleConvenio}
          />
          <Label id="convenio" labelText="Atendimento com convênios" />
        </S.CheckboxContainer>

        {temConvenio && (
          <S.DetailRow>
            <SelectMult
              id="convOptions"
              value={convenios}
              onChangeValue={(opts) => handleChange('convenios', opts)}
              labelText="Convênios atendidos"
              options={conveniosList}
              invalid={errorData!.convenios}
            />
          </S.DetailRow>
        )}
      </S.PartConvContainer>

      <S.ButtonContainer>
        <ActionButton onClick={next} value="Continuar" primary loading={load} />
        <ActionButton onClick={prevStep} value="Voltar" />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default FormEqMedica;
