import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import api from '../../services/api';

import {
  ConfigConselhos,
  ConfigConvenios,
  ConfigEspecialidades,
  ConfigTermos,
  SkeletonConselhos,
  SkeletonConvenios,
  SkeletonEspecialidades,
  SkeletonTermos,
} from '../../domain/ConfigAdm';

import {
  ModalAlterarTermos,
  ModalNovoConvenio,
  ModalNovaEspecialidade,
  ModalApagarConvenio,
  ModalApagarEspecialidade,
  ModalAceitarEspecialidade,
} from '../../domain/Modal';

import * as S from './styles';
import { ActionButton, TableWarning } from '../../components';

const ConfiguracoesAdm: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);

  const [listaConvenios, setListaConvenios] = useState([
    { id: 0, convenio: '' },
  ]);
  const [listaConselhos, setListaConselhos] = useState([
    { id: 0, conselho: '' },
  ]);
  const [listaEspecialidades, setListaEspecialidades] = useState([
    { id: 0, especialidade: '', is_pending: false },
  ]);
  const [pdf, setPdf] = useState({ file_url: '', criado_em: new Date() });

  const [modals, setModals] = useState<{ [key: string]: any }>({
    novoConvenio: {
      id: 0,
      state: false,
    },
    apagarConvenio: {
      id: 1,
      state: false,
    },
    novaEspecialidade: {
      id: 2,
      state: false,
    },
    alterarTermos: {
      id: 2,
      state: false,
    },
    apagarEspecialidade: {
      id: 3,
      state: false,
    },
    aceitarEspecialidade: {
      id: 3,
      state: false,
    },
  });

  const toggleModal = (type: string, id?: number) => {
    return id
      ? setModals({
          ...modals,
          [type]: { id, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  const fetchData = async () => {
    setLoading(true);

    await api
      .get('/lista-convenios')
      .then(async ({ data: dados }) => {
        setListaConvenios(dados);
      })
      .catch(() => {
        setConnectionError(true);
      });
    await api
      .get('/lista-conselhos')
      .then(async ({ data: dados }) => {
        setListaConselhos(dados);
      })
      .catch(() => {
        setConnectionError(true);
      });
    await api
      .get('/lista-especialidades/adm')
      .then(async ({ data: dados }) => {
        setListaEspecialidades(dados);
      })
      .catch(() => {
        setConnectionError(true);
      });

    await api
      .get('/pdf')
      .then(async ({ data: dados }) => {
        setPdf(dados);
      })
      .catch(() => {
        setConnectionError(true);
      });

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logoff = useCallback(() => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('jwt');
    history.push('/');
  }, [history]);

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Headline>
            <S.Icon />
            <S.Title>Configurações</S.Title>
          </S.Headline>
          <S.Description>
            Altere os termos e condições, bem como as opções de convênios,
            conselhos, especialidades. Atenção, quaisquer alterações nas listas
            abaixo não terão efeito nos especialistas já cadastrados.
          </S.Description>
        </S.TitleContainer>
        <S.ButtonContainer>
          <ActionButton onClick={logoff} value="Desconectar" />
        </S.ButtonContainer>
      </S.Header>
      {connectionError && <TableWarning noConnection />}
      <S.Content>
        {loading ? (
          <>
            <SkeletonConvenios />
            <SkeletonEspecialidades />
            <SkeletonConselhos />
            <SkeletonTermos />
          </>
        ) : (
          !connectionError && (
            <>
              <ConfigConvenios
                convenios={listaConvenios}
                toggleModal={toggleModal}
              />
              <ConfigEspecialidades
                especialidades={listaEspecialidades}
                toggleModal={toggleModal}
              />
              <ConfigConselhos
                conselhos={listaConselhos}
                toggleModal={toggleModal}
              />
              <ConfigTermos pdf={pdf} toggleModal={toggleModal} />
              {/* <ConfigCadastro
                nomeClinica={data.nomeClinica}
                isClinica={data.isClinica}
                cnpj={data.cnpj}
                cpf={data.cpf}
                emailPrincipal={data.emailPrincipal}
                emailSecundario={data.emailSecundario}
                fetchData={fetchData}
              />
              <ConfigEqMedica
                medicos={data.eqMedica}
                toggleModal={toggleModal}
                fetchData={fetchData}
              /> */}
            </>
          )
        )}
      </S.Content>
      <AnimatePresence>
        {modals.novoConvenio.state && (
          <ModalNovoConvenio
            id={modals.novoConvenio.id}
            closeModal={() => toggleModal('novoConvenio')}
            fetchData={fetchData}
          />
        )}
        {modals.apagarConvenio.state && (
          <ModalApagarConvenio
            id={modals.apagarConvenio.id}
            closeModal={() => toggleModal('apagarConvenio')}
            fetchData={fetchData}
          />
        )}
        {modals.novaEspecialidade.state && (
          <ModalNovaEspecialidade
            id={modals.novaEspecialidade.id}
            closeModal={() => toggleModal('novaEspecialidade')}
            fetchData={fetchData}
          />
        )}
        {modals.apagarEspecialidade.state && (
          <ModalApagarEspecialidade
            id={modals.apagarEspecialidade.id}
            closeModal={() => toggleModal('apagarEspecialidade')}
            fetchData={fetchData}
          />
        )}
        {modals.aceitarEspecialidade.state && (
          <ModalAceitarEspecialidade
            id={modals.aceitarEspecialidade.id}
            closeModal={() => toggleModal('aceitarEspecialidade')}
            fetchData={fetchData}
          />
        )}
        {modals.alterarTermos.state && (
          <ModalAlterarTermos
            id={modals.alterarTermos.id}
            closeModal={() => toggleModal('alterarTermos')}
            fetchData={fetchData}
          />
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default ConfiguracoesAdm;
