import React from 'react';
import ReactDom from 'react-dom';

import { SelectOne } from '../../../components';

import * as S from './styles';

import * as I from './interface';

const ModalEspecialidade: React.FC<I.Props> = ({
  top,
  left,
  closeModal,
  options,
  filterOptions,
  setFilterOptions,
}) => {
  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer top={top} left={left}>
        <S.Label>Selecione uma especialidade</S.Label>
        <SelectOne
          id="especialidade"
          value={filterOptions.especialidade}
          onChangeValue={(opt) => {
            setFilterOptions((state: any) => ({
              ...state,
              especialidade: opt,
            }));
            closeModal();
          }}
          width="210px"
          height="36px"
          options={options}
        />
        <S.Clear>
          <S.Link
            onClick={() => {
              setFilterOptions((state: any) => ({
                ...state,
                especialidade: '',
              }));
              closeModal();
            }}>
            Limpar
          </S.Link>
        </S.Clear>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalEspecialidade;
