import React from 'react';
import { Link } from 'react-router-dom';

import { ActionButton, SpecialistButton } from '../../../components';

import * as S from './styles';

interface Medico {
  id: string;
  nomeMedico: string;
  isPending?: boolean;
  isActive?: boolean;
}

interface Props {
  medicos: Medico[];
  toggleModal: (type: string, id?: number | undefined) => void;
  fetchData: () => void;
}

const ConfigEqMedica: React.FC<Props> = ({ medicos, toggleModal }) => {
  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Equipe médica</S.SubTitle>
        <ActionButton
          onClick={() => toggleModal('novoMedico')}
          value="Novo Médico"
        />
      </S.SubTitleContainer>
      <S.DetailsContainer>
        {medicos.map((medico) => (
          <Link key={medico.id} to={`/gerente/detalhes/${medico.id}`}>
            <SpecialistButton
              key={medico.id}
              onClick={() => {}}
              value={medico.nomeMedico}
              isPending={medico.isPending}
              isActive={medico.isActive}
            />
          </Link>
        ))}
      </S.DetailsContainer>
    </S.Container>
  );
};

export default ConfigEqMedica;
