import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const StatusButton: React.FC<I.Props> = ({ value, active, ...res }) => {
  return (
    <S.Button status={value.toLowerCase()} active={active} {...res}>
      <S.Dot status={value.toLowerCase()} active={active} />
      {value}
    </S.Button>
  );
};

export default StatusButton;
