import React from 'react';

import * as S from './styles';

import * as I from './interface';

const ConsultasTabela: React.FC<I.Props> = ({
  consultas,
  toggleModal,
  current,
  maxItensPerPage,
  enablePagination = false,
}) => {
  const getInitials = (name: string) => {
    name = name.replace('Dr(a).', '').trim();
    const initials = name
      .split(' ')
      .map((n) => n[0])
      .filter((w) => w !== undefined);
    if (!initials[0]) return '';
    if (!initials[1]) return initials[0].toUpperCase();
    return (
      initials[0].toUpperCase() + initials[initials.length - 1].toUpperCase()
    );
  };
  return (
    <S.Container>
      <S.Table>
        <S.THead>
          <S.TRHead>
            <S.TH>Consulta</S.TH>
            <S.TH>Paciente</S.TH>
            <S.TH>Especialista</S.TH>
            <S.TH>Telefone</S.TH>
            <S.TH>Status</S.TH>
            <S.TH>Atualizado em</S.TH>
            <S.TH>Tempo de espera</S.TH>
          </S.TRHead>
        </S.THead>
        <S.TBody>
          {consultas
            .slice(
              enablePagination ? (current - 1) * maxItensPerPage : 1,
              enablePagination ? current * maxItensPerPage : undefined,
            )
            .map(
              ({
                id,
                data,
                paciente,
                nomeUsuario,
                especialista,
                especialidade,
                telefone,
                status,
                atualizado,
                avatarPaciente,
                avatarMedico,
                diasEspera,
              }) => (
                <S.TRBody key={id} onClick={() => toggleModal('consulta', +id)}>
                  <S.TD>
                    <S.Bold>{data.split(',')[0]}</S.Bold>
                    <br />
                    {data.split(',')[1]}
                  </S.TD>

                  {/* Início Avatar Paciente */}
                  <S.TD>
                    <S.EspContainer>
                      {avatarPaciente.trim() !== '' &&
                      nomeUsuario === paciente ? (
                        <S.EspAvatar src={avatarPaciente} />
                      ) : (
                        <S.InitialsContainer>
                          <S.Initials>{getInitials(paciente)}</S.Initials>
                        </S.InitialsContainer>
                      )}
                      <S.EspDetails>
                        <S.EspNome>{paciente}</S.EspNome>
                      </S.EspDetails>
                    </S.EspContainer>
                  </S.TD>
                  {/* Fim Avatar paciente */}

                  <S.TD>
                    <S.EspContainer>
                      {avatarMedico.trim() !== '' ? (
                        <S.EspAvatar src={avatarMedico} />
                      ) : (
                        <S.InitialsContainer>
                          <S.Initials>{getInitials(especialista)}</S.Initials>
                        </S.InitialsContainer>
                      )}
                      <S.EspDetails>
                        <S.EspNome>{especialista}</S.EspNome>
                        <S.EspFuncao>{especialidade}</S.EspFuncao>
                      </S.EspDetails>
                    </S.EspContainer>
                  </S.TD>
                  <S.TD>{telefone}</S.TD>
                  <S.TD>
                    <S.StatusContainer>
                      <S.Dot status={status} />
                      {status}
                    </S.StatusContainer>
                  </S.TD>
                  <S.TD>
                    <S.Update>{atualizado}</S.Update>
                  </S.TD>
                  <S.TD>
                    <S.StatusContainer>
                      {status === 'Solicitada' ? <S.Clock /> : null}
                      <S.Update> {diasEspera}</S.Update>
                    </S.StatusContainer>
                  </S.TD>
                </S.TRBody>
              ),
            )}
        </S.TBody>
      </S.Table>
    </S.Container>
  );
};

export default ConsultasTabela;
