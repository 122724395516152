import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import WhiteLogo from '../../../assets/images/logo-white.svg';
import api from '../../../services/api';

import * as S from './styles';

const SidebarMenu: React.FC = () => {
  const [selected, setSelected] = useState('home');

  const getTerms = () => {
    api
      .get('/pdf')
      .then(({ data: { file_url } }) => window.open(file_url, '_blank'));
  };

  useEffect(() => {
    setSelected(window.location.pathname.split('/')[2]);
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.Logo src={WhiteLogo} />
      </S.Header>
      <S.Content>
        <S.MenuItems>
          <Link to="/gerente/home" onClick={() => setSelected('home')}>
            <S.MenuItem selected={selected === 'home'}>
              <S.IconInicio />
              <S.Label>Início</S.Label>
            </S.MenuItem>
          </Link>
          <Link
            to="/gerente/consultas"
            onClick={() => setSelected('consultas')}>
            <S.MenuItem selected={selected === 'consultas'}>
              <S.IconConsultas />
              <S.Label>Consultas</S.Label>
            </S.MenuItem>
          </Link>
          <Link
            to="/gerente/relatorio"
            onClick={() => setSelected('relatorio')}>
            <S.MenuItem selected={selected === 'relatorio'}>
              <S.IconRelatorio />
              <S.Label>Relatório</S.Label>
            </S.MenuItem>
          </Link>
          <Link to="/gerente/config" onClick={() => setSelected('config')}>
            <S.MenuItem selected={selected === 'config'}>
              <S.IconConfig />
              <S.Label>Configurações</S.Label>
            </S.MenuItem>
          </Link>
        </S.MenuItems>
        <S.TermsPolicyContainer onClick={getTerms}>
          Termos &amp; Política{' '}
        </S.TermsPolicyContainer>
      </S.Content>
    </S.Container>
  );
};

export default SidebarMenu;
