import React, { useState } from 'react';
import ReactDom from 'react-dom';
import api from '../../../services/api';

import { LoadingSpinner } from '../../../components';

import * as S from './styles';

interface Props {
  id?: string;
  closeModal: () => void;
  closeAll: () => void;
}

const ModalRemoverEndereco: React.FC<Props> = ({
  id,
  closeModal,
  closeAll,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const confirm = async () => {
    setLoading(true);
    api
      .delete(`/address/${id}`)
      .then(() => {
        setLoading(false);
        closeModal();
        closeAll();
      })
      .catch(({ response }) => {
        setError(response.data.msg);
        setLoading(false);
      });
  };

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer>
        <S.Content>
          <S.Header>
            <S.Title>Remover endereco</S.Title>
            {error.trim() === '' ? (
              <>
                <S.Text>
                  {!loading
                    ? 'Tem certeza que gostaria de remover esse endereço?'
                    : 'Processando...'}
                </S.Text>
                {!loading && (
                  <S.WarningText>
                    ATENÇÃO: Apagar esse endereço irá remover todas as consultas
                    do seu histórico vinculadas à ele!
                  </S.WarningText>
                )}
              </>
            ) : (
              <S.Text>{error}</S.Text>
            )}
          </S.Header>
        </S.Content>
        <S.LinkContainer>
          {!loading ? (
            <>
              <S.Link onClick={closeModal}>Voltar</S.Link>
              {error.trim() === '' && (
                <S.Link onClick={confirm} primary>
                  Confirmar
                </S.Link>
              )}
            </>
          ) : (
            <LoadingSpinner color="#fc6471" />
          )}
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalRemoverEndereco;
