import React, { useState } from 'react';
import ReactDom from 'react-dom';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { InputPasswordField } from '../../../components';

import { modalSenhaValidator } from '../../../utils/modalValidator';

import * as S from './styles';

interface Props {
  id: string;
  closeModal: () => void;
  fetch: () => void;
}

const ModalRecusarCadastro: React.FC<Props> = ({ id, closeModal }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    idMedico: id,
    senha: '',
  });

  const [errorData, setErrorData] = useState({
    senha: '',
  });

  const handleChange = (key: any, value: any) => {
    setData({ ...data, [key]: value });
    setErrorData({ ...errorData, [key]: '' });
  };

  const confirm = async () => {
    setLoading(true);
    if (await modalSenhaValidator(data!, setErrorData)) {
      await api
        .put('/medicos/deny', {
          id_medico: data.idMedico,
          senha: data.senha,
        })
        .then(() => {
          closeModal();
          history.push('/admin/especialistas');
        })
        .catch(() => {
          setErrorData({ senha: 'Senha incorreta!' });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer>
        <S.Header>
          <S.Title>Recusar cadastro</S.Title>
        </S.Header>
        <S.Details>
          <S.Form>
            <S.Input hidden autoComplete="username" />
            <InputPasswordField
              id="senha"
              value={data.senha}
              onChange={(e) => handleChange('senha', e.target.value)}
              placeholder="••••••••"
              labelText="Para recusar o médico atual e excluir seu cadastro, digite sua senha abaixo:"
              width="196px"
              invalid={errorData.senha}
              doNotSave
            />
          </S.Form>
        </S.Details>
        <S.LinkContainer>
          <S.Link onClick={closeModal}>Cancelar</S.Link>
          {loading ? (
            <S.Loading>Validando...</S.Loading>
          ) : (
            <S.Link onClick={confirm} primary>
              Recusar e Excluir
            </S.Link>
          )}
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalRecusarCadastro;
