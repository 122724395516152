import { Dispatch, SetStateAction } from 'react';

import { validator } from './validators';

import {
  vazio,
  dataValida,
  horarioValido,
  validaCep,
  tamanhoMinimo,
  optTamanhoMinimo,
} from './conditions';

import * as I from '../shared/interfaces/modalInterfaces';

export const modalRemarcarValidator = async (
  { novaData, novoHorario, novoEndereco }: I.Remarcar,
  setErrorData: Dispatch<SetStateAction<any>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'novaData',
      novaData,
      [dataValida, vazio],
      ['Data inválida!', 'A nova data não pode ser vazia!'],
      setErrorData,
    ),
    validator(
      'novoHorario',
      novoHorario,
      [horarioValido, vazio],
      ['Novo horário inválido', 'O novo horário não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'novoEndereco',
      novoEndereco,
      [vazio],
      ['O novo endereço não pode ser vazio!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const modalSenhaValidator = async (
  { senha }: I.ModalCadastro,
  setErrorData: Dispatch<SetStateAction<any>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator('senha', senha, [vazio], ['Digite sua senha!'], setErrorData),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const modalNovoEnderecoValidator = async (
  { rua, numero, estado, cidade, bairro, cep, tel, cel }: I.NovoEndereco,
  setErrorData: Dispatch<SetStateAction<any>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'rua',
      rua,
      [vazio],
      ["O campo 'rua' não pode ser vazio!"],
      setErrorData,
    ),
    validator(
      'numero',
      numero,
      [vazio],
      ['O campo numero não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'cidade',
      cidade,
      [vazio],
      ['Escolha uma das cidade!'],
      setErrorData,
    ),
    validator(
      'estado',
      estado,
      [vazio],
      ['Escolha um dos estados!'],
      setErrorData,
    ),
    validator(
      'bairro',
      bairro,
      [vazio],
      ['O bairro não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'cep',
      cep,
      [validaCep, vazio],
      ['O CEP digito não é válido!', "O campo 'cep' não pode ser vazio!"],
      setErrorData,
      true,
    ),
    validator(
      'tel',
      tel,
      [tamanhoMinimo(10), vazio],
      ['Preencha o telefone corretamente!', 'O telefone não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'cel',
      cel,
      [optTamanhoMinimo(10)],
      ['Preencha o celular corretamente!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};
