import React, { useState, useEffect } from 'react';
import api from '../../../services/api';

import {
  Formacao,
  AddButton,
  Experiencia,
  ActionButton,
  UpdateError,
} from '../../../components';

import { expFormValidator } from '../../../utils/formValidator';

import * as S from './styles';

import * as I from './interface';

const DetalhesExperienciaFormacao: React.FC<I.Props> = ({
  id,
  experiencias,
  formacoes,
  readOnly,
  fetch,
}) => {
  const [load, setLoad] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState({
    error: false,
    message: '',
  });

  const [data, setData] = useState<I.Data>({
    id,
    experiencias: experiencias.map((e) => ({ ...e })),
    formacoes: formacoes.map((f) => ({ ...f })),
  });

  const [errorData, setErrorData] = useState<I.Data>({
    id,
    experiencias: [{ titulo: '', descricao: '', ano: '' }],
    formacoes: [{ instituicao: '', curso: '', ano: '' }],
  });

  const setOriginalData = () => {
    setData({
      id,
      experiencias: experiencias.map((e) => ({ ...e })),
      formacoes: formacoes.map((f) => ({ ...f })),
    });
    setErrorData({
      id,
      experiencias: [{ titulo: '', descricao: '', ano: '' }],
      formacoes: [{ instituicao: '', curso: '', ano: '' }],
    });
    setUpdateError({ error: false, message: '' });
  };

  useEffect(() => {}, []);

  const save = async () => {
    setLoad(true);
    if (await expFormValidator(data!, setErrorData)) {
      api
        .put(`/medicos/exp-form/${id}`, {
          experiencias: data.experiencias.map((e) => ({
            titulo: e.titulo,
            descricao: e.descricao,
            e_ano: e.ano,
          })),
          formacoes: data.formacoes.map((f) => ({
            instituicao: f.instituicao,
            curso: f.curso,
            f_ano: f.ano,
          })),
        })
        .then(() => {
          setEditing(false);
          setLoad(false);
          fetch();
        })
        .catch(() => {
          setUpdateError({
            error: true,
            message:
              'Erro na atualização dos dados. Preencha os campos corretamente e tente novamente!',
          });
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  const addExp = () => {
    setData({
      ...data,
      experiencias: [
        ...data.experiencias,
        { titulo: '', descricao: '', ano: '' },
      ],
    });
    setErrorData!({
      ...errorData!,
      experiencias: [
        ...errorData!.experiencias,
        { titulo: '', descricao: '', ano: '' },
      ],
    });
  };

  const addForm = () => {
    setData({
      ...data,
      formacoes: [...data.formacoes, { instituicao: '', curso: '', ano: '' }],
    });
    setErrorData!({
      ...errorData!,
      formacoes: [
        ...errorData!.formacoes,
        { instituicao: '', curso: '', ano: '' },
      ],
    });
  };

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Title>Experiência &amp; Formação</S.Title>
          <S.Description>
            Abaixo estão as experiências e formações do médico selecionado.
          </S.Description>
        </S.TitleContainer>
        {!readOnly && (
          <S.ButtonContainer>
            {!editing ? (
              <ActionButton
                onClick={() => setEditing(true)}
                value="Editar"
                primary
              />
            ) : (
              <>
                {updateError.error && (
                  <UpdateError message={updateError.message} />
                )}
                <ActionButton
                  onClick={() => {
                    setOriginalData();
                    setEditing(false);
                  }}
                  value="Cancelar"
                />
                <ActionButton
                  onClick={save}
                  value="Salvar"
                  primary
                  loading={load}
                />
              </>
            )}
          </S.ButtonContainer>
        )}
      </S.Header>

      <S.ContentContainer>
        <S.Subtitle>Experiência</S.Subtitle>

        {data.experiencias.map((experiencia: I.Experiencias, index: number) => (
          <S.ContentRow key={`exp-${index.toString()}`}>
            <Experiencia
              index={index}
              experiencia={experiencia}
              data={data}
              setData={setData}
              errorData={errorData}
              setErrorData={setErrorData}
              isDisabled={!editing}
            />
          </S.ContentRow>
        ))}
        {editing && (
          <AddButton id="experiencia" text="Experiência" onClick={addExp} />
        )}
      </S.ContentContainer>

      <S.ContentContainer>
        <S.Subtitle>Formação</S.Subtitle>

        {data.formacoes.map((formacao: I.Formacoes, index: number) => (
          <S.ContentRow key={`form-${index.toString()}`}>
            <Formacao
              index={index}
              formacao={formacao}
              data={data}
              setData={setData}
              errorData={errorData}
              setErrorData={setErrorData}
              isDisabled={!editing}
            />
          </S.ContentRow>
        ))}

        {editing && (
          <AddButton id="formacao" text="Formação" onClick={addForm} />
        )}
      </S.ContentContainer>
    </S.Container>
  );
};

export default DetalhesExperienciaFormacao;
