import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticatedAs, logout } from '../../services/auth';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PrivateRoute = ({
  component: Component,
  permmitedRole,
  ...rest
}: any): any => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticatedAs(permmitedRole)) {
          return <Component {...props} />;
        }

        logout();
        return (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
};
