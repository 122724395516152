import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';
import api from '../../../services/api';

import { InputPasswordField, ActionButton } from '../../../components';

import { recoveryPasswordValidator } from '../../../utils/recoveryValidator';

import { recoveryContext } from '../RecoveryContainer';

import * as S from './styles';

interface Props {
  user_type: string;
}

const RecoveryPassword: React.FC<Props> = ({ user_type }) => {
  const reRef: any = useRef<ReCAPTCHA>();
  const { recoveryData, setRecoveryData } = useContext(recoveryContext);
  const history = useHistory();

  const { senha, confirmarSenha } = recoveryData!;

  const [load, setLoad] = useState<boolean>(false);

  const [errorData, setErrorData] = useState({
    senha: '',
    confirmarSenha: '',
  });

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const next = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoad(true);
    if (await recoveryPasswordValidator(recoveryData!, setErrorData)) {
      const token = await reRef.current?.executeAsync();
      reRef.current?.reset();
      api
        .post(`/reset-password/${user_type}`, {
          email: recoveryData?.email,
          code: recoveryData?.code,
          senha: recoveryData?.senha,
          token,
        })
        .then(() => {
          setShowSuccess(true);
        })
        .catch(() => {
          setErrorData({
            ...errorData,
            senha: 'Houve um erro na troca de senha! Tente novamente',
          });
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  const handleChange = (data: any, value: any) => {
    setErrorData({ ...errorData, [data]: '' });
    setRecoveryData!({ ...recoveryData!, [data]: value });
  };

  return (
    <S.Container>
      <S.Content>
        <S.TitleContainer>
          <S.PrimaryMessage>Crie uma nova senha</S.PrimaryMessage>
        </S.TitleContainer>

        <S.FormContainer onSubmit={next}>
          <InputPasswordField
            id="senha"
            value={senha}
            onChange={(e) => handleChange('senha', e.target.value)}
            placeholder="••••••••"
            labelText="Senha"
            width="286px"
            invalid={errorData!.senha}
            doNotSave
          />

          <InputPasswordField
            id="confirmarSenha"
            value={confirmarSenha}
            onChange={(e) => handleChange('confirmarSenha', e.target.value)}
            placeholder="••••••••"
            labelText="Confirmar senha"
            width="286px"
            invalid={errorData!.confirmarSenha}
            doNotSave
          />
          {showSuccess ? (
            <>
              <S.SuccessContainer>
                <S.SuccessMessage>Recuperação concluída!</S.SuccessMessage>
                <S.SuccessDescription>
                  Entre com sua nova senha.
                </S.SuccessDescription>
              </S.SuccessContainer>
              <S.BtnLogin onClick={() => history.push('/auth/login')}>
                Entrar
                <S.ArrowContainer>
                  <S.Arrow />
                </S.ArrowContainer>
              </S.BtnLogin>
            </>
          ) : (
            <S.BtnContainer>
              <ActionButton
                value="Enviar"
                primary
                onClick={() => {}}
                loading={load}
              />
              <ActionButton
                value="Cancelar"
                onClick={() => {
                  history.push('/');
                }}
              />
            </S.BtnContainer>
          )}
        </S.FormContainer>
      </S.Content>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN!}
        size="invisible"
        ref={reRef}
      />
    </S.Container>
  );
};

export default RecoveryPassword;
