import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const DelButton: React.FC<I.Props> = ({ onClick }) => {
  return (
    <S.Container onClick={onClick}>
      <S.TrashIcon />
    </S.Container>
  );
};

export default DelButton;
