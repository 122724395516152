import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import useUpdateEffect from '../../../hooks/useUpdateEffect';

import {
  InputNumberField,
  InputField,
  SelectOne,
  ActionButton,
} from '../../../components';

import { enderecoValidator } from '../../../utils/formValidator';

import { userContext } from '../FormContainer';

import * as S from './styles';

import * as I from '../../../shared/interfaces/formInterfaces';

interface Props {
  prevStep: () => void;
  nextStep: () => void;
}

const FormEndereco: React.FC<Props> = ({ prevStep, nextStep }) => {
  const { userData, setUserData } = useContext(userContext);
  const [estados, setEstados] = useState<string[]>([]);
  const [cidades, setCidades] = useState<string[]>([]);
  const [isCnpj, setIsCnpj] = useState<boolean>(true);
  const [load, setLoad] = useState<boolean>(false);

  const {
    isClinica,
    nomeClinica,
    cnpj,
    emailPrincipal,
    emailSecundario,
    estado,
    rua,
    numero,
    cidade,
    bairro,
    cep,
    tel,
    cel,
    email,
  } = userData!;

  const [errorData, setErrorData] = useState<I.ErrorEndereco>({
    nomeClinica: '',
    cnpj: '',
    cpf: '',
    emailPrincipal: '',
    emailSecundario: '',
    rua: '',
    numero: '',
    estado: '',
    cidade: '',
    bairro: '',
    cep: '',
    tel: '',
    cel: '',
  });

  const next = async () => {
    setLoad(true);
    if (await enderecoValidator(userData!, setErrorData)) {
      nextStep();
    } else {
      setLoad(false);
    }
  };

  const handleAccountTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value === 'clinica')
      setUserData!({ ...userData!, isClinica: true });
    else
      setUserData!({
        ...userData!,
        isClinica: false,
      });
  };

  const handleChangeCpfCnpj = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'cnpj') setIsCnpj(true);
    else setIsCnpj(false);
    setUserData!({
      ...userData!,
      cnpj: '',
    });
  };

  /** A função 'handleChange' altera o estado do objeto de erro e de dados do usuário.
   * @param data: Se refere a qual dado dentro do objeto irá sofrer a alteração.
   * @param value: Se refere ao novo valor que esse dado irá receber.  */
  const handleChange = (data: any, value: any) => {
    setErrorData({ ...errorData, [data]: '' });
    setUserData!({ ...userData!, [data]: value });
  };

  useEffect(() => {
    if (email !== undefined && emailPrincipal === '') {
      setUserData!({ ...userData!, emailPrincipal: email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    axios
      .get<{ sigla: string }[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
      )
      .then((res) => {
        const ufs = res.data.map((uf) => uf.sigla).sort();
        setEstados(ufs);
      })
      .catch(() => {});

    if (cnpj) {
      if (cnpj.length > 11) setIsCnpj(true);
      else setIsCnpj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    if (estado === '') return;
    setUserData!((state) => ({ ...state, cidade: '' }));
    axios
      .get<{ nome: string }[]>(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`,
      )
      .then((res) => {
        const cityList = res.data
          .map((city) => city.nome)
          .sort((a, b) => a.localeCompare(b)); // Ignorando acentos
        setCidades(cityList);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>Endereço</S.Title>
        <S.Description>
          Preencha os campos abaixo com os dados da clínica.
        </S.Description>
      </S.TitleContainer>

      <S.AdressContainer>
        <S.TypeContainer>
          <S.Description>Selecione o tipo da conta:</S.Description>
          <S.TypeOption>
            <S.Type
              type="radio"
              name="type"
              value="clinica"
              defaultChecked={isClinica}
              onChange={handleAccountTypeChange}
            />{' '}
            Clínica
          </S.TypeOption>
          <S.TypeOption>
            <S.Type
              type="radio"
              name="type"
              value="medicoIndependente"
              defaultChecked={!isClinica}
              onChange={handleAccountTypeChange}
            />
            Consultório
          </S.TypeOption>
        </S.TypeContainer>

        <S.ContentContainer>
          <InputField
            id="nomeClinica"
            value={nomeClinica}
            onChange={(e) => handleChange('nomeClinica', e.target.value)}
            placeholder={`Digite o nome ${
              isClinica ? 'da clínica' : 'do consultório'
            }`}
            labelText={isClinica ? 'Nome da clínica' : 'Nome do consultório'}
            width="180px"
            invalid={errorData!.nomeClinica}
          />

          <S.CpfCnpjContainer>
            <S.CpfCnpjOption>
              <S.Type
                type="radio"
                name="codeType"
                value="cnpj"
                checked={isCnpj}
                onChange={handleChangeCpfCnpj}
              />{' '}
              CNPJ
            </S.CpfCnpjOption>
            <S.CpfCnpjOption>
              <S.Type
                type="radio"
                name="codeType"
                value="cpf"
                checked={!isCnpj}
                onChange={handleChangeCpfCnpj}
              />
              CPF
            </S.CpfCnpjOption>
          </S.CpfCnpjContainer>
          <InputNumberField
            id="cnpj"
            value={cnpj}
            onChange={({ value }) => handleChange('cnpj', value)}
            placeholder={isCnpj ? '00.000.000/0000-00' : '000.000.000-00'}
            labelText={isCnpj ? 'CNPJ' : 'CPF'}
            width="150px"
            format={isCnpj ? '##.###.###/####-##' : '###.###.###-##'}
            mask="_"
            invalid={isCnpj ? errorData!.cnpj : errorData!.cpf}
          />
        </S.ContentContainer>

        <S.ContentContainer>
          <InputField
            id="emailPrincipal"
            value={emailPrincipal}
            onChange={(e) => handleChange('emailPrincipal', e.target.value)}
            placeholder="email@dominio.com"
            labelText="Email principal"
            width="200px"
            invalid={errorData!.emailPrincipal}
            isDisabled
          />

          <InputField
            id="emailSecundario"
            value={emailSecundario}
            onChange={(e) => handleChange('emailSecundario', e.target.value)}
            placeholder="email@dominio.com"
            labelText="Email secundário"
            width="200px"
            invalid={errorData!.emailSecundario}
            optional
          />
        </S.ContentContainer>

        <S.ContentContainer>
          <InputField
            id="rua"
            value={rua}
            onChange={(e) => handleChange('rua', e.target.value)}
            placeholder="Ex.: Rua, Av., Vila..."
            labelText="Logradouro"
            width="205px"
            invalid={errorData!.rua}
          />

          <InputNumberField
            id="numero"
            value={numero}
            onChange={({ value }) => handleChange('numero', value)}
            placeholder="Número..."
            labelText="Número"
            width="87px"
            format="#####"
            mask=""
            invalid={errorData!.numero}
          />

          <InputField
            id="bairro"
            value={bairro}
            onChange={(e) => handleChange('bairro', e.target.value)}
            placeholder="Nome do bairro..."
            labelText="Bairro"
            width="136px"
            invalid={errorData!.bairro}
          />
        </S.ContentContainer>

        <S.ContentContainer>
          <SelectOne
            id="estado"
            value={estado}
            onChangeValue={(opt) => handleChange('estado', opt)}
            labelText="Estado"
            width="150px"
            options={estados}
            invalid={errorData!.estado}
          />

          <SelectOne
            id="cidade"
            value={cidade}
            onChangeValue={(opt) => handleChange('cidade', opt)}
            labelText="Cidade"
            options={cidades}
            width="190px"
            invalid={errorData!.cidade}
          />

          <InputNumberField
            id="cep"
            value={cep}
            onChange={({ value }) => handleChange('cep', value)}
            placeholder="00000-000"
            labelText="CEP"
            format="#####-###"
            mask="_"
            invalid={errorData!.cep}
          />
        </S.ContentContainer>

        <S.ContentContainer>
          <InputNumberField
            id="tel"
            value={tel}
            onChange={({ value }) => handleChange('tel', value)}
            placeholder="(00) 0000-0000"
            labelText="Telefone"
            width="125px"
            format={tel.length <= 10 ? '(##) ####-#####' : '(##) #####-####'}
            invalid={errorData!.tel}
          />

          <InputNumberField
            id="cel"
            value={cel}
            onChange={({ value }) => handleChange('cel', value)}
            placeholder="(00) 00000-0000"
            labelText="Celular"
            width="130px"
            format={cel.length <= 10 ? '(##) ####-#####' : '(##) #####-####'}
            invalid={errorData!.cel}
            optional
          />
        </S.ContentContainer>
      </S.AdressContainer>

      <S.ButtonContainer>
        <ActionButton onClick={next} value="Continuar" primary loading={load} />
        <ActionButton onClick={prevStep} value="Voltar" />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default FormEndereco;
