import React, { useState } from 'react';

import { RecoveryEmail, RecoveryCode, RecoveryPassword } from '../index';

import * as S from './styles';

import * as I from '../../../shared/interfaces/recoveryInterfaces';

export const recoveryContext = React.createContext<Partial<I.Recovery>>({});

const RecoveryContainer: React.FC<I.Props> = ({ user_type }) => {
  const [step, setStep] = useState(1);

  const [recoveryData, setRecoveryData] = useState<I.RecoveryData>({
    email: '',
    code: '',
    senha: '',
    confirmarSenha: '',
  });

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderStep = (opt: number) => {
    switch (opt) {
      case 1:
        return <RecoveryEmail user_type={user_type} nextStep={nextStep} />;
      case 2:
        return (
          <RecoveryCode
            user_type={user_type}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 3:
        return <RecoveryPassword user_type={user_type} />;
      default:
        return <h1>Ocorreu um erro! :(</h1>;
    }
  };

  return (
    <recoveryContext.Provider value={{ recoveryData, setRecoveryData }}>
      <S.Container>{renderStep(step)}</S.Container>
    </recoveryContext.Provider>
  );
};

export default RecoveryContainer;
