import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import api from '../../../services/api';

import { LoadingSpinner } from '../../../components';

import * as S from './styles';

interface Props {
  id?: string;
  closeModal: () => void;
  closeAll: () => void;
}

const ModalConfirmarConsulta: React.FC<Props> = ({
  id,
  closeModal,
  closeAll,
}) => {
  const [loading, setLoading] = useState(false);

  const close = () => {
    closeModal();
    closeAll();
  };

  const confirm = async () => {
    setLoading(true);
    api.put(`/consult/confirmar/${id}`).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    confirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && close()}>
      <S.ModalContainer>
        <S.Header>
          <S.Title>Consulta agendada</S.Title>
          <S.Text>
            {loading
              ? 'Confimando consulta...'
              : 'Acabamos de enviar uma confirmação para o paciente'}
          </S.Text>
        </S.Header>
        <S.LinkContainer>
          {!loading ? (
            <>
              <S.Link onClick={close}>Finalizar</S.Link>
            </>
          ) : (
            <LoadingSpinner color="#00cc8f" />
          )}
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalConfirmarConsulta;
