import styled from 'styled-components';
import { RiDashboardLine } from 'react-icons/ri';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  height: calc(100vh - 32px);
  overflow: scroll;
  background-color: #f6f6f6;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 50vw;
  height: 100vh;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-position: 0 50%;
  object-fit: cover;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  max-width: 600px;
`;

export const ButtonContainer = styled.div`
  & button {
    margin: 0;
  }
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled(RiDashboardLine)`
  width: 24px;
  height: 24px;
  stroke: #2fa8d5;
  fill: #2fa8d5;
  margin-right: 8px;
`;

export const Title = styled.h1`
  font-size: 22px;
  margin: 0;
  font-weight: 600;
  color: #2fa8d5;
`;

export const Description = styled.p`
  line-height: 1.3rem;
  color: #333;
  font-size: 14px;
`;

export const ContentL = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContentR = styled.div`
  display: flex;
`;

export const Content = styled.div`
  max-width: 1048px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
