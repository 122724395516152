/* eslint-disable no-plusplus */
import axios from 'axios';
import moment from 'moment';
import api from '../services/api';

// Abaixo estão as funções que podem ser utilizadas para validação de campos:

/** Verifica se o CNPJ  está incorreto (Código fonte da receita federal, apenas com alterações estruturais) */

export const testaCNPJ = (cnpj: string): boolean => {
  if (!cnpj) return true;

  // Guarda um array com todos os dígitos do valor
  const match = cnpj.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return true;

  // Elimina inválidos com todos os dígitos iguais
  for (let i = 0; i <= 9; i++) {
    if (cnpj === `${i * 11111111111111}`) return true;
  }

  // Cálculo validador
  const calc = (x: number) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return true;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 !== digits[1];
};

/** Verifica se o CPF está incorreto (Código fonte da receita federal, apenas com alterações estruturais) */
export const testaCPF = (strCPF: string): boolean => {
  let soma;
  let resto;
  let i;
  soma = 0;

  for (i = 0; i <= 9; i++) {
    if (strCPF === `${i * 11111111111}`) return true;
  }

  for (i = 1; i <= 9; i++)
    soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10), 10)) return true;

  soma = 0;
  for (i = 1; i <= 10; i++)
    soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;

  if (resto !== parseInt(strCPF.substring(10, 11), 10)) return true;
  return false;
};

/** Recebe uma string e retorna 'true' caso ela esteja vazia */
export const vazio = (value: string): boolean => value.toString().trim() === '';

/** Recebe uma string contendo um endereço de email e retorna 'true' caso ele for inválido */
export const emailIgual = (emailPrincipal: string) => (
  value: string,
): boolean => value === emailPrincipal;

/** Recebe uma string contendo um endereço de email e retorna 'true' caso ele for inválido */
export const emailValido = (value: string): boolean =>
  !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);

/** Recebe uma string contendo um endereço de email e retorna 'true' caso ele for inválido ou a string for vazia */
export const optEmailValido = (value: string): boolean =>
  value.trim() === '' ? false : !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);

/** Verifica se o email já foi cadastrado no banco de dados */
export const emailExistente = async (value: string): Promise<boolean> => {
  if (vazio(value)) return false;
  let invalid = true;
  await api.get(`/managers/check/${value}`).then(({ data: { exists } }) => {
    invalid = exists;
  });
  return invalid;
};

/** Verifica se o email já foi cadastrado no banco de dados */
export const registroValido = async (value: string): Promise<boolean> => {
  let invalid = true;
  await api
    .get(`/medicos/check/${value}`)
    .then(({ data: { exists } }) => {
      invalid = exists;
    })
    .catch(() => {});
  return invalid;
};

/** Recebe uma string contendo a senha e retorna true caso a senha for fraca seguindo os critérios de ter pelo menos:
 * - Um número;
 * - Uma letra maiúscula;
 * - Uma letra minúscula;
 * - Oito caracteres.
 */
export const senhaForte = (value: string): boolean =>
  !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value);

/** Verifica se as senhas coincidem, retornando 'true' caso forem diferentes */
export const senhasCoincidem = (senha: string) => (
  confirmação: string,
): boolean => confirmação !== senha;

export const conveniosVazios = (temConvenio: boolean) => (
  value: string[],
): boolean => temConvenio && value.length === 0;

/** Verifica se o campo foi preenchido com pelo menos um número 'n' de caracteres. */
export const tamanhoMinimo = (n: number) => (value: string): boolean =>
  value.length < n;

/** Verifica se o campo é vazio ou se foi preenchido com pelo menos um número 'n' de caracteres. */
export const optTamanhoMinimo = (n: number) => (value: string): boolean =>
  !value || value.trim() === '' ? false : value.length < n;

/** Verifica a string recebida retornando 'true' caso represente um CEP invalido. */
export const validaCep = async (value: string): Promise<boolean> => {
  let invalido = true;
  if (!tamanhoMinimo(8)(value))
    await axios
      .get(`https://viacep.com.br/ws/${value}/json`)
      .then(({ data: { erro } }) => {
        if (!erro) invalido = false;
      });
  return invalido;
};

/** Verifica se o botao de termos e condicoes foi marcado. */
export const termos = (value: boolean): boolean => !value;

/** Verifica se a data informada é valida. */
export const dataValida = (value: string): boolean =>
  !(
    moment(value, 'DDMMYYYY').clone().isValid() &&
    moment(value, 'DDMMYYYY').clone().isAfter(moment().clone())
  );

/** Verifica se o horário informado é valido. */
export const horarioValido = (value: string): boolean =>
  value.length !== 4 ? true : !moment(value, 'HHmm').clone().isValid();

export const verificarSenha = (email: string) => async (
  value: string,
): Promise<boolean> => {
  let invalido = true;
  await api
    .post('/managers/password-validate', {
      email,
      senha: value,
    })
    .then(({ data: { erro } }) => {
      if (!erro) invalido = false;
    })
    .catch(() => false);
  return invalido;
};
