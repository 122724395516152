import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import moment from 'moment';
import { AnimatePresence } from 'framer-motion';
import api from '../../../services/api';

import {
  ModalCancelarConsulta,
  ModalFinalizarConsulta,
  ModalConfirmarConsulta,
  ModalRemarcarConsulta,
  SkeletonModalConsulta,
} from '../index';

import * as S from './styles';

import * as I from './interface';

interface Props {
  id?: string;
  readOnly?: boolean;
  closeModal: () => void;
  fetch: () => void;
}

const ModalConsulta: React.FC<Props> = ({
  id,
  readOnly,
  closeModal,
  fetch,
}) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<I.Data>({
    idMedico: 0,
    idPaciente: 0,
    data: moment().clone(),
    especialista: '',
    status: '',
    nomePaciente: '',
    dataNasc: '',
    idade: '',
    celular: '',
    endereco: '',
    email: '',
    consultorio: '',
    pagamento: {
      tipo: '',
      valor: '',
    },
    telefoneContato: '',
    emailContato: '',
    pessoaContato: '',
    solicitacao: {
      nome: '',
      data: moment().clone(),
    },
    remarcacao: {
      isRemarcacao: false,
      dataSolicitacao: moment().clone(),
      novaData: moment().clone(),
      endereco: '',
    },
  });

  const [modals, setModals] = useState<{ [key: string]: any }>({
    remarcarConsulta: {
      state: false,
    },
    cancelarConsulta: {
      state: false,
    },
    confirmarConsulta: {
      state: false,
    },
    finalizarConsulta: {
      state: false,
    },
  });

  const toggleModal = (type: string, modalId?: string) => {
    return modalId
      ? setModals({
          ...modals,
          [type]: { modalId, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  const formatPhoneNumber = (phoneNumberString: string): string => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4}|\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
  };

  const getAge = (birthDate: string) => {
    const roundedAge = moment().diff(moment(birthDate), 'years');
    return roundedAge;
  };

  const getInitials = (name: string) => {
    const initials = name
      .split(' ')
      .map((n) => n[0])
      .filter((w) => w !== undefined);
    if (!initials[0]) return '';
    if (!initials[1]) return initials[0].toUpperCase();
    return (
      initials[0].toUpperCase() + initials[initials.length - 1].toUpperCase()
    );
  };

  const fetchData = () => {
    setLoading(true);
    api
      .get(`/consult/detailConsult/${id}`)
      .then(({ data: consulta }) => {
        const {
          id_medico: idMedico,
          id_paciente: idPaciente,
          data: dataConsulta,
          novaData: novaDataConsulta,
          especialista,
          status,
          nomePaciente,
          dataNasc,
          celular,
          email,
          consultorio,
          pagamento,
          endereco,
          solicitacao,
          remarcacao,
          telefoneContato,
          emailContato,
          pessoaContato,
        } = consulta;

        setData({
          idMedico,
          idPaciente,
          data:
            novaDataConsulta && !remarcacao.isRemarcacao
              ? moment(novaDataConsulta)
              : moment(dataConsulta),
          especialista,
          status,
          nomePaciente,
          dataNasc: moment(dataNasc).format('DD/MM/YYYY'),
          idade: getAge(dataNasc).toString(),
          celular: formatPhoneNumber(celular),
          email,
          consultorio,
          telefoneContato: formatPhoneNumber(telefoneContato),
          emailContato,
          pessoaContato,
          endereco:
            !remarcacao.endereco.includes('null') && !remarcacao.isRemarcacao
              ? remarcacao.endereco
              : endereco,
          pagamento,
          solicitacao: {
            nome: solicitacao.nome,
            data: moment(solicitacao.data),
          },
          remarcacao: {
            isRemarcacao: remarcacao.isRemarcacao,
            dataSolicitacao: moment(remarcacao.dataSolicitacao),
            novaData: moment(remarcacao.novaData),
            endereco: remarcacao.endereco,
          },
        });
        setLoading(false);
      })
      .catch(() => closeModal());
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDom.createPortal(
    <>
      <S.Background
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
        onClick={(e) => e.target === e.currentTarget && closeModal()}>
        {loading && <SkeletonModalConsulta />}
        {!loading && (
          <S.ModalContainer>
            <S.Header>
              <S.CloseButton onClick={closeModal} />
              <S.Title>Consulta</S.Title>
              <S.BoldText>
                {data.data
                  .format('dddd, DD/MM/YYYY [às] kk:mm')[0]
                  .toUpperCase() +
                  data.data.format('dddd, DD/MM/YYYY [às] kk:mm').substring(1)}
              </S.BoldText>
              <S.SpecText>Especialista: {data.especialista}</S.SpecText>

              <S.Row>
                Status:
                <S.Status status={data.status}>{data.status}</S.Status>
              </S.Row>
            </S.Header>

            <S.Content>
              {data.remarcacao.isRemarcacao && (
                <S.Reschedule>
                  <S.PatientDetails>
                    <S.RescheduleTitleContainer>
                      <S.RescheduleTitle>
                        Reagendamento solicitado
                      </S.RescheduleTitle>
                      <S.RescheduleTime>
                        {moment()
                          .clone()
                          .diff(data.remarcacao.dataSolicitacao, 'days') < 1
                          ? 'há menos de um dia'
                          : `há ${moment()
                              .clone()
                              .diff(
                                data.remarcacao.dataSolicitacao,
                                'days',
                              )} dia(s)`}
                      </S.RescheduleTime>
                    </S.RescheduleTitleContainer>
                    <S.Label>Esperando confirmação do paciente</S.Label>

                    <S.Label>Nova data</S.Label>
                    <S.BoldText>
                      {data.remarcacao.novaData
                        .format('dddd, DD/MM/YYYY [às] kk:mm')[0]
                        .toUpperCase() +
                        data.remarcacao.novaData
                          .format('dddd, DD/MM/YYYY [às] kk:mm')
                          .substring(1)}
                    </S.BoldText>

                    <S.Label>Novo endereço</S.Label>
                    <S.BoldText>{data.remarcacao.endereco}</S.BoldText>
                  </S.PatientDetails>
                </S.Reschedule>
              )}

              <S.Details>
                <S.AvatarContainer>
                  <S.InitialsContainer>
                    <S.Initials>{getInitials(data.nomePaciente)}</S.Initials>
                  </S.InitialsContainer>
                  <S.Name>{data.nomePaciente}</S.Name>
                </S.AvatarContainer>
                <S.Info>
                  <S.IconPatient />
                  <S.PatientDetails>
                    <S.BoldText>Informações do paciente</S.BoldText>

                    <S.Label>Data de nascimento</S.Label>
                    {moment(data.dataNasc, 'DD/MM/YYYY').isValid() ? (
                      <S.Row>
                        <S.BoldText>{data.dataNasc}</S.BoldText>
                        <S.LightText>{data.idade} anos</S.LightText>
                      </S.Row>
                    ) : (
                      <S.BoldText>Não informada pelo paciente</S.BoldText>
                    )}

                    <S.Label>Celular</S.Label>
                    <S.BoldText>{data.celular}</S.BoldText>

                    <S.Label>Email</S.Label>
                    <S.BoldText>{data.email}</S.BoldText>
                  </S.PatientDetails>
                </S.Info>
              </S.Details>

              <S.Details>
                <S.Info>
                  <S.IconDoctor />
                  <S.ScheduleDetails>
                    <S.BoldText>Informações do especialista</S.BoldText>

                    <S.Label>Nome</S.Label>
                    <S.BoldText>{data.especialista}</S.BoldText>

                    <S.Label>Telefone</S.Label>
                    <S.BoldText>{data.telefoneContato}</S.BoldText>

                    <S.Label>Email</S.Label>
                    <S.BoldText>{data.emailContato}</S.BoldText>

                    <S.Label>Pessoa de Contato</S.Label>
                    <S.BoldText>{data.pessoaContato}</S.BoldText>
                  </S.ScheduleDetails>
                </S.Info>
              </S.Details>
              <S.Details>
                <S.Info>
                  <S.IconCalendar />
                  <S.ScheduleDetails>
                    <S.BoldText>Informações do agendamento</S.BoldText>

                    <S.Label>Local do Atendimento</S.Label>
                    <S.BoldText>{data.endereco}</S.BoldText>

                    <S.Label>Pagamento</S.Label>
                    <S.Row>
                      <S.BoldText>
                        {data.pagamento.tipo.trim() !== ''
                          ? `${data.pagamento.tipo}`
                          : ''}
                        {data.pagamento.tipo.trim() === 'Particular'
                          ? ` | ${Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(parseInt(data.pagamento.valor, 10))}`
                          : ''}
                      </S.BoldText>
                    </S.Row>
                  </S.ScheduleDetails>
                </S.Info>
              </S.Details>
              <S.Details>
                <S.Info>
                  <S.IconRequest />
                  <S.ScheduleDetails>
                    <S.BoldText>Dados da solicitação de agendamento</S.BoldText>

                    <S.Label>Solicitação feita por</S.Label>
                    <S.Row>
                      <S.BoldText>
                        {data.solicitacao.nome}
                        {data.solicitacao.data.format(
                          '[, em] DD/MM/YYYY [às] kk:mm',
                        )}
                      </S.BoldText>
                    </S.Row>
                  </S.ScheduleDetails>
                </S.Info>
              </S.Details>
            </S.Content>
            <S.ButtonContainer>
              {!readOnly &&
                data.status !== 'Finalizada' &&
                data.status !== 'Cancelada' && (
                  <>
                    {!data.remarcacao.isRemarcacao &&
                    data.status !== 'Agendada' ? (
                      <S.ButtonCancelar
                        onClick={() => toggleModal('cancelarConsulta', id)}
                        value="Cancelar"
                      />
                    ) : (
                      <>
                        <S.ButtonCancelar
                          onClick={() => toggleModal('cancelarConsulta', id)}
                          value="Cancelar"
                        />
                        <S.ButtonFinalizar
                          onClick={() => toggleModal('finalizarConsulta', id)}
                          value="Finalizar"
                          primary
                        />
                      </>
                    )}
                    {!data.remarcacao.isRemarcacao &&
                      data.status !== 'Agendada' && (
                        <S.Row>
                          <S.ButtonRemarcar
                            onClick={() => toggleModal('remarcarConsulta')}
                            value="Remarcar"
                            primary
                          />
                          <S.ButtonConfirmar
                            onClick={() => toggleModal('confirmarConsulta')}
                            value="Agendar"
                            primary
                          />
                        </S.Row>
                      )}
                  </>
                )}
            </S.ButtonContainer>
          </S.ModalContainer>
        )}
      </S.Background>
      <AnimatePresence>
        {modals.remarcarConsulta.state && (
          <ModalRemarcarConsulta
            id={id}
            idMedico={data.idMedico}
            idPaciente={data.idPaciente}
            closeModal={() => toggleModal('remarcarConsulta')}
            closeAll={() => {
              closeModal();
              fetch();
            }}
          />
        )}
        {modals.cancelarConsulta.state && (
          <ModalCancelarConsulta
            id={id}
            closeModal={() => toggleModal('cancelarConsulta')}
            closeAll={() => {
              closeModal();
              fetch();
            }}
          />
        )}
        {modals.confirmarConsulta.state && (
          <ModalConfirmarConsulta
            id={id}
            closeModal={() => toggleModal('confirmarConsulta')}
            closeAll={() => {
              closeModal();
              fetch();
            }}
          />
        )}
        {modals.finalizarConsulta.state && (
          <ModalFinalizarConsulta
            id={id}
            closeModal={() => toggleModal('finalizarConsulta')}
            closeAll={() => {
              closeModal();
              fetch();
            }}
          />
        )}
      </AnimatePresence>
    </>,

    document.getElementById('portal')!,
  );
};

export default ModalConsulta;
