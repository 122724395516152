import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const SpecialistButton: React.FC<I.Props> = ({
  value,
  isActive,
  isPending,
  ...res
}) => {
  return (
    <S.Button {...res}>
      <S.Content>
        <S.IconSpecialist />
        Dr(a). {value}
        <S.Status active={isActive}>{isActive ? 'Ativo' : 'Inativo'}</S.Status>
        {isPending && <S.Status pending>Pendente</S.Status>}
      </S.Content>
      <S.IconChevron />
    </S.Button>
  );
};

export default SpecialistButton;
