import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  ActionButton,
  InputPasswordField,
  InputField,
} from '../../../components';

import { userContext } from '../FormContainer';

import { emailSenhaValidator } from '../../../utils/formValidator';

import * as S from './styles';

import * as I from '../../../shared/interfaces/formInterfaces';

interface Props {
  nextStep: () => void;
}

const FormEmailSenha: React.FC<Props> = ({ nextStep }) => {
  const history = useHistory();
  const { userData, setUserData } = useContext(userContext);
  const [load, setLoad] = useState<boolean>(false);

  const { email, senha, confirmarSenha } = userData!;

  const [errorData, setErrorData] = useState<I.ErrorEmailSenha>({
    email: '',
    senha: '',
    confirmarSenha: '',
  });

  /** A função 'next' valida os dados de forma assíncrona. Se os dados forem válidos, o próximo passo é renderizado através da função 'nextStep'. */
  const next = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoad(true);
    if (await emailSenhaValidator(userData!, setErrorData)) {
      nextStep();
    } else {
      setLoad(false);
    }
  };

  /** A função 'handleChange' altera o estado do objeto de erro e de dados do usuário.
   * @param data: Se refere a qual dado dentro do objeto irá sofrer a alteração.
   * @param value: Se refere ao novo valor que esse dado irá receber.  */
  const handleChange = (data: any, value: any) => {
    setErrorData({ ...errorData, [data]: '' });
    setUserData!({ ...userData!, [data]: value });
  };

  return (
    <S.Container>
      <S.TitleContainer>
        <S.PrimaryMessage>Criar conta</S.PrimaryMessage>
        {/* <S.SecondaryMessage>Cadastre uma nova conta</S.SecondaryMessage> */}
      </S.TitleContainer>
      <S.FormContainer onSubmit={next}>
        <S.InputContainer>
          <InputField
            id="email"
            value={email}
            onChange={(e) => handleChange('email', e.target.value)}
            placeholder="nome@dominio.com"
            labelText="Email"
            width="286px"
            invalid={errorData!.email}
          />

          <InputPasswordField
            id="senha"
            value={senha}
            onChange={(e) => handleChange('senha', e.target.value)}
            placeholder="••••••••"
            labelText="Senha"
            width="286px"
            invalid={errorData!.senha}
          />

          <InputPasswordField
            id="confirmarSenha"
            value={confirmarSenha}
            onChange={(e) => handleChange('confirmarSenha', e.target.value)}
            placeholder="••••••••"
            labelText="Confirmar senha"
            width="286px"
            invalid={errorData!.confirmarSenha}
          />
        </S.InputContainer>

        <S.ButtonContainer>
          <ActionButton
            onClick={() => {}}
            value="Continuar"
            primary
            loading={load}
          />
          <ActionButton
            onClick={() => history.push('/auth/login')}
            value="Voltar"
          />
        </S.ButtonContainer>
      </S.FormContainer>
    </S.Container>
  );
};

export default FormEmailSenha;
