import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import axios from 'axios';

import {
  SelectOne,
  InputField,
  ActionButton,
  InputNumberField,
} from '../../../components';

import { modalNovoEnderecoValidator } from '../../../utils/modalValidator';

import * as S from './styles';

import * as I from './interface';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import api from '../../../services/api';

interface Props {
  id?: string;
  readOnly?: boolean;
  closeModal: () => void;
  fetchData: () => void;
}

const ModalNovoEndereco: React.FC<Props> = ({ closeModal, fetchData }) => {
  const [load, setLoad] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [cidades, setCidades] = useState<string[]>([]);
  const [estados, setEstados] = useState<string[]>([]);

  const [data, setData] = useState<I.Data>({
    rua: '',
    numero: '',
    estado: '',
    cidade: '',
    bairro: '',
    cep: '',
    tel: '',
    cel: '',
  });

  const [errorData, setErrorData] = useState<I.ErrorData>({
    rua: '',
    numero: '',
    estado: '',
    cidade: '',
    bairro: '',
    cep: '',
    tel: '',
    cel: '',
  });

  const handleChange = (field: any, value: any) => {
    setData({ ...data, [field]: value });
    setErrorData({ ...errorData, [field]: '' });
  };

  const confirm = async () => {
    setLoad(true);
    if (await modalNovoEnderecoValidator(data, setErrorData)) {
      const { data: search } = await api.post('/coordinates', {
        search: `${data.rua},${data.numero},${data.bairro},${data.cidade},${data.estado},${data.cep}`,
      });

      api
        .post(`/address`, {
          rua: data.rua,
          numero: data.numero,
          bairro: data.bairro,
          uf: data.estado,
          cidade: data.cidade,
          cep: data.cep,
          telefone_cel: data.cel,
          telefone_fixo: data.tel,
          coordenadas: search.split(',').reverse().join(','),
        })
        .then(() => {
          setStep(step + 1);
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  const closeAndFetch = () => {
    closeModal();
    fetchData();
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <S.Description>
              Preencha os campos abaixo com os dados do novo endereço e clique
              em confirmar para salvar. Caso desista de incluir um novo
              endereço, clique em cancelar.
            </S.Description>
            <S.ContentContainer>
              <InputField
                id="rua"
                value={data.rua}
                onChange={(e) => handleChange('rua', e.target.value)}
                placeholder="Ex.: Rua, Av., Vila..."
                labelText="Logradouro"
                width="205px"
                invalid={errorData.rua}
              />

              <InputNumberField
                id="numero"
                value={data.numero}
                onChange={({ value }) => handleChange('numero', value)}
                placeholder="Número..."
                labelText="Número"
                width="87px"
                format="#####"
                mask=""
                invalid={errorData.numero}
              />

              <InputField
                id="bairro"
                value={data.bairro}
                onChange={(e) => handleChange('bairro', e.target.value)}
                placeholder="Nome do bairro..."
                labelText="Bairro"
                width="136px"
                invalid={errorData.bairro}
              />
            </S.ContentContainer>

            <S.ContentContainer>
              <SelectOne
                id="estado"
                value={data.estado}
                onChangeValue={(opt) => handleChange('estado', opt)}
                labelText="Estado"
                width="150px"
                options={estados}
                invalid={errorData.estado}
              />

              <SelectOne
                id="cidade"
                value={data.cidade}
                onChangeValue={(opt) => handleChange('cidade', opt)}
                labelText="Cidade"
                options={cidades}
                width="190px"
                invalid={errorData.cidade}
              />

              <InputNumberField
                id="cep"
                value={data.cep}
                onChange={({ value }) => handleChange('cep', value)}
                placeholder="00000-000"
                labelText="CEP"
                format="#####-###"
                mask="_"
                invalid={errorData.cep}
              />
            </S.ContentContainer>

            <S.ContentContainer>
              <InputNumberField
                id="tel"
                value={data.tel}
                onChange={({ value }) => handleChange('tel', value)}
                placeholder="(00) 0000-0000"
                labelText="Telefone"
                width="125px"
                format="(##) ####-####"
                mask="_"
                invalid={errorData!.tel}
              />

              <InputNumberField
                id="cel"
                value={data.cel}
                onChange={({ value }) => handleChange('cel', value)}
                placeholder="(00) 00000-0000"
                labelText="Celular"
                width="130px"
                format="(##) #####-####"
                mask="_"
                invalid={errorData!.cel}
                optional
              />
            </S.ContentContainer>
          </>
        );
      case 2:
        return (
          <S.SuccessMessageContainer>
            <S.Subtitle>Cadastro realizado com sucesso!</S.Subtitle>
            <S.Description>
              Você pode acessar o cadastro do novo endereço no painel de
              configurações.
            </S.Description>
          </S.SuccessMessageContainer>
        );
      default:
        return <h3>Ocorreu um erro! Reinicie a página e tente novamente.</h3>;
    }
  };

  useEffect(() => {
    axios
      .get<{ sigla: string }[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
      )
      .then((res) => {
        const ufs = res.data.map((uf) => uf.sigla).sort();
        setEstados(ufs);
      })
      .catch(() => {});
  }, []);

  useUpdateEffect(() => {
    if (data.estado === '') return;
    setData!((state) => ({ ...state, cidade: '' }));
    axios
      .get<{ nome: string }[]>(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data.estado}/municipios`,
      )
      .then((res) => {
        const cityList = res.data
          .map((city) => city.nome)
          .sort((a, b) => a.localeCompare(b)); // Ignorando acentos
        setCidades(cityList);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.estado]);

  return ReactDom.createPortal(
    <>
      <S.Background
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
        onClick={(e) =>
          e.target === e.currentTarget &&
          (step === 1 ? closeModal() : closeAndFetch())
        }>
        <S.ModalContainer>
          <S.Content>
            <S.Title>Novo Endereço</S.Title>

            {renderStep()}
          </S.Content>
          <S.ButtonContainer>
            {step === 1 ? (
              <>
                <ActionButton onClick={closeModal} value="Cancelar" />
                <ActionButton
                  onClick={confirm}
                  value="Confirmar"
                  primary
                  loading={load}
                />
              </>
            ) : (
              <ActionButton onClick={closeAndFetch} value="Fechar" />
            )}
          </S.ButtonContainer>
        </S.ModalContainer>
      </S.Background>
    </>,

    document.getElementById('portal')!,
  );
};

export default ModalNovoEndereco;
