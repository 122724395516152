import styled from 'styled-components';
import { FaInfo } from 'react-icons/fa';

export const Container = styled.div``;

export const Message = styled.span`
  /* position: absolute; */
  display: inline-block;
`;

export const UpdateMessage = styled.span`
  /* position: absolute; */
  display: none;
  font-weight: bold;
  font-size: 11px;
`;

export const Photo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  clip-path: circle(50px at center);
`;

export const PhotoContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 16px 0 24px 0;
  background-color: #d7ecff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 500;
  line-height: 0;

  cursor: pointer;
  transition: 0.2s;

  position: relative;

  &:hover {
    background-color: #e0e1e2;

    ${Photo} {
      filter: grayscale(100%);
      opacity: 0.3;
      position: absolute;
    }

    ${UpdateMessage} {
      display: inline-block;
      position: absolute;
    }
  }
`;

export const Input = styled.input`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
`;

export const ErrorIcon = styled(FaInfo)`
  width: 50%;
  height: 50%;
  color: white;
`;

export const ErrorMessage = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 0;
  right: -10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  p {
    cursor: default;
    display: none;
    color: #fff;
  }

  &:hover {
    padding: 0 10px;
    width: auto;
    height: auto;
    border-radius: 4px;
    white-space: pre;
    right: -315px;
    z-index: 1000;

    ${ErrorIcon} {
      display: none;
    }

    p {
      display: inline;
    }
  }
`;
