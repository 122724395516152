import { Dispatch, SetStateAction } from 'react';

import * as IForm from '../shared/interfaces/formInterfaces';
import * as ILogin from '../shared/interfaces/loginInterfaces';
import * as IRec from '../shared/interfaces/recoveryInterfaces';

/**
  Função que recebe os seguintes valores:

  @param field (string): o nome do campo a ser validado;

  @param value (string, number ou array de string): o valor contido no campo a ser validado;

  @param conditions (um array de funções): recebe um array de condições a serem testadas, a
função Validator irá percorrer esse array testando cada uma dessas condições, e irá
retornar 'true' caso todas as condições sejam obedecidas, retornando 'false' caso contrário;

  @param msgArray (array de string): representa a mensagem de erro a ser exibida para cada
condição do array de condições. Exemplo: se o array de condições for da forma [condição1,
condição2], o array de mensagens deve ser [mensagemDaCondição1, mensagemDaCondição2];

  @param setErrorData: representa a função que troca o valor das mensagens de erro vinda do
  [errorData, setErrorData] = useState().
*/

export const validator = async (
  field: string,
  value: boolean | string | number | string[],
  conditions: any[],
  msgArray: string[],
  setErrorData:
    | Dispatch<SetStateAction<IForm.ErrorEmailSenha>>
    | Dispatch<SetStateAction<IForm.ErrorEqMedica>>
    | Dispatch<SetStateAction<IForm.ErrorEndereco>>
    | Dispatch<SetStateAction<IForm.ErrorTermos>>
    | Dispatch<SetStateAction<ILogin.Login>>
    | Dispatch<SetStateAction<IRec.RecoveryEmail>>
    | Dispatch<SetStateAction<IRec.RecoveryCode>>
    | Dispatch<SetStateAction<IRec.RecoveryPassword>>,
  isAsync = false,
): Promise<boolean> => {
  // Percorre o vetor de condições, retornando um boolean representando se a condição foi respeitada ou não.

  const validationArray = conditions.map(
    async (condition: any, index: number) => {
      if (isAsync) {
        if (await condition(value)) {
          setErrorData((state: any) => ({
            ...state,
            [field]: msgArray[index],
          }));
          return false;
        }
        return true;
      }
      if (condition(value)) {
        setErrorData((state: any) => ({
          ...state,
          [field]: msgArray[index],
        }));
        return false;
      }
      return true;
    },
  );

  // Se todas as condições forem cumpridas a variável validation receberá 'true', caso contrário receberá 'false'.

  let validation = false;
  await Promise.all(validationArray).then((res) => {
    validation = res.every((e: boolean) => e === true);
  });

  // Se for válido, limpa a mensagem de erro.

  if (validation)
    setErrorData((state: any) => ({
      ...state,
      [field]: '',
    }));

  // Por fim, retorna se é valido ou não.

  return validation;
};

/* Mesmo que a função acima, mas lida com vetores de especialidades. */
export const espValidator = async (
  field: string,
  value: string | number,
  espIndex: number,
  conditions: any[],
  msgArray: string[],
  setErrorData: Dispatch<SetStateAction<IForm.ErrorEqMedica>>,
  isAsync = false,
): Promise<boolean> => {
  // Percorre o vetor de condições, retornando um boolean representando se a condição foi respeitada ou não.

  const validationArray = conditions.map(
    async (condition: any, index: number) => {
      if (isAsync) {
        if (await condition(value)) {
          setErrorData((state) => ({
            ...state,
            especialidades: [
              ...state.especialidades.slice(0, espIndex),
              {
                ...state.especialidades[espIndex],
                [field]: msgArray[index],
              },
              ...state.especialidades.slice(espIndex + 1),
            ],
          }));
          return false;
        }
        return true;
      }
      if (condition(value)) {
        setErrorData((state) => ({
          ...state,
          especialidades: [
            ...state.especialidades.slice(0, espIndex),
            {
              ...state.especialidades[espIndex],
              [field]: msgArray[index],
            },
            ...state.especialidades.slice(espIndex + 1),
          ],
        }));
        return false;
      }
      return true;
    },
  );

  // Se todas as condições forem cumpridas a variável validation receberá 'true', caso contrário receberá 'false'.

  let validation = false;
  await Promise.all(validationArray).then((res) => {
    validation = res.every((e: boolean) => e === true);
  });

  // Se for válido, limpa a mensagem de erro.

  if (validation) {
    setErrorData((state) => ({
      ...state,
      especialidades: [
        ...state.especialidades.slice(0, espIndex),
        {
          ...state.especialidades[espIndex],
          [field]: '',
        },
        ...state.especialidades.slice(espIndex + 1),
      ],
    }));
  }

  // Por fim, retorna se é valido ou não.

  return validation;
};

/* Mesmo que a função acima, mas lida com vetores de experiencias. */
export const expValidator = async (
  field: string,
  value: string | number,
  espIndex: number,
  conditions: any[],
  msgArray: string[],
  setErrorData: Dispatch<SetStateAction<IForm.ErrorExp>>,
  isAsync = false,
): Promise<boolean> => {
  // Percorre o vetor de condições, retornando um boolean representando se a condição foi respeitada ou não.

  const validationArray = conditions.map(
    async (condition: any, index: number) => {
      if (isAsync) {
        if (await condition(value)) {
          setErrorData((state) => ({
            ...state,
            experiencias: [
              ...state.experiencias.slice(0, espIndex),
              {
                ...state.experiencias[espIndex],
                [field]: msgArray[index],
              },
              ...state.experiencias.slice(espIndex + 1),
            ],
          }));
          return false;
        }
        return true;
      }
      if (condition(value)) {
        setErrorData((state) => ({
          ...state,
          experiencias: [
            ...state.experiencias.slice(0, espIndex),
            {
              ...state.experiencias[espIndex],
              [field]: msgArray[index],
            },
            ...state.experiencias.slice(espIndex + 1),
          ],
        }));
        return false;
      }
      return true;
    },
  );

  // Se todas as condições forem cumpridas a variável validation receberá 'true', caso contrário receberá 'false'.
  let validation = false;
  await Promise.all(validationArray).then((res) => {
    validation = res.every((e: boolean) => e === true);
  });

  // Se for válido, limpa a mensagem de erro.

  if (validation)
    setErrorData((state) => ({
      ...state,
      experiencias: [
        ...state.experiencias.slice(0, espIndex),
        {
          ...state.experiencias[espIndex],
          [field]: '',
        },
        ...state.experiencias.slice(espIndex + 1),
      ],
    }));

  // Por fim, retorna se é valido ou não.

  return validation;
};

/* Mesmo que a função acima, mas lida com vetores de formacoes. */
export const forValidator = async (
  field: string,
  value: string | number,
  espIndex: number,
  conditions: any[],
  msgArray: string[],
  setErrorData: Dispatch<SetStateAction<IForm.ErrorExp>>,
  isAsync = false,
): Promise<boolean> => {
  // Percorre o vetor de condições, retornando um boolean representando se a condição foi respeitada ou não.

  const validationArray = conditions.map(
    async (condition: any, index: number) => {
      if (isAsync) {
        if (await condition(value)) {
          setErrorData((state) => ({
            ...state,
            formacoes: [
              ...state.formacoes.slice(0, espIndex),
              {
                ...state.formacoes[espIndex],
                [field]: msgArray[index],
              },
              ...state.formacoes.slice(espIndex + 1),
            ],
          }));
          return false;
        }
        return true;
      }
      if (condition(value)) {
        setErrorData((state) => ({
          ...state,
          formacoes: [
            ...state.formacoes.slice(0, espIndex),
            {
              ...state.formacoes[espIndex],
              [field]: msgArray[index],
            },
            ...state.formacoes.slice(espIndex + 1),
          ],
        }));
        return false;
      }
      return true;
    },
  );

  // Se todas as condições forem cumpridas a variável validation receberá 'true', caso contrário receberá 'false'.

  let validation = false;
  await Promise.all(validationArray).then((res) => {
    validation = res.every((e: boolean) => e === true);
  });

  // Se for válido, limpa a mensagem de erro.

  if (validation)
    setErrorData((state) => ({
      ...state,
      formacoes: [
        ...state.formacoes.slice(0, espIndex),
        {
          ...state.formacoes[espIndex],
          [field]: '',
        },
        ...state.formacoes.slice(espIndex + 1),
      ],
    }));

  // Por fim, retorna se é valido ou não.

  return validation;
};
