import styled from 'styled-components';

interface Props {
  status: string;
  active: boolean;
}

export const Button = styled.button<Props>`
  width: 100px;
  height: 38px;
  color: ${({ status, active }) => {
    switch (status) {
      case 'solicitada':
        return active ? 'white' : '#FD9800';
      case 'agendada':
        return active ? 'white' : '#00cc8f';
      case 'pendente':
        return active ? 'white' : '#2fa8d5';
      case 'cancelada':
        return active ? 'white' : '#CF4647';
      case 'finalizada':
        return active ? 'white' : '#333333';
      default:
        return 'grey';
    }
  }};
  border: ${({ status }) => {
    switch (status) {
      case 'solicitada':
        return '1px solid #FD9800';
      case 'agendada':
        return '1px solid #00cc8f';
      case 'pendente':
        return '1px solid #2fa8d5';
      case 'cancelada':
        return '1px solid #CF4647';
      case 'finalizada':
        return '1px solid #333333';
      default:
        return '1px solid grey';
    }
  }};
  background-color: ${({ status, active }) => {
    switch (status) {
      case 'solicitada':
        return active ? '#FD9800' : 'white';
      case 'agendada':
        return active ? '#00cc8f' : 'white';
      case 'pendente':
        return active ? '#2fa8d5' : 'white';
      case 'cancelada':
        return active ? '#CF4647' : 'white';
      case 'finalizada':
        return active ? '#333333' : 'white';
      default:
        return 'grey';
    }
  }};
  border-radius: 4px;
  margin: 16px 0 0 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  /* line-height: 0; */

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

export const Dot = styled.div<Props>`
  width: 6px;
  height: 6px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${({ status, active }) => {
    switch (status) {
      case 'solicitada':
        return active ? 'white' : '#FD9800';
      case 'agendada':
        return active ? 'white' : '#37D363';
      case 'pendente':
        return active ? 'white' : '#2fa8d5';
      case 'cancelada':
        return active ? 'white' : '#CF4647';
      case 'finalizada':
        return active ? 'white' : '#333333';
      default:
        return 'grey';
    }
  }};
`;
