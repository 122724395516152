import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import { InputNumberField } from '../../../components';

import {
  ModalDesativarPerfil,
  ModalAprovarCadastro,
  ModalRecusarCadastro,
  ModalAtivarPerfil,
} from '../../Modal';

import * as S from './styles';

import * as I from './interface';

const DetalhesAvancados: React.FC<I.Props> = ({
  idMedico,
  pendente = false,
  ativo = false,
  dataInclusao,
  fetch,
}) => {
  const [modals, setModals] = useState<{ [key: string]: any }>({
    senha: {
      id: '0',
      state: false,
    },
    aprovarCadastro: {
      id: '0',
      state: false,
    },
    recusarCadastro: {
      id: '0',
      state: false,
    },
    ativarPerfil: {
      id: '0',
      state: false,
    },
    desativarPerfil: {
      id: '0',
      state: false,
    },
  });

  const toggleModal = (type: string, id?: string) => {
    return id
      ? setModals({
          ...modals,
          [type]: { id, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Title>Avançado</S.Title>
          <S.Description>
            Aqui é possível aprovar ou recusar a solicitação de cadastro de um
            médico. Se o cadastro já estiver aprovado, também é possível
            desativá-lo permanentemente.
          </S.Description>
          {pendente ? (
            <S.ApprovalContainer>
              <S.ApprovalTitle>Aprovação Pendente</S.ApprovalTitle>
              <S.ApprovalDescription>
                O pedido de cadastro do médico está pendente, você pode aceitar
                ou recusar, caso recuse, todos os dados do médico serão perdidos
                e será necessário um novo cadastro
              </S.ApprovalDescription>
              <S.ContentRow>
                <S.ApprovalAcceptButton
                  onClick={() => toggleModal('aprovarCadastro', idMedico)}
                  value="Aceitar"
                />
                <S.ApprovalDenyButton
                  onClick={() => toggleModal('recusarCadastro', idMedico)}
                  value="Recusar"
                />
              </S.ContentRow>
            </S.ApprovalContainer>
          ) : (
            <S.DeactivateProfileContainer>
              <S.DateContainer>
                <S.IconCalendar />
                <InputNumberField
                  id="dataInclusao"
                  value={dataInclusao.format('DDMMYYYY')}
                  placeholder="00/00/0000"
                  labelText="Data de inclusão"
                  width="70px"
                  format="##/##/####"
                  isDisabled
                />
              </S.DateContainer>
              <S.Divisor />
              {ativo ? (
                <>
                  <S.DeactivateTitle>Desativar Perfil</S.DeactivateTitle>
                  <S.StatusDescription>
                    Ao desativar o perfil do médico, ele deixará de aparecer nas
                    buscas do aplicativo.
                  </S.StatusDescription>
                  <S.ApprovalDenyButton
                    onClick={() => toggleModal('desativarPerfil', idMedico)}
                    value="Desativar"
                  />
                </>
              ) : (
                <>
                  <S.ActivateTitle>Ativar Perfil</S.ActivateTitle>
                  <S.StatusDescription>
                    Ao ativar o perfil do médico, ele voltará a aparecer nas
                    buscas do aplicativo.
                  </S.StatusDescription>
                  <S.ApprovalAcceptButton
                    onClick={() => toggleModal('ativarPerfil', idMedico)}
                    value="Ativar"
                  />
                </>
              )}
            </S.DeactivateProfileContainer>
          )}
        </S.TitleContainer>
      </S.Header>
      <AnimatePresence>
        {modals.aprovarCadastro.state && (
          <ModalAprovarCadastro
            id={modals.aprovarCadastro.id}
            closeModal={() => toggleModal('aprovarCadastro')}
            fetch={fetch}
          />
        )}
        {modals.recusarCadastro.state && (
          <ModalRecusarCadastro
            id={modals.recusarCadastro.id}
            closeModal={() => toggleModal('recusarCadastro')}
            fetch={fetch}
          />
        )}
        {modals.desativarPerfil.state && (
          <ModalDesativarPerfil
            id={modals.desativarPerfil.id}
            closeModal={() => toggleModal('desativarPerfil')}
            fetch={fetch}
          />
        )}
        {modals.ativarPerfil.state && (
          <ModalAtivarPerfil
            id={modals.ativarPerfil.id}
            closeModal={() => toggleModal('ativarPerfil')}
            fetch={fetch}
          />
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default DetalhesAvancados;
