import { Dispatch, SetStateAction } from 'react';
// import axios from 'axios';

import { emailValido, vazio, senhaForte, senhasCoincidem } from './conditions';

import { validator } from './validators';

import * as I from '../shared/interfaces/recoveryInterfaces';

export const recoveryEmailValidator = async (
  { email }: I.RecoveryEmail,
  setErrorData: Dispatch<SetStateAction<I.RecoveryEmail>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;
  await Promise.all([
    validator(
      'email',
      email,
      [emailValido, vazio],
      ['O email deve ser válido!', 'O email não pode ser vazio!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const recoveryCodeValidator = async (
  { code }: I.RecoveryCode,
  setErrorData: Dispatch<SetStateAction<I.RecoveryCode>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;
  await Promise.all([
    validator(
      'code',
      code,
      [vazio],
      ['Preencha com o código recebido por email!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const recoveryPasswordValidator = async (
  { senha, confirmarSenha }: I.RecoveryPassword,
  setErrorData: Dispatch<SetStateAction<I.RecoveryPassword>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;
  await Promise.all([
    validator(
      'senha',
      senha,
      [senhaForte, vazio],
      [
        'A senha deve conter, no mínimo:\n\t• Um número.\n\t• Uma letra maiúscula.\n\t• Uma letra minúscula.\n\t• Oito caracteres.',
        'A senha não pode ser vazia!',
      ],
      setErrorData,
    ),
    validator(
      'confirmarSenha',
      confirmarSenha,
      [senhasCoincidem(senha), vazio],
      [
        'As senhas não coincidem!',
        'A confirmação de senha não pode ser vazia!',
      ],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};
