import React from 'react';
import * as S from './styles';

import WhiteLogo from '../../assets/images/logo-white.svg';

const PolicyLayout: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        <img src={WhiteLogo} alt="ClickConsultas Logo" />
      </S.Header>
      <S.PolicyContainer>
        <S.PolicyWrapper>
          <S.Title>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</S.Title>
          <S.Paragraph>
            <b>1.1 USUÁRIO:</b> é denominado usuário toda pessoa física que
            possui uma conta/perfil no App Clickconsultas.com para busca de
            profissionais da área da saúde e áreas correlatas e afins;
          </S.Paragraph>
          <S.Paragraph>
            <b>1.2 ESPECIALISTA:</b> profissionais da área da saúde e áreas
            correlatas e afins, que exerce nos termos da Legislação em vigor, a
            profissão de médico, dentista, fisioterapeuta, nutricionista,
            terapeuta, cirurgião, psicólogo, personal trainer, etc;
          </S.Paragraph>
          <S.Paragraph>
            <b>1.3 PESSOA JURÍDICA:</b> entidade formada por indivíduo (s) e
            reconhecida pelo Estado como detentora de direitos e deveres, tais
            como: empresas, governos, organizações ou qualquer grupo criado com
            uma finalidade específica, que oferecem seus serviços e produtos aos
            usuários por meio deste App Clickconsultas.com;
          </S.Paragraph>
          <S.Paragraph>
            <b>1.4 CONTA DE ACESSO:</b> meio digital que permite a visualização
            e utilização dos serviços oferecidos pelo App Clickconsultas.com,
            através de acesso por meio de login e senha;
          </S.Paragraph>
          <S.Paragraph>
            <b>1.5 PERFIL:</b> define quais direitos e permissões tem os
            usuários do sistema, prestadores de serviços, pessoa jurídica,
            profissional liberal da área da saúde e afins;
          </S.Paragraph>
          <S.Paragraph>
            <b>1.6 AGENDAMENTO DE CONSULTAS:</b> o usuário poderá através do App
            Clickconsultas.com agendar consultas presenciais ou online (conforme
            modalidade disponibilizada no App na hora do agendamento) junto aos
            prestadores de serviços ou profissional de sua preferência, que por
            sua vez confirmará o agendamento ou apresentará ao usuário outra (s)
            data (s) disponíveis para agendamento. Esta nova data deverá ser
            aprovada pelo usuário que estará ciente da alteração da mesma,
            confirmando o reagendamento da consulta;
          </S.Paragraph>
          <S.Paragraph>
            <b>1.7 PACOTE DE SERVIÇOS:</b> serviços disponibilizados pelo App
            Clickconsultas.com aos prestadores de serviços, pessoa jurídica,
            profissional liberal da área da saúde e afins, firmados através de
            aditivo contratual, mediante a escolha do pacote e pagamento de
            valores especificado na tabela de preços do Clickconsultas.com;
          </S.Paragraph>
          <S.Paragraph>
            <b>1.8 CONTRATADA:</b> Será denominada CONTRATADA a empresa AD3Com
            Comunicação e Design, 22.602.439/0001-27 com sede à rua Serra da
            Canastra nº 1000 sala 02, 86065-160, Jardim Bandeirantes,
            Londrina/Pr responsável pela criação, manutenção, atualização e
            funcionamento do App Clickconsultas.com;
          </S.Paragraph>
          <S.Paragraph>
            <b>1.9 CONTRATANTE:</b> Será denominada CONTRATANTE o prestador de
            serviços, pessoa jurídica, profissional liberal da área da saúde e
            afins, que possui cadastro ativo nos órgãos da Federação Brasileira
            e cadastro ativo através da aquisição de pacote de serviços
            adquirido no Clickconsultas.com;
          </S.Paragraph>
          <S.Subtitle>2. CELEBRAÇÃO DO CONTRATO OU TERMO DE ADESÃO</S.Subtitle>
          <S.Paragraph>
            <b>2.1 </b>Se concretizará por meio eletrônico ou físico com o
            consentimento da CONTRATANTE ou USUÁRIO conforme sua conta/pefil,
            que ao final da leitura do Contrato ou Termo de Adesão, dará o seu
            “De Acordo” por meio eletrônico ou através de assinatura em contrato
            físico, firmando assim a contratação dos serviços oferecidos pelo
            App Clickconsultas.com;
          </S.Paragraph>
          <S.Paragraph>
            <b>2.2 </b>O Termo de Adesão também poderá ser firmado por meio de
            ligação telefônica com aceite de voz;
          </S.Paragraph>
          <S.Subtitle>
            3. UTILIZAÇÃO DO APP CLICKCONSULTAS.COM PELOS USUÁRIOS
          </S.Subtitle>
          <S.Paragraph>
            <b>3.1 </b>Os usuários poderão utilizar gratuitamente o App para
            localizar profissionais da área da saúde e áreas afins, exceto
            quando agendar uma consulta por convênio participativo ou consulta
            particular cujo valor estabelecido da consulta deverá ser pago
            diretamente na clinica/consultório ou através do App
            Clickconsultas.com, caso esteja disponibilizado pagamento online.
          </S.Paragraph>
          <S.Subtitle>
            4. FORMA DE PAGAMENTO DISPONIBILIZADO PELA CONTRATADA A CONTRATANTE
          </S.Subtitle>
          <S.Paragraph>
            <b>4.1 </b>cartão de crédito, boleto bancário, depósito em conta,
            transferência de valores ou PIX;
          </S.Paragraph>
          <S.Paragraph>
            <b>4.2 </b>Será encaminhada pela CONTRATADA a CONTRATANTE até o dia
            10 do mês subsequente a planilha dos serviços prestados no mês
            anterior e a NF para recebimento dos serviços prestados de
            agendamento de consulta;
          </S.Paragraph>
          <S.Subtitle>5. PACOTE DE SERVIÇOS</S.Subtitle>
          <S.Paragraph>
            <b>5.1 </b>Para adquirir um pacote de serviços a CONTRATANTE deverá
            consultar o App Clickconsultas.com pelo email (
            <a href="mailto:contato@clickconsultas.com">
              contato@clickconsultas.com
            </a>
            );
          </S.Paragraph>
          <S.Subtitle>
            6. SERVIÇOS PRESTADOS PELO APP DE ACORDO COM CADA PERFIL OU CONTA
          </S.Subtitle>
          <S.Paragraph>
            <b>6.1 </b>App de busca de profissionais na área da saúde e afins;
          </S.Paragraph>
          <S.Paragraph>
            <b>6.2 </b>Agendamentos de consultas;
          </S.Paragraph>
          <S.Paragraph>
            <b>6.3 </b>Marketing e propaganda;
          </S.Paragraph>
          <S.Paragraph>
            <b>6.4 </b>Consultas médicas ou outros serviços disponibilizados
            pelos profissionais da área da saúde e afins;
          </S.Paragraph>
          <S.Paragraph>
            <b>6.5 </b>Relatórios financeiros e relatórios estatísticos de
            consultas;
          </S.Paragraph>
          <S.Subtitle>
            7. AGENDAMENTO DE CONSULTAS PELOS USUÁRIOS ATRAVÉS DO APP;
          </S.Subtitle>
          <S.Paragraph>
            <b>7.1 </b>O usuário ou paciente ao efetuar uma solicitação de
            agendamento de consulta através do App, estará autorizando o sistema
            a encaminhar tal solicitação a CONTRATANTE por meio do App
            Clickconsultas.com, simultaneamente será encaminhada a CONTRATADA
            esta solicitação de agendamento para acompanhamento da realização do
            serviço;
          </S.Paragraph>
          <S.Paragraph>
            <b>7.2 </b>A aprovação, cancelamento ou reagendamento de uma
            solicitação de consulta será de responsabilidade da CONTRATANTE que
            informará ao USUÁRIO o resultado da solicitação, após alguns
            minutos;
          </S.Paragraph>
          <S.Subtitle>8. VIGÊNCIA DO CONTRATO OU TERMO DE ADESÃO</S.Subtitle>
          <S.Paragraph>
            <b>8.1 </b>O início da vigência do Contrato ou Termo de Adesão
            celebrado entre CONTRATANTE e CONTRADADA se iniciará em até 7 dias
            úteis após assinatura do contrato ou do “De Acordo” no Termo de
            Adesão;
          </S.Paragraph>
          <S.Paragraph>
            <b>8.2 </b>O início da vigência do Contrato ou Termo de Adesão
            celebrado entre USUÁRIO e App Clickconsultas.com se iniciará
            imediatamente após assinatura do contrato ou do “De Acordo” no Termo
            de Adesão;
          </S.Paragraph>
          <S.Paragraph>
            <b>8.3 </b>Fica resguardado o direito há CONTRATADA em ACEITAR ou
            RECUSAR ou CANCELAR a qualquer momento, solicitações de adesão aos
            serviços oferecidos pelo App Clickconsultas.com, tanto de usuários
            (pacientes), profissionais da área da saúde, empresas, profissionais
            liberais e áreas fins, se assim achar conveniente sem necessidade de
            dar explicações ou aviso prévio;
          </S.Paragraph>
          <S.Paragraph>
            <b>8.4 </b>O App Clickconsultas.com é uma empresa privada que tem o
            direito resguardado em lei para efetuar negócios com quem achar
            conveniente;
          </S.Paragraph>
          <S.Subtitle>
            9. RECISÃO DOS SERVIÇOS NAS SEGUINTES HIPÓTESES:
          </S.Subtitle>
          <S.Paragraph>
            <b>9.1 </b>A qualquer momento pela CONTRATADA em caso de
            inadimplência, risco de imagem, infração às cláusulas e condições
            deste Contrato ou Termo de Adesão independentemente de interpelação
            judicial ou extrajudicial, assim como em caso de pedido de
            recuperação judicial, extrajudicial e/ou falência de qualquer uma
            das partes, sem aviso prévio e sem quaisquer ônus pela CONTRATADA e
            com aviso prévio pela CONTRATANTE formalizado por email à CONTRATADA
            (
            <a href="mailto:contato@clickconsultas.com">
              contato@clickconsultas.com
            </a>
            );
          </S.Paragraph>
          <S.Paragraph>
            <b>9.2 </b>A CONTRATADA reserva para si o direito de restringir,
            cancelar ou suspender por tempo indeterminado este contrato ou Termo
            de Adesão, sem prévia notificação à CONTRATANTE ou USUÁRIO;
          </S.Paragraph>
          <S.Subtitle>10. RESPONSABILIDADE DA CONTRATADA</S.Subtitle>
          <S.Paragraph>
            <b>10.1 </b>Zelar pelo cumprimento e execução dos serviços
            oferecidos;
          </S.Paragraph>
          <S.Paragraph>
            <b>10.2 </b>Dar as devidas informações a CONTRATANTE quando esta
            solicitar;
          </S.Paragraph>
          <S.Subtitle>11. DISPOSIÇÕES GERAIS</S.Subtitle>
          <S.Paragraph>
            <b>11.1 </b>A CONTRATANTE e USUÁRIOS são responsáveis pela
            veracidade das informações pessoais, profissionais, sejam elas quais
            forem, informadas e repassadas a CONTRATADA, bem como pela
            veracidade das informações e qualificações relativas à sua clínica,
            consultório, academia ou escritório, como: nome, razão social, CPF,
            CNPJ, inscrição municipal e estadual, endereço, número de registro
            no Conselho Regional Profissional, qualificações educacionais e
            profissionais, dados postais, telefônicos ou correio eletrônico,
            eximindo a CONTRATADA de qualquer responsabilidade pela inveracidade
            e falta de informações seja na esfera civil ou criminal, sem
            obrigação pela verificação das mesmas;
          </S.Paragraph>
          <S.Paragraph>
            <b>11.2 </b>A inscrição da CONTRATANTE ou USUÁRIO no banco de dados
            do App Clickconsultas ou uma eventual atualização dos dados
            cadastrais, poderá sofrer uma verificação das informações fornecidas
            pelas partes por parte da CONTRATADA caso julgue necessário, podendo
            ser excluído o perfil da CONTRATANTE ou USUÁRIO se assim o App achar
            conveniente;
          </S.Paragraph>
          <S.Paragraph>
            <b>11.3 </b>O App Clickconsultas.com não se responsabiliza pela
            existência ou ilegitimidade dos dados cadastrados pelo usuário no
            App, e nem pela impossibilidade da Contratante em contatar o
            usuário, ou vice e versa, através dos dados pessoais fornecidos no
            cadastro/perfil, sendo que estes dados são inseridos pelo usuário ou
            contratante por sua própria conta e risco;
          </S.Paragraph>
          <S.Paragraph>
            <b>11.4 </b>A CONTRATANTE não poderá atribuir a CONTRATADA qualquer
            responsabilidade, tampouco exigir o pagamento por lucro cessante em
            virtude de prejuízos resultantes de dificuldades técnicas ou falhas
            no sistema ou na internet, caso o sistema ou site da
            Clickconsultas.com não esteja disponível por motivos técnicos ou
            falhas da internet ou por qualquer outro evento de força maior
            alheio ao controle do prestador dos serviços;
          </S.Paragraph>
          <S.Paragraph>
            <b>11.5 </b>A CONTRADA não se responsabiliza pelo mal uso de senhas
            e logins, estes são de uso exclusivo da CONTRATANTE ou USUÁRIOS;
          </S.Paragraph>
          <S.Paragraph>
            <b>11.6 </b>A vinculação de perfil, bem como a inclusão de matérias
            no blog contido no App Clickconsultas.com é feita pela CONTRATANTE
            ou USUÁRIOS de forma independente por meio de funcionalidades
            especiais em sua conta;
          </S.Paragraph>
          <S.Paragraph>
            <b>11.7 </b>A CONTRATADA não se responsabiliza pela veracidade e
            pelo teor dos conteúdos publicados pela CONTRATANTE ou USUÀRIOS do
            App Clickconsultas.com;
          </S.Paragraph>
          <S.Paragraph>
            <b>11.8 </b>A Clickconsultas.com reserva para si o direito de
            “OBJEÇÃO DE CONSCIENCIA”, caso julgue necessário retirar do App
            alguma matéria, serviços oferecidos pela CONTRATANTE ou mensagens
            que não condiz com os princípios da CONTRATADA sem prévia
            notificação à CONTRATANTE e sem quaisquer ônus seja civil ou
            financeiro;
          </S.Paragraph>
          <S.Paragraph>
            <b>11.9 </b>Fica expressamente ciente que o App Clickconsultas.com
            não possui e não tem nenhuma obrigação trabalhista ou previdenciária
            entre parceiros, contratantes, usuários, prepostos, terceiros ou
            qualquer tipo de profissional que tenham seu perfil ou fazem parte
            do App Clickconsultas.com, sendo considerados como legalmente
            independentes da empresa;
          </S.Paragraph>
          <S.Subtitle>12. OBJETIVO DO APP</S.Subtitle>
          <S.Paragraph>
            <b>12.1 </b>O App Clickconsultas.com é um App que tem por objetivo
            facilitar o agendamento de consultas e a localização de
            profissionais e estabelecimentos da área da saúde correlatas e
            afins, facilitando a conectividade entre o usuário previamente
            cadastrado que compõe a base de dados com o prestador de serviço;
          </S.Paragraph>
          <S.Paragraph>
            <b>12.2 </b>A CONTRATADA não é um prestador de serviços de
            consultoria em saúde humana, seja ela: terapêutica, aconselhamento
            médico, odontológico ou qualquer outro tipo de situação que coloque
            o App Clickconsultas.com como responsável pelo diagnóstico ou
            tratamento de qualquer natureza aos usuários dos serviços da base de
            dados que compõe a sua plataforma;
          </S.Paragraph>
          <S.Paragraph>
            <b>12.3 </b>Os serviços prestados por Clickconsultas.com não poderão
            ser utilizados para a solicitação de serviços de atendimento de
            emergência, para pronto-atendimento, para cirurgias
            emergenciais/eletivas ou para exames, não se responsabilizando o
            Clickconsultas.com por quaisquer danos e/ou prejuízos causados aos
            USUÁRIOS do App a terceiros;
          </S.Paragraph>
          <S.Paragraph>
            <b>12.4 </b>O App Clickconsultas.com poderá sofrer alteração a
            qualquer momento, sempre com o objetivo de promover melhorias,
            adicionar ou corrigir recursos do serviço sem aviso prévio;
          </S.Paragraph>
          <S.Subtitle>
            13. PROPRIEDADE INTELECTUAL E POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE
            DADOS PESSOAIS
          </S.Subtitle>
          <S.Paragraph>
            <b>13.1 </b>Ao dar o aceite ao Termo de Adesão ou assinar o Contrato
            Físico a CONTRATANTE ou USUÁRIO conferem ao App Cliconsultas.com
            plenos poderes e direitos para que seus dados pessoais e
            profissionais sejam utilizados efetivamente nas atividades da
            plataforma gratuitamente e sem nenhum custo, desde que a mesma não
            descaracterize finalidade de função, podendo publicar/exibir através
            do App Clickconsultas.com todo tipo de informação como: nome do (a)
            do usuário, especialista, clínica, consultório, CPF, CNPJ, endereço,
            fone, registro do especialista, conselho em que pertence o
            profissional, foto do usuário, especialista, da clínica ou
            consultório para registro no cadastro/perfil (avatar);
          </S.Paragraph>
          <S.Paragraph>
            <b>13.2 </b>Autorizo à AD3Com Comunicação e Design, CNPJ:
            22.602.439/0001-27 o uso de minha imagem em qualquer material, fotos
            e ou documentos a ser publicada no App Clickconsultas.com, sejam
            essas destinadas à divulgação ao público em geral. A presente
            autorização é concedida a título gratuito, abrangendo o uso da
            imagem acima mencionada em todo território nacional e no exterior,
            das seguintes formas: (I) out-door; (II) busdoor; (III) folder de
            apresentação; (IV) anúncios em revistas, jornais, encartes, mala
            direta, catálogo, etc; (V) home page; (VI) cartazes; (VII)
            back-light; (VIII) mídia eletrônica (painéis, internet, vídeo-tapes,
            televisão, cinema, programa para rádio, entre outros). Por esta ser
            a expressão da minha vontade declaro que autorizo o uso acima
            descrito por tempo indeterminado sem que nada haja a ser reclamado a
            título de direitos conexos à minha imagem ou a qualquer outro;
          </S.Paragraph>
          <S.Paragraph>
            <b>13.3 </b>Todos os direitos relativos ao App e às marcas sob a
            titularidade da CONTRATADA suas funcionalidades são de propriedade
            exclusiva do Clickconsultas.com, inclusive no que diz respeito aos
            seus textos, imagens, layouts, base de dados, códigos de
            programação, linguagens informáticas, bases de dados e demais
            conteúdos criados direta ou indiretamente pelo Clickconsultas.com,
            sendo que estes direitos estão protegidos pelas leis e tratados
            internacionais de direito autoral, marcas, patentes, modelos e
            desenhos industriais;
          </S.Paragraph>
          <S.Paragraph>
            <b>13.4 </b>O USUÁRIO ou a CONTRATANTE não adquire, pelo presente
            instrumento ou pela utilização do APLICATIVO, nenhum direito de
            propriedade intelectual ou outros direitos exclusivos, incluindo
            patentes, desenhos, marcas, direitos autorais ou quaisquer direitos
            sobre informações confidenciais ou segredos de negócio, bem como
            todo o conteúdo disponibilizado no App móbile ou Site, incluindo,
            mas não se limitando a textos, gráficos, imagens, logotipos, ícones,
            fotografias, conteúdo editorial, notificações, softwares e qualquer
            outro material, sobre ou relacionados ao App ou nenhuma parte dele;
          </S.Paragraph>
          <S.Paragraph>
            <b>13.5 </b>O uso indevido e a reprodução total ou parcial dos
            referidos direitos de propriedade intelectual são proibidos, salvo
            quando autorizados expressamente pelo Clickconsultas.com;
          </S.Paragraph>
          <S.Paragraph>
            <b>13.6 </b>A CONTRATANTE se compromete a não ceder, sublicenciar,
            vender, doar, alienar, alugar ou transferir, total ou parcialmente,
            sob quaisquer modalidades, a qualquer título, as funcionalidades da
            plataforma que compõem o App, assim como seus manuais, guias,
            procedimentos ou quaisquer outros documentos a eles relacionados.
            Compromete-se, ainda, a não efetuar engenharia reversa, não revelar,
            não duplicar, não copiar, não reproduzir, não autorizar e/ou
            permitir o uso e/ou acesso da Plataforma por terceiros;
          </S.Paragraph>
          <S.Paragraph>
            <b>13.7 </b>Os usuários poderão fornecer dados pessoais de terceiros
            para agendamento de consultas, para que isso aconteça dentro da leis
            estabelecidas nesta Republica Federativa do Brasil, o USUÁRIO deve
            ter todas as autorizações, procurações ou consentimentos do mesmo ou
            da família para utilizar os nossos serviços e se for necessário
            apresentar tais autorizações mediante comprovação;
          </S.Paragraph>
          <S.Paragraph>
            <b>13.8 </b>As consultas efetuadas por videoconferência
            (teleconsultas) quando disponibilizado pelo App Clickconsulatas.com
            poderão ser gravas, ficando exclusivamente aos profissionais da área
            da saúde ou áreas afins a opção ou não pela gravação, caso isso
            ocorra o usuário/paciente será informado para dar o seu “De acordo”
            ou não na continuidade da consulta;
          </S.Paragraph>
          <S.Subtitle>14. A CONTRATANTE OU USUÁRIO SE COMPROMETE:</S.Subtitle>
          <S.Paragraph>
            <b>14.1 </b>Não disponibilizar e/ou lançar no App Clickconsultas.com
            qualquer conteúdo discriminatório, contrário aos bons costumes, que
            contenha imagens ou informações inadequadas ou que incitem a
            violência, pornografia, etc;
          </S.Paragraph>
          <S.Paragraph>
            <b>14.2 </b>A CONTRATANTE ou USUÁRIO reconhecem que, na hipótese de
            qualquer descumprimento das obrigações supracitadas, estarão
            sujeitos as sanções legais cabíveis, todo e qualquer conteúdo, seja
            ele informação profissional e/ou imagem, disponibilizado pela
            CONTRATANTE que contrarie os critérios e política do App
            Clickconsultas.com;
          </S.Paragraph>
          <S.Paragraph>
            <b>14.3 </b>Nada neste Termo de Uso ou Contrato deverá ser
            interpretado como restrição a qualquer direito do Clickconsultas.com
            sobre o App e suas funcionalidades, inclusive, sem limitação, no que
            se refere a seu direito de, a todo e qualquer tempo, desenvolver,
            modificar, explorar comercialmente e licenciar, a quaisquer
            terceiros e sob qualquer modalidade a plataforma do App;
          </S.Paragraph>
          <S.Paragraph>
            <b>14.4 </b>A constatação de qualquer violação a estas regras de
            propriedade intelectual sujeitará o USUÁRIO ou a CONTRATANTE às
            penalidades previstas na Lei e, ainda, na suspensão ou no
            cancelamento dos Serviços e da utilização do App;
          </S.Paragraph>
          <S.Subtitle>15. APLICATIVOS</S.Subtitle>
          <S.Paragraph>
            <b>15.1 </b>A CONTRATANTE ou USUÁRIO aceita e concorda com todas
            condições aplicáveis à retirada e baixa de download e atualização de
            aplicativos pertencentes a CONTRATADA, estabelecidos pelas empresas
            detentora de tecnologia aplicável em aparelhos MOBILE, tais como
            aplicativo iTunes e o sistema operacional ANDROID ou qualquer outro
            sistema;
          </S.Paragraph>
          <S.Paragraph>
            <b>15.2 </b>O dispositivo móvel do usuário poderá receber pedido de
            acesso do aplicativo, sendo facultado ao usuário permitir tal
            acesso. O usuário reconhece e concorda que, se não autorizar o
            acesso à função do dispositivo móvel, o prestador de serviços não
            poderá prestar os serviços de forma satisfatória ou nem mesmo o
            mínimo dos serviços;
          </S.Paragraph>
          <S.Paragraph>
            <b>15.3 </b>Este Termo de adesão ou contrato explicita aos USUÁRIOS
            e a CONTRATANTE que por sua vez reconhecem e concordam, renunciando
            a qualquer direito, reclamação, judicial ou extrajudicial, a
            qualquer falha ou omissão na prestação de serviços decorrente da
            situação acima descrita ou por força maior aqui não mencionada,
            isenta a CONTRATADA de quaisquer danos ou prejuízos que possam ser
            causados por estas situações;
          </S.Paragraph>
          <S.Paragraph>
            <b>15.4 </b>O Clickconsultas.com não garante o pleno funcionamento
            dos serviços prestados e que o aplicativo será sempre seguro, sem
            erros ou sem interrupções;
          </S.Paragraph>
          <S.Paragraph>
            <b>15.5 </b>O Clickconsultas.com concede ao USUÁRIO e a CONTRATANTE
            o direito a utilização dos serviços através de licença limitada, sem
            exclusividade e intransferível para fazer download e instalar o
            aplicativo em dispositivo móbile ou desktop de propriedade do
            usuário;
          </S.Paragraph>
          <S.Subtitle>16. DISPOSIÇÕES FINAIS</S.Subtitle>
          <S.Paragraph>
            <b>16.1 </b>Este Contrato ou Termo de Adesão conjuntamente com os
            termos, políticas, regras e diretrizes publicadas pelo
            Clickconsultas.com no App ou website constituem o acordo integral
            entre as partes e substituem todos os acordos anteriores escritos ou
            orais;
          </S.Paragraph>
          <S.Paragraph>
            <b>16.2 </b>As Partes declaram que têm capacidade plena na esfera
            civil, foram plenamente informadas, com relação aos aspectos legais,
            econômicos, financeiros e fiscais do Instrumento, não observam
            nenhum traço de poder de negociação desigual entre elas e celebram
            este Contrato baseadas nas suas respectivas perspectivas
            econômico-financeiras.
          </S.Paragraph>
          <S.Paragraph>
            <b>16.3 </b>As alterações das regras e normas que contemplam as
            funcionalidades do App descritas neste Termo de Adesão ou Contrato,
            deverá ser comunicada pela CONTRATADA à CONTRATANTE, através de
            notificação no sistema, ou por e-mail para o endereço especificado
            no cadastro com no mínimo 15 (quinze) dias antes da (s) alteração
            (ões) entrarem em vigor;
          </S.Paragraph>
          <S.Paragraph>
            <b>16.4 </b>A utilização do App Clickconsultas.com após a entrada em
            vigor das alterações no Termos de Uso ou Contrato, representa a
            aceitação da (s) alteração (ões) por parte da CONTRATANTE e do
            USUÁRIO sem necessidade de qualquer confirmação adicional a
            CONTRATADA;
          </S.Paragraph>
          <S.Paragraph>
            <b>16.5 </b>A CONTRATANTE ou USUÁRIO declara estar ciente que a
            empresa Clickconsultas.com não assume nenhuma responsabilidade pelo
            roubo de dados tanto pessoais, profissionais, sejam eles quais
            forem, pelo mal uso do App, ataque de Hackers, utilização por
            terceiros, vírus etc;
          </S.Paragraph>
          <S.Paragraph>
            <b>16.6 </b>O email
            <a href="mailto:contato@clickconsultas.com">
              &nbsp;contato@clickconsultas.com&nbsp;
            </a>
            está disponível para sanar quaisquer duvidas, reclamações, sugestões
            ou fazer algum elogio por parte dos usuários ou da CONTRATANTE à
            CONTRATADA;
          </S.Paragraph>
          <S.Paragraph>
            <b>16.7 </b>Para solucionar eventuais controvérsias decorrentes do
            presente contrato, as partes elegem o foro da comarca da cidade de
            LONDRINA/PR, renunciando a qualquer outro, por mais privilegiado que
            seja.
          </S.Paragraph>
        </S.PolicyWrapper>
      </S.PolicyContainer>
    </S.Container>
  );
};

export default PolicyLayout;
