import React from 'react';

import { ActionButton, TermosButton } from '../../../components';

import * as S from './styles';

interface Pdf {
  file_url: string;
  criado_em: Date;
}

interface Props {
  pdf: Pdf;
  toggleModal: (type: string, id?: number | undefined) => void;
}

const ConfigTermos: React.FC<Props> = ({ pdf, toggleModal }) => {
  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Termos e Condições</S.SubTitle>
        <ActionButton
          onClick={() => toggleModal('alterarTermos')}
          value="Alterar Termos"
        />
      </S.SubTitleContainer>
      <S.DetailsContainer>
        <TermosButton
          key={pdf.file_url}
          onClick={() => window.open(pdf.file_url, '_blank')}
          value="Fazer download dos termos"
          criacao={pdf.criado_em}
        />
      </S.DetailsContainer>
    </S.Container>
  );
};

export default ConfigTermos;
