import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const ActionButton: React.FC<I.Props> = ({
  value,
  primary = false,
  loading = false,
  color,
  ...res
}) => {
  return (
    <S.Button color={color} primary={primary} {...res}>
      <S.Load $loading={loading} />
      {loading ? 'Validando...' : value}
    </S.Button>
  );
};

export default ActionButton;
