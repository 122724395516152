import React, { useState } from 'react';
import ReactDom from 'react-dom';
import api from '../../../services/api';

import { InputField, ActionButton } from '../../../components';

import { ModalRemoverEndereco } from '../index';

import * as S from './styles';

import * as I from './interface';

const ModalNovaEspecialidade: React.FC<I.Props> = ({
  id,
  closeModal,
  fetchData,
}) => {
  const [validating, setValidating] = useState<boolean>(false);
  const [step, setStep] = useState(1);

  const [data, setData] = useState<any>({
    especialidade: '',
    aviso: 'nenhum',
    email: '',
  });
  const [error, setError] = useState<any>({
    especialidade: '',
    email: '',
    geral: '',
  });

  const [modals, setModals] = useState<{ [key: string]: any }>({
    confirmarAdicao: {
      state: false,
    },
  });

  const toggleModal = (type: string, modalId?: string) => {
    return modalId
      ? setModals({
          ...modals,
          [type]: { modalId, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  const handleChange = (field: any, value: any) => {
    setData({ ...data, [field]: value });
    setError({ ...error, [field]: '' });
  };

  const handleAccountTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setData!({ ...data, aviso: event.target.value });
  };

  const closeAndFetch = () => {
    closeModal();
    fetchData();
  };

  const confirm = () => {
    setValidating(true);

    if (data.especialidade.trim() === '') {
      setValidating(false);
      return setError((state: any) => ({
        ...state,
        especialidade: 'Esse campo não pode ser vazio!',
      }));
    }

    return api
      .post('/lista-especialidades', {
        especialidade: data.especialidade,
        email: data.email,
        aviso: data.aviso,
      })
      .then(() => {
        setValidating(false);
        setStep(2);
      })
      .catch(() => {
        setValidating(false);
        setError((state: any) => ({
          ...state,
          geral: 'Erro ao inserir especialidade! Tente novamente.',
        }));
      });
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <S.Description>
              Digite o nome da especialidade a ser incluída. Em seguida, você
              pode escolher uma das três opções para avisar às clínicas por
              email que há uma nova especialidade no sistema.
            </S.Description>
            <S.ContentContainer>
              <InputField
                id="especialidade"
                value={data.especialidade}
                onChange={(e) => handleChange('especialidade', e.target.value)}
                placeholder="Digite o nome da nova especialidade..."
                labelText="Nova especialidade"
                width="264px"
                invalid={error.especialidade}
              />

              <S.TypeContainer>
                <S.Description>
                  Selecione o tipo de aviso a ser enviado:
                </S.Description>
                <S.TypeOption>
                  <S.Type
                    type="radio"
                    name="type"
                    value="nenhum"
                    defaultChecked
                    onChange={handleAccountTypeChange}
                  />
                  Não avisar nenhuma clinica sobre a nova especialidade
                  incluída.
                </S.TypeOption>
                <S.TypeOption>
                  <S.Type
                    type="radio"
                    name="type"
                    value="todos"
                    onChange={handleAccountTypeChange}
                  />
                  Avisar TODAS as clínicas cadastradas no sistema sobre a nova
                  especialidade incluída.
                </S.TypeOption>
                <S.TypeOption>
                  <S.Type
                    type="radio"
                    name="type"
                    value="unico"
                    onChange={handleAccountTypeChange}
                  />
                  Avisar apenas UMA clínica sobre a nova especialidade incluída.
                </S.TypeOption>
              </S.TypeContainer>
              {data.aviso === 'unico' && (
                <InputField
                  id="email"
                  value={data.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  placeholder="Digite o email da clínica..."
                  labelText="Email da clínica"
                  width="270px"
                  invalid={error.email}
                />
              )}

              {error.geral.trim() !== '' && <S.Error>{error.geral}</S.Error>}
            </S.ContentContainer>
          </>
        );

      case 2:
        return (
          <S.SuccessMessageContainer>
            <S.Subtitle>Nova especialidade cadastrada com sucesso!</S.Subtitle>
            <S.DescriptionSuccess>
              De agora em diante essa especialidade irá aparecer para os novos
              usuários na etapa de cadastro e também para os especialistas já
              existentes adicionarem em suas informações.
            </S.DescriptionSuccess>
          </S.SuccessMessageContainer>
        );
      default:
        return <h3>Ocorreu um erro! Reinicie a página e tente novamente.</h3>;
    }
  };

  return ReactDom.createPortal(
    <>
      <S.Background
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
        onClick={(e) =>
          e.target === e.currentTarget &&
          (step === 2 ? closeAndFetch() : closeModal())
        }>
        <S.ModalContainer>
          <S.Content>
            <S.Title>Cadastrar nova especialidade</S.Title>

            {renderStep()}
          </S.Content>
          <S.ButtonContainer>
            {step === 1 ? (
              <>
                <ActionButton onClick={closeModal} value="Cancelar" />
                <ActionButton
                  onClick={confirm}
                  value="Adicionar"
                  primary
                  loading={validating}
                />
              </>
            ) : (
              <ActionButton onClick={closeAndFetch} value="Fechar" primary />
            )}
          </S.ButtonContainer>
        </S.ModalContainer>
      </S.Background>
      {modals.confirmarAdicao.state && (
        <ModalRemoverEndereco
          id={id}
          closeModal={() => toggleModal('removerEndereco')}
          closeAll={() => {
            closeModal();
            fetchData();
          }}
        />
      )}
    </>,

    document.getElementById('portal')!,
  );
};

export default ModalNovaEspecialidade;
