import React, { useState } from 'react';

import { ActionButton, SpecialitieButton } from '../../../components';

import * as S from './styles';

interface Especialidade {
  id: number;
  especialidade: string;
  is_pending: boolean;
}

interface Props {
  especialidades: Especialidade[];
  toggleModal: (type: string, id?: number | undefined) => void;
}

const ConfigEspecialidades: React.FC<Props> = ({
  especialidades,
  toggleModal,
}) => {
  const [filter, setFilter] = useState('');
  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Lista de Especialidades</S.SubTitle>
        <ActionButton
          onClick={() => toggleModal('novaEspecialidade')}
          value="Nova Especialidade"
        />
      </S.SubTitleContainer>
      <S.DetailsContainer>
        <S.SearchContainer>
          <S.SearchInput
            value={filter}
            onChange={(v) => setFilter(v.target.value)}
          />
          <S.SearchIcon />
        </S.SearchContainer>

        {especialidades
          .filter((a) =>
            a.especialidade
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                filter
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase(),
              ),
          )
          .map((especialidade) => (
            <SpecialitieButton
              key={especialidade.id}
              is_pending={especialidade.is_pending}
              onClickArrow={() =>
                toggleModal('apagarEspecialidade', +especialidade.id)
              }
              onClickAccept={() =>
                toggleModal('aceitarEspecialidade', +especialidade.id)
              }
              value={`${especialidade.especialidade}`}
            />
          ))}
        {especialidades
          .filter((a) =>
            a.especialidade
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                filter
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase(),
              ),
          )
          .map((especialidade) => (
            <SpecialitieButton
              key={especialidade.id}
              is_pending={especialidade.is_pending}
              onClickArrow={() =>
                toggleModal('alterarEspecialidade', +especialidade.id)
              }
              onClickAccept={() =>
                toggleModal('aceitarEspecialidade', +especialidade.id)
              }
              value={`${especialidade.especialidade}`}
            />
          )).length === 0 && (
          <S.TextNotFound>Nenhuma especialidade encontrada!</S.TextNotFound>
        )}
      </S.DetailsContainer>
    </S.Container>
  );
};

export default ConfigEspecialidades;
