import styled from 'styled-components';

export const Container = styled.div`
  width: 86%;
  height: 100%;
  margin: 7%;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  color: #2fa8d5;
  margin: 0;
`;

export const Description = styled.h4`
  font-size: 14px;
  font-weight: normal;
  margin: 10px 0 16px 0;
`;

export const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TypeOption = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
  font-size: 14px;
`;

export const Type = styled.input`
  margin: 2px 8px 0 0;
`;

export const Subtitle = styled.h3`
  margin: 16px 0 0 0;
  size: 20px;
`;

export const AdressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;

  width: 320px;
  display: flex;
  justify-content: space-between;
`;

export const CpfCnpjContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  height: 44px;
  margin-right: 10px;

  align-self: flex-end;
`;

export const CpfCnpjOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;
