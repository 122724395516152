import React from 'react';
import Select, { components, OptionProps } from 'react-select';

import { Label } from '..';

import * as S from './styles';

import * as I from './interfaces';

type Option = { value: string; label: string };

const SelectMult: React.FC<I.Props> = ({
  id,
  labelText,
  isDisabled,
  options,
  value = [''],
  onChangeValue,
  invalid = '',
}) => {
  const error = invalid.trim() !== '';

  const labelize = (items: string[]) => {
    return items.map((item) => {
      return { value: item, label: item };
    });
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      width: 440,
      minHeight: 42,
      display: 'flex',
      border: error ? '1px solid red' : '1px solid #DEDEDF',
      borderRadius: '4px',
      padding: '0 0 0 8px',
      fontSize: '13px',
      alignItems: 'center',
      backgroundColor: state.isDisabled ? '#efefef4d' : 'white',
      marginBottom: 32,
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2185D0' : 'white',
      fontSize: '13px',
      '&:hover': {
        backgroundColor: !state.isSelected && '#dce6ee',
      },
    }),

    placeholder: (provided: any) => ({
      ...provided,
      color: '#b5b5b5',
    }),
  };

  const Option = ({ innerProps, ...props }: OptionProps<any, any>) => {
    // As funções abaixo causavam sérios problemas de performance no caso de terem diversas opções para o select.
    // O único meio de contornar esse problema foi resetando as funções e mudando o estilo do hover pro CSS do CustomStyles.
    innerProps.onMouseMove = () => {};
    innerProps.onMouseOver = () => {};
    return <components.Option innerProps={innerProps} {...props} />;
  };

  return (
    <S.Container>
      <Label id={id} labelText={labelText} />
      <S.SelectContainer>
        <Select
          isMulti
          styles={customStyles}
          options={labelize(options)}
          value={labelize(value)}
          onChange={(selectedOptions) =>
            selectedOptions
              ? onChangeValue!(
                  (selectedOptions as Option[]).map((option) => option.value),
                )
              : onChangeValue!([])
          }
          components={{ Option }}
          placeholder="Selecione..."
          noOptionsMessage={() => 'Nenhuma informação a ser exibida!'}
          isDisabled={isDisabled}
          maxMenuHeight={120}
        />
        {error && (
          <S.ErrorMessage>
            <S.ErrorIcon />
            <p>{invalid}</p>
          </S.ErrorMessage>
        )}
      </S.SelectContainer>
    </S.Container>
  );
};

export default SelectMult;
