import React, { useState } from 'react';
import api from '../../../services/api';

import {
  InputNumberField,
  SelectOne,
  ActionButton,
  UpdateError,
} from '../../../components';

import { atendimentoValidator } from '../../../utils/detalhesValidator';

import * as S from './styles';

import * as I from './interface';

const DetalhesAtendimento: React.FC<I.Props> = ({
  id,
  dom,
  seg,
  ter,
  qua,
  qui,
  sex,
  sab,
  from,
  to,
  duration,
  readOnly,
  fetch,
}) => {
  const [load, setLoad] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [updateError, setUpdateError] = useState({
    error: false,
    message: '',
  });

  const daysOfWeek = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];

  const [data, setData] = useState<I.Data>({
    id,
    dom,
    seg,
    ter,
    qua,
    qui,
    sex,
    sab,
    from,
    to,
    duration,
  });

  const [errorData, setErrorData] = useState<I.ErrorData>(Object);

  const setOriginalData = () => {
    setData({
      id,
      dom,
      seg,
      ter,
      qua,
      qui,
      sex,
      sab,
      from,
      to,
      duration,
    });
    setErrorData(Object());
    setUpdateError({ error: false, message: '' });
  };

  const save = async () => {
    setLoad(true);
    if (await atendimentoValidator(data!, setErrorData)) {
      api
        .put(`/medicos/atendimento/${id}`, {
          seg: data.seg,
          ter: data.ter,
          qua: data.qua,
          qui: data.qui,
          sex: data.sex,
          sab: data.sab,
          dom: data.dom,
          de_horario: data.from,
          ate_horario: data.to,
          duracao_min: data.duration,
        })
        .then(() => {
          setEditing(false);
          setLoad(false);
          fetch();
        })
        .catch(() => {
          setUpdateError({
            error: true,
            message:
              'Erro na atualização dos dados. Preencha os campos corretamente e tente novamente!',
          });
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  const toggleDay = (day: string) =>
    setData((state) => ({ ...state, [day]: !state[day] }));

  const handleChange = (field: any, value: any) => {
    setErrorData({ ...errorData, [field]: '' });
    setData({ ...data, [field]: value });
    setUpdateError({ error: false, message: '' });
  };

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Title>Atendimento</S.Title>
          <S.Description>
            Abaixo estão os horários de atendimento que estarão disponíveis para
            os pacientes.
          </S.Description>
        </S.TitleContainer>
        {!readOnly && (
          <S.ButtonContainer>
            {!editing ? (
              <ActionButton
                onClick={() => setEditing(true)}
                value="Editar"
                primary
              />
            ) : (
              <>
                {updateError.error && (
                  <UpdateError message={updateError.message} />
                )}
                <ActionButton
                  onClick={() => {
                    setOriginalData();
                    setEditing(false);
                  }}
                  value="Cancelar"
                />
                <ActionButton
                  onClick={save}
                  value="Salvar"
                  primary
                  loading={load}
                />
              </>
            )}
          </S.ButtonContainer>
        )}
      </S.Header>

      <S.Label>Selecione os dias de atendimento:</S.Label>
      <S.WeekButtonContainer>
        {daysOfWeek.map((day) => (
          <S.WeekButton
            key={day}
            active={data[day.toLowerCase()]}
            onClick={() => {
              toggleDay(day.toLowerCase());
            }}
            disabled={!editing}>
            {day}
          </S.WeekButton>
        ))}
      </S.WeekButtonContainer>
      <S.Row>
        <S.Hour>
          <S.Label>Horário:</S.Label>
          <S.Row>
            <S.Label>De</S.Label>
            <InputNumberField
              id="from"
              value={data.from}
              onChange={({ value }) => handleChange('from', value)}
              placeholder="09:00"
              width="64px"
              format="##:##"
              mask="_"
              invalid={errorData.from}
              isDisabled={!editing}
            />
            <S.Label>às</S.Label>
            <InputNumberField
              id="to"
              value={data.to}
              onChange={({ value }) => handleChange('to', value)}
              placeholder="18:00"
              width="64px"
              format="##:##"
              mask="_"
              invalid={errorData.to}
              isDisabled={!editing}
            />
          </S.Row>
        </S.Hour>
        <S.Duration>
          <S.Label>Duração:</S.Label>
          <SelectOne
            id="duration"
            value={data.duration}
            options={['15', '30', '60']}
            onChangeValue={(opt) => handleChange('duration', opt)}
            sufix="minutos"
            width="200px"
            invalid={errorData.duration}
            isDisabled={!editing}
          />
        </S.Duration>
      </S.Row>
    </S.Container>
  );
};

export default DetalhesAtendimento;
