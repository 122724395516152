import React from 'react';

import * as S from './styles';

import * as I from './interface';

const RelatorioTabela: React.FC<I.Props> = ({
  tableRef,
  consultas,
  current,
  maxItensPerPage,
  enablePagination = false,
}) => {
  return (
    <S.Container>
      <S.Table ref={tableRef}>
        <S.THead>
          <S.TRHead>
            <S.TH>Consulta</S.TH>
            <S.TH>Paciente</S.TH>
            <S.TH>Especialista</S.TH>
            <S.TH>Status</S.TH>
            <S.TH>Atendimento</S.TH>
          </S.TRHead>
        </S.THead>
        <S.TBody>
          {consultas
            .slice(
              enablePagination ? (current - 1) * maxItensPerPage : 0,
              enablePagination ? current * maxItensPerPage : undefined,
            )
            .map(
              ({
                id,
                data,
                paciente,
                especialista,
                especialidade,
                status,
                atendimento,
              }) => (
                <S.TRBody key={id} onClick={() => {}}>
                  <S.TD>
                    <S.Bold>
                      {data.format('D')} de{' '}
                      {data.format('MMMM').charAt(0).toUpperCase() +
                        data.format('MMMM').slice(1)}{' '}
                      de {data.format('YYYY')}
                    </S.Bold>
                    <br />
                    {data.format('LT')}
                  </S.TD>
                  <S.TD>{paciente}</S.TD>
                  <S.TD>
                    <S.EspContainer>
                      {/* <S.EspAvatar src={Avatar} /> */}
                      <S.EspDetails>
                        <S.EspNome>{especialista}</S.EspNome>
                        <S.EspFuncao>{especialidade}</S.EspFuncao>
                      </S.EspDetails>
                    </S.EspContainer>
                  </S.TD>
                  <S.TD>
                    <S.StatusContainer>
                      {/* <S.Dot status={status} /> */}
                      {status}
                    </S.StatusContainer>
                  </S.TD>
                  <S.TD>
                    <S.FinalValue>
                      <S.FinalValue>{atendimento}</S.FinalValue>
                    </S.FinalValue>
                  </S.TD>
                </S.TRBody>
              ),
            )}
        </S.TBody>
        <S.TFoot>
          <S.TRHead>
            <S.TH>{consultas.length} resultados</S.TH>
            <S.TH />
            <S.TH />
            <S.TH />
            <S.TH>
              {/* {consultas.length > 0 &&
                consultas
                  .map(({ valorFinal }) => valorFinal)
                  .reduce((a, b) => a + b)
                  .toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  })} */}
            </S.TH>
          </S.TRHead>
        </S.TFoot>
      </S.Table>
    </S.Container>
  );
};

export default RelatorioTabela;
