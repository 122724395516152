import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const ConfigEnderecos: React.FC = () => {
  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Termos e Condições</S.SubTitle>
        <Skeleton width={126} height={38} />
      </S.SubTitleContainer>
      <S.DetailsContainer>
        <Skeleton width={390} height={50} />
        &nbsp;&nbsp;&nbsp;
      </S.DetailsContainer>
    </S.Container>
  );
};

export default ConfigEnderecos;
