import styled from 'styled-components';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

interface Props {
  selected: boolean;
}

interface Disabled {
  disabled: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowLeft = styled(BiChevronLeft)<Disabled>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #707070;
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};
`;

export const ArrowRight = styled(BiChevronRight)<Disabled>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #707070;
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};
`;

export const Number = styled.div<Props>`
  width: 24px;
  height: 24px;
  background-color: ${(props) => (props.selected ? '#DDF1F8' : 'white')};
  color: ${(props) => (props.selected ? 'black' : '#707070')};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 4px;
  user-select: none;
`;
