import { Dispatch, SetStateAction } from 'react';

import { validator } from './validators';

import { Data } from '../domain/Config/ConfigCadastro/interface';

import {
  vazio,
  verificarSenha,
  emailExistente,
  optEmailValido,
  senhaForte,
  senhasCoincidem,
} from './conditions';

export const cadastroValidator = async (
  {
    nomeClinica,
    emailPrincipal,
    emailSecundario,
    senhaAtual,
    novaSenha,
    confirmarSenha,
  }: Data,
  setErrorData: Dispatch<SetStateAction<any>>,
  hasChangedEmail: boolean,
  hasChangedPassword: boolean,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'nomeClinica',
      nomeClinica,
      [vazio],
      ['Digite o nome da clínica!'],
      setErrorData,
    ),
    // isClinica
    //   ? validator(
    //       'cnpj',
    //       cnpj,
    //       [tamanhoMinimo(14), vazio],
    //       ['Preencha o CNPJ corretamente!', 'Digite o CNPJ da clínica!'],
    //       setErrorData,
    //     )
    //   : true,
    // !isClinica
    //   ? validator(
    //       'cpf',
    //       cpf,
    //       [tamanhoMinimo(11), vazio],
    //       ['Preencha o CPF corretamente!', 'Digite o CPF do médico!'],
    //       setErrorData,
    //     )
    //   : true,
    // validator(
    //   'emailPrincipal',
    //   emailPrincipal,
    //   [emailValido, vazio],
    //   [
    //     'O email deve ser válido!',
    //     'O campo de email principal não pode ser vazio!',
    //   ],
    //   setErrorData,
    // ),
    hasChangedEmail
      ? validator(
          'emailSecundario',
          emailSecundario,
          [emailExistente, optEmailValido],
          ['Este email já está em uso!', 'O email deve ser válido!'],
          setErrorData,
          true,
        )
      : true,
    hasChangedPassword
      ? validator(
          'senhaAtual',
          senhaAtual,
          [verificarSenha(emailPrincipal)],
          // [verificarSenha],
          ['Senha atual incorreta!'],
          setErrorData,
          true,
        )
      : true,
    hasChangedPassword
      ? validator(
          'novaSenha',
          novaSenha,
          [senhaForte, vazio],
          [
            'A senha deve conter, no mínimo:\n\t• Um número.\n\t• Uma letra maiúscula.\n\t• Uma letra minúscula.\n\t• Oito caracteres.',
            'A senha não pode ser vazia!',
          ],
          setErrorData,
        )
      : true,
    hasChangedPassword
      ? validator(
          'confirmarSenha',
          confirmarSenha,
          [senhasCoincidem(novaSenha), vazio],
          [
            'As senhas não coincidem!',
            'A confirmação de senha não pode ser vazia!',
          ],
          setErrorData,
        )
      : true,
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};
