import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  // OBS: TIREI O STRICT MODE POR CAUSA DE UM BUG DO REACT-SELECT!! TEMOS QUE VERIFICAR MELHOR O QUE FAZER
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);
