import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';

import Home from '../../pages/Home';
import Detalhes from '../../pages/Detalhes';
import Consultas from '../../pages/Consultas';
import Relatorio from '../../pages/Relatorio';

import Configuracoes from '../../pages/Configuracoes';

import SidebarMenu from '../../domain/Sidebar/SidebarMenu';

import { Container } from './styles';

const NotFound = () => {
  return (
    <>
      <h2 style={{ margin: '30px' }}>
        Página <code>{useLocation().pathname}</code> não encontrada!
        <span role="img" aria-label="emoji triste">
          😢
        </span>
      </h2>
    </>
  );
};

const GerenteLayout: React.FC = () => {
  return (
    <Container>
      <SidebarMenu />
      <Switch>
        <Route exact path="/gerente">
          <Redirect to="/gerente/home" />
        </Route>
        <Route path="/gerente/home" exact component={Home} />
        <Route path="/gerente/consultas" exact component={Consultas} />
        <Route path="/gerente/relatorio" exact component={Relatorio} />
        <Route path="/gerente/config" component={Configuracoes} />
        <Route path="/gerente/detalhes/:id" exact>
          <Detalhes />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Container>
  );
};

export default GerenteLayout;
