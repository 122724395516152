import styled from 'styled-components';

interface StatusProps {
  status: string;
}

export const Container = styled.div`
  width: 100%;
  margin: 16px 0;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const THead = styled.thead`
  text-align: left;
  background-color: #ddf1f8;
`;

export const TRHead = styled.tr`
  width: 100%;
  position: sticky;
  top: 0;
  text-align: left;
  background-color: #ddf1f8;
  z-index: 1;

  th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const TH = styled.th`
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 24px 10px 20px;
  color: #39393a;
`;

export const TD = styled.td`
  font-size: 14px;
  padding: 10px 24px 10px 20px;
`;

export const TBody = styled.tbody`
  page-break-inside: always;
`;

export const TRBody = styled.tr`
  height: 64px;
  position: relative;
  border-bottom: 1px solid lightgray;

  &:hover {
    background-color: #fafafa;
  }
`;

export const TFoot = styled.tfoot`
  height: 44px;
  background-color: #ddf1f8;
  text-align: left;
`;

export const EspContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EspDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EspNome = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const EspFuncao = styled.p`
  font-size: 13px;
  color: #414141;
  margin: 0;
`;

export const EspAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Dot = styled.div<StatusProps>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(props) => {
    switch (props.status) {
      case 'Agendado':
        return '#37D363';
      case 'Solicitado':
        return '#FFA877';
      case 'Cancelado':
        return '#FF8C8C';
      default:
        return 'grey';
    }
  }};
`;

export const Bold = styled.strong``;

export const FinalValue = styled.p`
  font-weight: bold;
`;
