import styled from 'styled-components';
import { FaInfo } from 'react-icons/fa';

export const ErrorIcon = styled(FaInfo)`
  width: 50%;
  height: 50%;
  color: white;
`;

export const ErrorMessage = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 10px;
  left: -16px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  p {
    cursor: default;
    display: none;
    color: #fff;
  }

  &:hover {
    padding: 0 10px;
    width: auto;
    height: 40px;
    border-radius: 4px;
    z-index: 1000;

    ${ErrorIcon} {
      display: none;
    }

    p {
      display: inline;
    }
  }
`;
