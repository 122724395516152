import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const ConfigConselhos: React.FC = () => {
  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Lista de Conselhos</S.SubTitle>
        <Skeleton width={126} height={38} />
      </S.SubTitleContainer>
      <S.DetailsContainer>
        <Skeleton width={340} height={50} />
        &nbsp;&nbsp;&nbsp;
        <Skeleton width={340} height={50} />
        &nbsp;&nbsp;&nbsp;
        <Skeleton width={340} height={50} />
      </S.DetailsContainer>
    </S.Container>
  );
};

export default ConfigConselhos;
