import React, { useState, useEffect } from 'react';

import * as S from './styles';

import * as I from './interfaces';

const Pagination: React.FC<I.Props> = ({
  entriesCounter,
  current,
  maxItensPerPage,
  pageLimit,
  setPagination,
}) => {
  const [pageCount, setPageCount] = useState(1);

  const prevPage = () => {
    setPagination((state: any) => ({ ...state, current: current - 1 }));
  };

  const nextPage = () => {
    setPagination((state: any) => ({ ...state, current: current + 1 }));
  };

  const pages = () => {
    switch (current) {
      case 1:
      case 2:
        return Array.from(Array(pageCount + 1).keys())
          .slice(1)
          .slice(0, pageLimit);
      case pageCount - 1:
      case pageCount:
        return Array.from(Array(pageCount + 1).keys())
          .slice(1)
          .slice(pageCount < pageLimit ? 0 : pageCount - pageLimit);
      default:
        return Array.from(Array(pageCount + 1).keys())
          .slice(1)
          .slice(current - 3, current + 2);
    }
  };

  useEffect(() => {
    setPageCount(Math.ceil(entriesCounter / maxItensPerPage));
  }, [entriesCounter, maxItensPerPage]);

  return (
    <S.Container>
      <S.ArrowLeft
        disabled={current === 1}
        onClick={() => current !== 1 && prevPage()}
      />
      {pages().map((n) => (
        <S.Number
          key={n}
          onClick={() =>
            setPagination((state: any) => ({ ...state, current: n }))
          }
          selected={n === current}>
          {n}
        </S.Number>
      ))}
      <S.ArrowRight
        disabled={current === pageCount}
        onClick={() => current !== pageCount && nextPage()}
      />
    </S.Container>
  );
};

export default Pagination;
