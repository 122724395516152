import { Dispatch, SetStateAction } from 'react';
// import axios from 'axios';

import { emailValido, vazio } from './conditions';

import { validator } from './validators';

import * as I from '../shared/interfaces/loginInterfaces';

export const loginValidator = async (
  { email, senha }: I.Login,
  setErrorData: Dispatch<SetStateAction<I.Login>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;
  await Promise.all([
    validator(
      'email',
      email,
      [emailValido, vazio],
      ['O email deve ser válido!', 'O email não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'senha',
      senha,
      [vazio],
      ['A senha não pode ser vazia!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};
