import React from 'react';
import ReactDom from 'react-dom';
import { ActionButton } from '../../../components';

import Logo from '../../../assets/images/logo.svg';

import * as S from './styles';

interface Props {
  tabela: any;
  type: string;
  filterOptions: any;
  tableRef: React.RefObject<HTMLTableElement>;
  closeModal: () => void;
  handlePrint: () => void;
}

const ModalPrint: React.FC<Props> = ({
  closeModal,
  tabela,
  tableRef,
  handlePrint,
  filterOptions,
  type,
}) => {
  const renderHeader = () => {
    switch (type) {
      case 'relatorio':
        return (
          <>
            <S.Description>Relatório de consultas</S.Description>
            {(filterOptions.data?.label.trim() !== '' ||
              filterOptions.status.length > 0 ||
              filterOptions.especialista.trim() !== '') && (
              <>
                <S.Detail>filtrando por</S.Detail>
                <S.Row>
                  <S.Detail>
                    {filterOptions.data.label.trim() !== '' && (
                      <S.Row>
                        Data: <S.Badge>{filterOptions.data.label}</S.Badge>
                      </S.Row>
                    )}
                  </S.Detail>
                  <S.Detail>
                    {filterOptions.status.length > 0 && (
                      <S.Row>
                        Status:{' '}
                        <S.Badge>{filterOptions.status.join(', ')}</S.Badge>
                      </S.Row>
                    )}
                  </S.Detail>
                  <S.Detail>
                    {filterOptions.especialista.trim() !== '' && (
                      <S.Row>
                        Especialista:{' '}
                        <S.Badge>{filterOptions.especialista}</S.Badge>
                      </S.Row>
                    )}
                  </S.Detail>
                </S.Row>
              </>
            )}
          </>
        );
      case 'historico':
        return (
          <>
            <S.Description>Relatório do histórico de consultas</S.Description>
            {(filterOptions.data?.label.trim() !== '' ||
              filterOptions.especialista.trim() !== '' ||
              filterOptions.status.length > 0 ||
              filterOptions.especialidade.trim() !== '' ||
              filterOptions.cidade.trim() !== '') && (
              <>
                <S.Detail>filtrando por</S.Detail>
                <S.Row>
                  <S.Detail>
                    {filterOptions.data.label.trim() !== '' && (
                      <S.Row>
                        Data: <S.Badge>{filterOptions.data.label}</S.Badge>
                      </S.Row>
                    )}
                  </S.Detail>
                  <S.Detail>
                    {filterOptions.especialista.trim() !== '' && (
                      <S.Row>
                        Especialista:{' '}
                        <S.Badge>{filterOptions.especialista}</S.Badge>
                      </S.Row>
                    )}
                  </S.Detail>
                  <S.Detail>
                    {filterOptions.status.length > 0 && (
                      <S.Row>
                        Status:{' '}
                        <S.Badge>{filterOptions.status.join(', ')}</S.Badge>
                      </S.Row>
                    )}
                  </S.Detail>
                  <S.Detail>
                    {filterOptions.especialidade.trim() !== '' && (
                      <S.Row>
                        Especialidade:{' '}
                        <S.Badge>{filterOptions.especialidade}</S.Badge>
                      </S.Row>
                    )}
                  </S.Detail>
                  <S.Detail>
                    {filterOptions.cidade.trim() !== '' && (
                      <S.Row>
                        Cidade: <S.Badge>{filterOptions.cidade}</S.Badge>
                      </S.Row>
                    )}
                  </S.Detail>
                </S.Row>
              </>
            )}
          </>
        );
      default:
        return <h1>Ocorreu um erro. Atualize a página e tente novamente!</h1>;
    }
  };

  return ReactDom.createPortal(
    <>
      <S.Background
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
        onClick={(e: { target: any; currentTarget: any }) =>
          e.target === e.currentTarget && closeModal()
        }>
        <S.ModalContainer>
          <S.Content>
            <S.Title>Imprimir relatório</S.Title>
            <S.Print ref={tableRef}>
              <S.Header>
                <S.Column>{renderHeader()}</S.Column>
                <S.Logo src={Logo} />
              </S.Header>
              {tabela}
            </S.Print>
          </S.Content>
          <S.ButtonContainer>
            <ActionButton onClick={closeModal} value="Fechar" />
            <ActionButton onClick={handlePrint} value="Imprimir" primary />
          </S.ButtonContainer>
        </S.ModalContainer>
      </S.Background>
    </>,

    document.getElementById('portal')!,
  );
};

export default ModalPrint;
