import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Svg = styled.img`
  width: 100px;
  height: 70px;
`;

export const Title = styled.h1`
  font-size: 16px;
`;

export const Description = styled.p`
  max-width: 220px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
`;

export const Link = styled.a`
  color: #2fa8d5;
  margin-left: 3px;
  text-decoration: none;
`;
