import React, { useState, useEffect } from 'react';
import moment from 'moment';

import * as S from './styles';

const Home: React.FC = () => {
  const [data, setData] = useState({
    username: JSON.parse(sessionStorage.getItem('user')!).nome ?? '',
    date: moment,
  });

  useEffect(() => {
    const interval = setInterval(
      () => setData((state) => ({ ...state, data: moment })),
      1000,
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <S.Container>
      <S.Username>{data.username}</S.Username>
      <S.Time>{data.date().format('kk:mm')}</S.Time>
      <S.Date>{data.date().format('dddd, D [de] MMMM [de] YYYY')}</S.Date>
    </S.Container>
  );
};

export default Home;
