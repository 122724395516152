import styled, { css } from 'styled-components';
import { RiMapPinLine } from 'react-icons/ri';
import { BsSearch } from 'react-icons/bs';
import {
  BiUserPin,
  BiCalendar,
  BiFilter,
  BiChevronDown,
  BiBell,
} from 'react-icons/bi';
import { AiOutlineUser } from 'react-icons/ai';
import { HiOutlineClipboardCheck } from 'react-icons/hi';

interface Active {
  active: boolean;
}

interface Disabled {
  disabled: boolean;
}

interface Request {
  request?: boolean;
}

const iconStyle = css`
  width: 18px;
  height: 18px;
  margin-right: 6px;
  transition: all 0.2s ease-in;
`;

export const Container = styled.div`
  width: calc(100%-64px);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 16px);
  overflow-x: scroll;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Head = styled.div`
  width: calc(100% - 290px);
  position: absolute;
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  padding: 32px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const Main = styled.div`
  overflow: auto;
  margin-top: 216px;
  padding: 0 32px 32px;
`;

export const TitleContainer = styled.div`
  max-width: 600px;
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled(AiOutlineUser)`
  width: 24px;
  height: 24px;
  fill: #2fa8d5;
  margin-right: 8px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 22px;
  color: #2fa8d5;
  margin: 0;
`;

export const Description = styled.p`
  color: #333;
  line-height: 1.3rem;
  font-size: 14px;
`;

export const ButtonContainer = styled.div<Disabled>`
  display: flex;

  button {
    margin-left: 12px;
    opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

export const FilterContainer = styled.div`
  width: calc(100%-40px);
  height: 18px;
  border-radius: 6px;
  border: 1px solid #dfdfdf;
  padding: 20px 20px 20px 8px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  position: relative;

  input {
    padding-left: 36px;
    border: none;
    background-color: #f5f5f5;
  }

  input:focus {
    outline: none;
  }
`;

export const IconSearch = styled(BsSearch)`
  width: 18px;
  height: 18px;
  fill: #11111150;
  position: absolute;
  top: 0;
  left: 12px;
  bottom: 0;
  margin: auto;
`;

export const IconData = styled(BiCalendar)`
  ${iconStyle}
`;

export const IconFilter = styled(BiFilter)`
  ${iconStyle}
`;

export const IconUser = styled(BiUserPin)`
  ${iconStyle}
`;

export const IconLocation = styled(RiMapPinLine)`
  ${iconStyle}
`;

export const IconDown = styled(BiChevronDown)`
  ${iconStyle}
  margin: 0;
`;

export const Filter = styled.div<Active>`
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  font-size: 14px;
  margin-right: 32px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${IconData} {
    fill: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  }
  ${IconFilter} {
    fill: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  }
  ${IconUser} {
    fill: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  }
  ${IconDown} {
    fill: ${({ active }) => (active ? '#2fa8d5' : '#4d4d4d')};
  }
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`;

export const IconNotify = styled(BiBell)`
  width: 18px;
  height: 18px;
  fill: #2a3748;
`;

export const IconResult = styled(HiOutlineClipboardCheck)`
  width: 18px;
  height: 18px;
  stroke: #2a3748;
`;

export const RedDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: #f24822;
  position: absolute;
  top: 0;
  right: 0;
`;

export const SubTitle = styled.p`
  color: #2a3748;
  margin-right: 3px;
`;

export const Result = styled.p`
  font-size: 13px;
  color: #11111170;
`;

export const CardContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }
`;

export const Card = styled.div`
  width: calc(100%-28px);
  height: 32px;
  padding: 14px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const CardSkeleton = styled.div`
  width: calc(100%-28px);
  height: 32px;
  padding: 14px;
  display: flex;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d1d1d1;
  margin-right: 12px;
`;

export const Avatar = styled.img<Request>`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  border: ${({ request }) =>
    request ? ' 1px solid #f24822' : ' 1px solid #d1d1d1'};
`;

export const CardName = styled.p`
  width: 200px;
  height: calc(100%-28px);
  margin-right: 12px;
  font-weight: bold;
  font-size: 14px;
  color: #2a3748;
`;

export const CardInfo = styled.p`
  width: 200px;
  height: calc(100%-28px);
  margin-right: 12px;
  font-weight: normal;
  font-size: 14px;
  color: #2a374880;
`;

export const Status = styled.span<Active>`
  padding: 6px;
  background-color: #4f6787;
  color: #fdfdfd;
  border-radius: 6px;
  font-size: 14px;
`;

export const InitialsContainer = styled.div<Request>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  border: ${({ request }) =>
    request ? ' 1px solid #f24822' : ' 1px solid #d1d1d1'};
`;

export const Initials = styled.span`
  width: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 0;
  color: gray;
`;
