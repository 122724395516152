import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonModalConsulta: React.FC = () => {
  return (
    <S.ModalContainer>
      <S.Content>
        <S.Header>
          <Skeleton width={90} height={20} />
          <br />
          <Skeleton width={200} height={15} />
          <Skeleton width={150} height={15} />
          <br />
          <S.Row>
            <Skeleton width={50} height={15} />
            <S.Status>
              <Skeleton width={93} height={15} />
            </S.Status>
          </S.Row>
        </S.Header>
      </S.Content>

      <S.Details>
        <S.AvatarContainer>
          <Skeleton circle width={41} height={41} />
          <Skeleton width={76} height={15} />
        </S.AvatarContainer>
        <S.Info>
          <Skeleton circle width={24} height={24} />
          <S.PatientDetails>
            <Skeleton width={150} height={15} />

            <S.Group>
              <Skeleton width={150} height={15} />
              <Skeleton width={96} height={15} />
            </S.Group>

            <S.Group>
              <Skeleton width={150} height={15} />
              <Skeleton width={96} height={15} />
            </S.Group>

            <S.Group>
              <Skeleton width={150} height={15} />
              <Skeleton width={96} height={15} />
            </S.Group>
          </S.PatientDetails>
        </S.Info>
      </S.Details>

      <S.Details>
        <S.Info>
          <Skeleton circle width={24} height={24} />
          <S.PatientDetails>
            <Skeleton width={150} height={15} />

            <S.Group>
              <Skeleton width={150} height={15} />
              <Skeleton width={96} height={15} />
            </S.Group>
          </S.PatientDetails>
        </S.Info>
      </S.Details>
      {/* <S.ButtonContainer>
        <ActionButton onClick={closeModal} value="Cancelar" />
        <ActionButton onClick={() => {}} value="Salvar" primary />
      </S.ButtonContainer> */}
    </S.ModalContainer>
  );
};

export default SkeletonModalConsulta;
