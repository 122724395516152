import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 64px);
  height: calc(100% - 64px);
  padding: 32px;
`;

export const TitleContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;

  button {
    margin: 0 0 0 8px;
  }
`;
export const Title = styled.h1`
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  color: #2a3748;
  margin: 0 0 16px 0;
`;

export const Description = styled.h4`
  max-width: 470px;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 32px 0;
  color: rgba(0, 0, 0, 0.6);
`;
export const Subtitle = styled.h3`
  margin: 16px 0 0 0;
`;

export const ContentRow = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  margin-top: 46px;
`;
