import React, { useState } from 'react';
import api from '../../../services/api';

import {
  InputNumberField,
  InputField,
  SelectOne,
  ActionButton,
  UpdateError,
} from '../../../components';

import { DetalhesEspecialidades } from '../index';

import { dadosBasicosValidator } from '../../../utils/detalhesValidator';

import * as S from './styles';

import * as I from './interface';
import ModalAvisoCadastro from '../../Modal/ModalAvisoCadastro';

const DetalhesDadosBasicos: React.FC<I.Props> = ({
  id = '0',
  readOnly,
  avatar,
  conselho,
  estado,
  numeroRegistro,
  nomeCompleto,
  cpfMedico,
  rgMedico,
  emailContato,
  telefoneContato,
  pessoaContato,
  clinica,
  endereco,
  email,
  emailSecundario,
  telefone_cel,
  telefone_fixo,
  conselhosList,
  estadosList,
  fetch,
  especialidades,
  especialidadesList,
}) => {
  const [load, setLoad] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState({
    error: false,
    message: '',
  });
  const [saving, setSaving] = useState<boolean>(false);

  const [imageUpload, setImageUpload] = useState<FormData>(new FormData());
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    message: '',
  });

  const [data, setData] = useState<I.Data>({
    id,
    readOnly,
    avatar,
    conselho,
    estado,
    numeroRegistro,
    nomeCompleto,
    cpfMedico,
    rgMedico,
    emailContato,
    telefoneContato,
    pessoaContato,
    clinica,
    endereco,
    email,
    emailSecundario,
    telefone_cel,
    telefone_fixo,
    especialidades: especialidades.map((e) => ({ ...e })),
  });

  const [errorData, setErrorData] = useState<I.Data>(Object);

  const setOriginalData = () => {
    setData({
      id,
      readOnly,
      avatar,
      conselho,
      estado,
      numeroRegistro,
      nomeCompleto,
      cpfMedico,
      rgMedico,
      emailContato,
      telefoneContato,
      pessoaContato,
      clinica,
      endereco,
      email,
      emailSecundario,
      telefone_cel,
      telefone_fixo,
      especialidades: especialidades.map((e) => ({ ...e })),
    });
    setErrorData(Object());
    setUpdateError({ error: false, message: '' });
  };

  const [modals, setModals] = useState<{ [key: string]: any }>({
    confirmar: {
      state: false,
    },
  });

  const toggleModal = (type: string, modalId?: string) =>
    modalId
      ? setModals({
          ...modals,
          [type]: { modalId, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });

  const save = async () => {
    setLoad(true);
    if (
      (await dadosBasicosValidator(data!, setErrorData)) &&
      !imageUploadError.error
    ) {
      const form = imageUpload;

      if (form?.has('file')) {
        if (form?.has('id_medico')) form!.delete('id_medico');
        form?.append('id_medico', data.id);
        await api.put(`/avatar`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      api
        .put(`/medicos/dados-basicos/${id}`, {
          conselho: data.conselho,
          estado_conselho: data.estado,
          registro: data.numeroRegistro,
          nome_completo: data.nomeCompleto,
          cpf: data.cpfMedico,
          rg: data.rgMedico,
          email_contato: data.emailContato,
          telefone_contato: data.telefoneContato,
          pessoa_contato: data.pessoaContato,
        })
        .then(() => {
          setSaving(true);
          fetch();
        })
        .catch(() => {
          setUpdateError({
            error: true,
            message:
              'Erro na atualização dos dados. Preencha os campos corretamente e tente novamente!',
          });
          setLoad(false);
          // Erro
        });
    } else {
      setLoad(false);
    }
  };

  const handleChange = (field: any, value: any, regex?: any) => {
    if (regex && !value.match(regex)) return;
    setErrorData({ ...errorData, [field]: '' });
    setData!({ ...data!, [field]: value });
    setUpdateError({ error: false, message: '' });
  };

  const handleChangePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target !== null) {
      const { files } = e.target;
      const form = new FormData();

      if (files!.length === 0) return;
      if (files![0].size > 1 * 1024 * 1024) {
        setImageUploadError({
          error: true,
          message: 'O arquivo escolhido ultrapassa o tamanho máximo de 5mb',
        });
        return;
      }
      if (files![0])
        setData((state) => ({
          ...state,
          avatar: URL.createObjectURL(files![0]),
        }));
      setImageUploadError({ error: false, message: '' });
      form.append('file', files![0]);
      setImageUpload(form);
    }
  };

  const getInitials = (name: string) => {
    const initials = name
      .split(' ')
      .map((n) => n[0])
      .filter((w) => w !== undefined);
    if (!initials[0]) return '';
    if (!initials[1]) return initials[0].toUpperCase();
    return (
      initials[0].toUpperCase() + initials[initials.length - 1].toUpperCase()
    );
  };

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Title>Equipe Médica</S.Title>
          <S.Description>
            Visualize todos os dados básicos deste médico.
          </S.Description>
        </S.TitleContainer>
        {!readOnly && (
          <S.ButtonContainer>
            {!editing ? (
              <ActionButton
                onClick={() => setEditing(true)}
                value="Editar"
                primary
              />
            ) : (
              <>
                {updateError.error && (
                  <UpdateError message={updateError.message} />
                )}
                <ActionButton
                  onClick={() => {
                    setOriginalData();
                    setEditing(false);
                  }}
                  value="Cancelar"
                />
                <ActionButton
                  onClick={() => toggleModal('confirmar')}
                  value="Salvar"
                  primary
                  loading={load}
                />
              </>
            )}
          </S.ButtonContainer>
        )}
      </S.Header>

      <S.AvatarContainer>
        <S.PhotoContainer disabled={!editing}>
          <>
            {data.avatar.trim() !== '' ? (
              <S.Photo src={data.avatar} />
            ) : (
              <S.Initials>{getInitials(data.nomeCompleto)}</S.Initials>
            )}
            <S.UpdateMessage>Clique para alterar</S.UpdateMessage>
          </>
          <S.Input
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleChangePicture}
            disabled={!editing}
          />
          {imageUploadError.error && (
            <S.ErrorMessage>
              <S.ErrorIcon />
              <p>{imageUploadError.message}</p>
            </S.ErrorMessage>
          )}
        </S.PhotoContainer>
        {editing && (
          <S.PhotoInfoContainer>
            <p>Resolução Sugerida: 1080x1080</p>
            <p>Tamanho Máximo: 5MB</p>
          </S.PhotoInfoContainer>
        )}
      </S.AvatarContainer>

      <S.DetailContainer>
        <S.SideContainer>
          <S.DetailRow>
            <InputField
              id="nomeCompleto"
              value={data.nomeCompleto}
              onChange={(e) => handleChange('nomeCompleto', e.target.value)}
              placeholder="John Smith Doe"
              labelText="Nome completo (como consta no conselho)"
              width="390px"
              invalid={errorData.nomeCompleto}
              isDisabled={!editing}
            />
          </S.DetailRow>

          <S.DetailRow>
            <SelectOne
              id="conselho"
              value={data.conselho}
              onChangeValue={(opt) => handleChange('conselho', opt)}
              labelText="Conselho"
              width="150px"
              options={conselhosList ?? []}
              invalid={errorData.conselho}
              isDisabled={!editing}
            />
            <InputNumberField
              id="numeroRegistro"
              value={data.numeroRegistro}
              onChange={({ value }) => handleChange('numeroRegistro', value)}
              placeholder="00000000"
              labelText="Número de registro"
              width="100px"
              invalid={errorData.numeroRegistro}
              isDisabled={!editing}
            />

            <SelectOne
              id="estado"
              value={data.estado}
              onChangeValue={(opt) => handleChange('estado', opt)}
              labelText="Estado"
              width="85px"
              options={estadosList ?? []}
              invalid={errorData.estado}
              isDisabled={!editing}
            />
          </S.DetailRow>

          <S.DetailRow>
            <DetalhesEspecialidades
              id={data.id}
              especialidades={data.especialidades}
              especialidadesList={especialidadesList}
              fetch={fetch}
              edit={editing}
              saving={saving}
              setSaving={setSaving}
              setEdit={setEditing}
              setLoading={setLoad}
            />
          </S.DetailRow>
          <S.DetailRow>
            <InputNumberField
              id="cpfMedico"
              value={data.cpfMedico}
              onChange={({ value }) => handleChange('cpfMedico', value)}
              placeholder="000.000.000-00"
              labelText="CPF"
              width="170px"
              format="###.###.###-##"
              mask="_"
              invalid={errorData.cpfMedico}
              isDisabled={!editing}
            />

            <InputNumberField
              id="rgMedico"
              value={data.rgMedico}
              onChange={({ value }) => handleChange('rgMedico', value)}
              placeholder="00.000.000-0"
              labelText="RG"
              width="170px"
              format="##.###.###-#"
              mask="_"
              invalid={errorData.rgMedico}
              isDisabled={!editing}
            />
          </S.DetailRow>

          <S.DetailRow>
            <InputField
              id="pessoaContato"
              value={data.pessoaContato}
              onChange={(e) => handleChange('pessoaContato', e.target.value)}
              placeholder="Jane Smith Doe"
              labelText="Pessoa de Contato"
              width="390px"
              invalid={errorData.pessoaContato}
              isDisabled={!editing}
            />
          </S.DetailRow>

          <S.DetailRow>
            <InputField
              id="emailContato"
              value={data.emailContato}
              onChange={(e) => handleChange('emailContato', e.target.value)}
              placeholder="email@dominio.com"
              labelText="E-mail de contato"
              width="230px"
              invalid={errorData.emailContato}
              isDisabled={!editing}
            />

            <InputNumberField
              id="telefoneContato"
              value={data.telefoneContato}
              onChange={({ value }) => handleChange('telefoneContato', value)}
              placeholder="(00) 00000-0000"
              labelText="Celular de contato"
              width="110px"
              format="(##) #####-####"
              mask="_"
              invalid={errorData.telefoneContato}
              isDisabled={!editing}
            />
          </S.DetailRow>
        </S.SideContainer>

        <S.SideContainer>
          {/*
          <S.DetailRow>
            <InputField
              id="clinica"
              value={data.clinica}
              onChange={(e) => handleChange('clinica', e.target.value)}
              placeholder=""
              labelText="Nome da clínica"
              width="329px"
              isDisabled
            />
          </S.DetailRow>

          <S.DetailRow>
            <InputField
              id="endereco"
              value={data.endereco}
              onChange={(e) => handleChange('endereco', e.target.value)}
              placeholder=""
              labelText="Endereço"
              width="329px"
              isDisabled
            />
          </S.DetailRow>

          <S.DetailRow>
            <InputField
              id="email"
              value={data.email}
              onChange={(e) => handleChange('email', e.target.value)}
              placeholder="email@dominio.com"
              labelText="E-mail"
              width="180px"
              isDisabled
            />

            <InputNumberField
              id="telefone_fixo"
              value={data.telefone_fixo}
              onChange={({ value }) => handleChange('telefone_fixo', value)}
              placeholder="(00) 0000-0000"
              labelText="Telefone de contato"
              width="100px"
              format="(##) ####-####"
              isDisabled
            />
          </S.DetailRow>

          <S.DetailRow>
            <InputField
              id="emailSecundario"
              value={data.emailSecundario}
              onChange={(e) => handleChange('emailSecundario', e.target.value)}
              placeholder="email@dominio.com"
              labelText="E-mail secundário"
              width="170px"
              isDisabled
            />

            <InputNumberField
              id="telefone_cel"
              value={data.telefone_cel}
              onChange={({ value }) => handleChange('telefone_cel', value)}
              placeholder="(00) 00000-0000"
              labelText="Celular de contato"
              width="110px"
              format="(##) #####-####"
              isDisabled
            />
          </S.DetailRow>
          */}
        </S.SideContainer>
      </S.DetailContainer>

      {modals.confirmar.state && (
        <ModalAvisoCadastro
          closeModal={() => toggleModal('confirmar')}
          save={save}
        />
      )}
    </S.Container>
  );
};

export default DetalhesDadosBasicos;
