import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import moment from 'moment';

import { ActionButton, InputDateField } from '../../../components';

import * as S from './styles';

import * as I from './interface';

const ModalData: React.FC<I.Props> = ({
  top,
  left,
  closeModal,
  filterOptions,
  onlyAtThisMoment = false,
  setFilterOptions,
}) => {
  const [date, setDate] = useState({
    label: '',
    de: { value: '', error: '' },
    ate: { value: '', error: '' },
  });

  const handleChange = (key: any, value: any) => {
    setDate((state) => ({
      ...state,
      [key]: { value, error: '' },
    }));
  };

  const handleToday = () => {
    const today = moment().clone();
    setFilterOptions((state: any) => ({
      ...state,
      data: {
        label: 'Hoje',
        de: today.format('DDMMYYYY'),
        ate: today.format('DDMMYYYY'),
      },
    }));
    closeModal();
  };

  const handleTomorrow = () => {
    const tomorrow = moment().clone().add(1, 'd');
    setFilterOptions((state: any) => ({
      ...state,
      data: {
        label: 'Amanhã',
        de: tomorrow.format('DDMMYYYY'),
        ate: tomorrow.format('DDMMYYYY'),
      },
    }));
    closeModal();
  };

  const handleThisMonth = () => {
    const firstDayOfCurrentMonth = moment().clone().startOf('month');
    const lastDayOfCurrentMonth = moment().clone().endOf('month');
    setFilterOptions((state: any) => ({
      ...state,
      data: {
        label: 'Este mês',
        de: firstDayOfCurrentMonth.format('DDMMYYYY'),
        ate: lastDayOfCurrentMonth.format('DDMMYYYY'),
      },
    }));
    closeModal();
  };

  const apply = () => {
    const isValid = [];
    if (
      moment(date.ate.value, 'DDMMYYYY').isSameOrAfter(
        moment(date.de.value, 'DDMMYYYY'),
      )
    ) {
      isValid.push(true);
    } else {
      setDate((state) => ({
        ...state,
        ate: { ...state.ate, error: 'Data anterior à inicial!' },
      }));
      isValid.push(false);
    }
    if (moment(date.de.value, 'DDMMYYYY').isValid()) {
      isValid.push(true);
    } else {
      setDate((state) => ({
        ...state,
        de: { ...state.de, error: 'Data inválida!' },
      }));
      isValid.push(false);
    }
    if (moment(date.ate.value, 'DDMMYYYY').isValid()) {
      isValid.push(true);
    } else {
      setDate((state) => ({
        ...state,
        ate: { ...state.ate, error: 'Data inválida!' },
      }));
      isValid.push(false);
    }
    if (isValid.every((e: boolean) => e === true)) {
      setFilterOptions((state: any) => ({
        ...state,
        data: {
          label: `${moment(date.de.value, 'DDMMYYYY')
            .clone()
            .format('L')} - ${moment(date.ate.value, 'DDMMYYYY')
            .clone()
            .format('L')}`,
          de: date.de.value,
          ate: date.ate.value,
        },
      }));
      closeModal();
    }
  };

  const clear = () => {
    setFilterOptions((state: any) => ({
      ...state,
      data: {
        label: '',
        de: '',
        ate: '',
      },
    }));
    closeModal();
  };

  useEffect(() => {
    setDate({
      label: filterOptions.data.label,
      de: { value: filterOptions.data.de, error: '' },
      ate: { value: filterOptions.data.ate, error: '' },
    });
  }, [filterOptions.data.label, filterOptions.data.ate, filterOptions.data.de]);
  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer top={top} left={left}>
        <S.FieldsContainer>
          <S.Field>
            <S.Label>De</S.Label>
            <InputDateField
              id="de"
              width="102px"
              value={date.de.value}
              onChange={({ value }) => handleChange('de', value)}
              placeholder="00/00/0000"
              format="##/##/####"
              mask="_"
              invalid={date.de.error}
            />
          </S.Field>
          <S.Line />
          <S.Field>
            <S.Label>Até</S.Label>
            <InputDateField
              id="ate"
              width="102px"
              value={date.ate.value}
              onChange={({ value }) => handleChange('ate', value)}
              placeholder="00/00/0000"
              format="##/##/####"
              mask="_"
              invalid={date.ate.error}
            />
          </S.Field>
        </S.FieldsContainer>
        <S.OptionButton onClick={handleToday}>Hoje</S.OptionButton>
        {!onlyAtThisMoment && (
          <S.OptionButton onClick={handleTomorrow}>Amanhã</S.OptionButton>
        )}
        <S.OptionButton onClick={handleThisMonth}>Este Mês</S.OptionButton>
        <ActionButton onClick={apply} primary value="Aplicar" />

        <S.Clear>
          <S.Link onClick={clear}>Limpar</S.Link>
        </S.Clear>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalData;
