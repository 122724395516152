import styled from 'styled-components';
import { AiFillMedicineBox } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';

export const IconArrow = styled(RiDeleteBinLine)`
  width: 16px;
  height: 16px;
  fill: red;
  opacity: 0;
  cursor: pointer;
`;

export const Button = styled.button`
  width: 100%;
  height: 56px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #11111115;
  margin: 0;
  cursor: default;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 14px;
  margin-bottom: 16px;

  &:hover {
    background-color: #f3f3f310;

    ${IconArrow} {
      opacity: 0.6;
    }
  }

  &:active {
    background-color: #f3f3f320;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 0;
`;

export const Text = styled.p`
  text-overflow: ellipsis;
`;

export const Icon = styled(AiFillMedicineBox)`
  width: 20px;
  height: 20px;
  fill: #2fa8d5;
  margin-right: 10px;
`;
