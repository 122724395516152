import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const ImageContainer = styled.div`
  width: 50vw;
  height: 100vh;
  overflow: hidden;
`;

export const BackgroundContainer = styled.div`
  width: 50vw;
  height: 100vh;
  position: relative;
`;

export const Background = styled.img`
  width: 200%;
  height: 200%;
  position: absolute;
  left: -50%;
  top: 15%;
`;

export const Logo = styled.img`
  width: 222px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ContentBlock = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.05);
  z-index: 2;

  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;

  color: #2a3748;
`;

export const PrimaryMessage = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
`;

export const SecondaryMessage = styled.h4`
  font-size: 14px;
  font-weight: 400;
  margin: 25px 0 32px 0;
  opacity: 0.6;
`;

export const FooterMessage = styled(SecondaryMessage)`
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FormContainer = styled.form`
  margin-right: -16px;
`;

export const LineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 16px 0 0;
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  height: 18px;
  padding-left: 24px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.8);
`;

export const InputCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  & ~ span {
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }

  &:checked ~ span {
    background: #2185d0;
    border: 1px solid #2185d0;
  }

  &:checked ~ span:after {
    display: block;
    width: 20%;
    height: 60%;
  }
`;

export const Checkbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #dedede;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;

    left: 5px;
    top: 0.5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const LinkComponent = styled(Link)`
  cursor: pointer;
  text-align: center;
  align-items: center;

  font-family: 'Lato';
  font-size: 14px;
  font-weight: 500;
  color: #2185d0;
  font-weight: 500;
  text-decoration-line: none;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 1rem 0 0;
`;
