import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  button {
    font-family: 'Lato', sans-serif;

  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: white;
    border-radius: 10px;

  }

  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {

    background: gray;
  }
`;
