import { useRef, useEffect } from 'react';
/**
 * Um useEffect customizado que só é acionado quando o componente atualiza, e não na primeira renderização
 * Baseado em: https://stackoverflow.com/a/55075818/1526448
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
const useUpdateEffect = (
  effect: () => any,
  dependencies: Array<any> = [],
): void => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useUpdateEffect;
