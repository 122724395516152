import { Dispatch, SetStateAction } from 'react';

import { validator, espValidator } from './validators';

import {
  vazio,
  horarioValido,
  conveniosVazios,
  tamanhoMinimo,
  emailValido,
  testaCPF,
} from './conditions';

import { Data as DadosBasicos } from '../domain/Detalhes/DetalhesDadosBasicos/interface';
import { Data as Atendimento } from '../domain/Detalhes/DetalhesAtendimento/interface';
import { Data as Especialidades } from '../domain/Detalhes/DetalhesEspecialidades/interface';
import { Data as Convenios } from '../domain/Detalhes/DetalhesConvenios/interface';

export const dadosBasicosValidator = async (
  {
    conselho,
    estado,
    numeroRegistro,
    nomeCompleto,
    cpfMedico,
    rgMedico,
    emailContato,
    telefoneContato,
  }: DadosBasicos,
  setErrorData: Dispatch<SetStateAction<any>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'conselho',
      conselho,
      [vazio],
      ['O conselho não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'estado',
      estado,
      [vazio],
      ['O estado horário não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'numeroRegistro',
      numeroRegistro,
      [vazio],
      ['O número de registro não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'nomeCompleto',
      nomeCompleto,
      [vazio],
      ['O nome completo não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'rgMedico',
      rgMedico,
      [tamanhoMinimo(9), vazio],
      ['Preencha o RG corretamente!', 'Digite o RG do médico!'],
      setErrorData,
    ),
    validator(
      'cpfMedico',
      cpfMedico,
      [testaCPF, tamanhoMinimo(11), vazio],
      [
        'CPF inválido!',
        'Preencha o CPF corretamente!',
        'Digite o CPF do médico!',
      ],
      setErrorData,
    ),
    validator(
      'emailContato',
      emailContato,
      [emailValido, vazio],
      [
        'O email deve ser válido!',
        'O campo de email de contato não pode ser vazio!',
      ],
      setErrorData,
    ),
    validator(
      'telefoneContato',
      telefoneContato,
      [tamanhoMinimo(10), vazio],
      ['Preencha o telefone corretamente!', 'O telefone não pode ser vazio!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const atendimentoValidator = async (
  { from, to, duration }: Atendimento,
  setErrorData: Dispatch<SetStateAction<any>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'from',
      from,
      [horarioValido, vazio],
      ['Horário inválido!', 'O horário inicial não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'to',
      to,
      [horarioValido, vazio],
      ['Horário inválido', 'O horário final não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'duration',
      duration,
      [vazio],
      ['A duração deve ser escolhida!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const especialidadeValidator = async (
  { especialidades }: Especialidades,
  setErrorData: Dispatch<SetStateAction<any>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  const validArray: any[] = [];

  especialidades.forEach(async (especialidade, index) => {
    validArray.push(
      ...[
        espValidator(
          'especialidade',
          especialidade.especialidade,
          index,
          [vazio],
          ['Escolha uma especialidade!'],
          setErrorData,
        ),
        espValidator(
          // validar com o back
          'rqe',
          especialidade.rqe,
          index,
          [vazio],
          ["O 'RQE' não pode ser vazio!"],
          setErrorData,
          true,
        ),
      ],
    );
  });

  await Promise.all(validArray).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};

export const conveniosValidator = async (
  { convenios, temConvenio, valor }: Convenios,
  setErrorData: Dispatch<SetStateAction<any>>,
): Promise<boolean> => {
  /*
  Aqui começam as validações para cada campo utilizando as funções importadas de 'condições.ts'.
  A variável 'valid' é modificada logo após as promises serem cumpridas, e caso todas as promises
  resultarem em 'true', é porque todas as restrições foram respeitadas, ou seja, o formulário é valido.
  */
  let valid = false;

  await Promise.all([
    validator(
      'valor',
      valor,
      [vazio],
      ['O valor não pode ser vazio!'],
      setErrorData,
    ),
    validator(
      'convenios',
      convenios,
      [conveniosVazios(temConvenio)],
      ['Escolha pelo menos um convênio!'],
      setErrorData,
    ),
  ]).then((res) => {
    valid = res.every((e: boolean) => e === true);
  });

  return valid;
};
