import React, { useState } from 'react';
import ReactDom from 'react-dom';
import api from '../../../services/api';

import { ActionButton } from '../../../components';

import { ModalRemoverEndereco } from '../index';

import * as S from './styles';

import * as I from './interface';

const ModalAlterarTermos: React.FC<I.Props> = ({
  id,
  closeModal,
  fetchData,
}) => {
  const [validating, setValidating] = useState<boolean>(false);
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState<any>({});
  const [error, setError] = useState<any>('');
  const [pdf, setPdf] = useState<any>({});

  const [modals, setModals] = useState<{ [key: string]: any }>({
    confirmarAlteracao: {
      state: false,
    },
  });

  const toggleModal = (type: string, modalId?: string) => {
    return modalId
      ? setModals({
          ...modals,
          [type]: { modalId, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  const closeAndFetch = () => {
    closeModal();
    fetchData();
  };

  const confirm = () => {
    setValidating(true);

    if (!pdf.file) {
      setError('Nenhum arquivo selecionado!');
      return setValidating(false);
    }

    return api
      .put('/pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        setValidating(false);
        setStep(2);
      })
      .catch(() => {
        setValidating(false);
        setError(
          'Ocorreu um erro no envio do PDF. Tente novamente mais tarde.',
        );
      });
  };

  const handleChange = (e: any) => {
    if (e.target !== null) {
      const { files } = e.target;
      const form = new FormData();
      if (files!.length === 0) return;
      if (files![0].size > 5 * 1024 * 1024) {
        setError('O arquivo escolhido ultrapassa o tamanho máximo de 5mb');
        return;
      }
      if (files![0])
        setPdf!({
          file: files![0],
          url: URL.createObjectURL(files![0]),
        });
      setError('');
      form.append('file', files![0]);
      setFormData(form);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <S.Description>
              Clique na área em destaque abaixo e escolha um arquivo para
              carregar os novos termos e condições (no formato PDF).
            </S.Description>
            <S.ContentContainer>
              <S.Label>
                {pdf.file ? pdf.file.name : 'Clique ou arraste o PDF aqui!'}
                <S.Input
                  type="file"
                  accept="application/pdf"
                  onChange={handleChange}
                />
              </S.Label>

              {error.trim() !== '' && <S.Error>{error}</S.Error>}
            </S.ContentContainer>
          </>
        );

      case 2:
        return (
          <S.SuccessMessageContainer>
            <S.Subtitle>Termos e condições alterados com sucesso!</S.Subtitle>
            <S.Description>
              De agora em diante esses serão os termos que irão aparecer para os
              novos usuários na etapa de cadastro. Você poderá fazer o download
              desses termos no painel de configurações.
            </S.Description>
          </S.SuccessMessageContainer>
        );
      default:
        return <h3>Ocorreu um erro! Reinicie a página e tente novamente.</h3>;
    }
  };

  return ReactDom.createPortal(
    <>
      <S.Background
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
        onClick={(e) =>
          e.target === e.currentTarget &&
          (step === 2 ? closeAndFetch() : closeModal())
        }>
        <S.ModalContainer>
          <S.Content>
            <S.Title>Alterar Termos e Condições</S.Title>

            {renderStep()}
          </S.Content>
          <S.ButtonContainer>
            {step === 1 ? (
              <>
                <ActionButton onClick={closeModal} value="Cancelar" />
                <ActionButton
                  onClick={confirm}
                  value="Enviar"
                  primary
                  loading={validating}
                />
              </>
            ) : (
              <ActionButton onClick={closeAndFetch} value="Fechar" primary />
            )}
          </S.ButtonContainer>
        </S.ModalContainer>
      </S.Background>
      {modals.confirmarAlteracao.state && (
        <ModalRemoverEndereco
          id={id}
          closeModal={() => toggleModal('removerEndereco')}
          closeAll={() => {
            closeModal();
            fetchData();
          }}
        />
      )}
    </>,

    document.getElementById('portal')!,
  );
};

export default ModalAlterarTermos;
