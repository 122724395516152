import styled from 'styled-components';
import { motion } from 'framer-motion';

interface Primary {
  primary?: boolean;
}

export const Background = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: #2a374880;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  width: 264px;
  height: fit-content;
  max-height: 80vh;
  background-color: white;
  position: absolute;
  border-radius: 4px;
  font-size: 14px;
`;

export const Content = styled.div`
  max-height: 70vh;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 204px;
  height: fit-content;
  padding: 30px 30px 0 30px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: #2fa8d5;
  padding: 0;
  margin: 0 0 16px 0;
`;

export const Text = styled.p`
  max-width: 200px;
  opacity: 0.7;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Details = styled.div`
  width: calc(100% - 60px);
  height: fit-content;
  padding: 0 30px 22px 30px;
  display: flex;
`;

export const LinkContainer = styled.div`
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 30px 30px 30px;
`;

export const Link = styled.a<Primary>`
  text-decoration: none;
  font-weight: bold;
  color: ${({ primary }) => (primary ? '#2fa8d5' : 'rgba(0,0,0,0.7)')};
  margin-left: ${({ primary }) => (primary ? '32px' : '0')};
  cursor: pointer;
`;
