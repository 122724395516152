import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const AddButton: React.FC<I.Props> = ({ text, ...rest }) => {
  return (
    <S.Container {...rest}>
      <S.AddIcon />
      <S.TextContent>{text}</S.TextContent>
    </S.Container>
  );
};

export default AddButton;
