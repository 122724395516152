import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const UpdateError: React.FC<I.Props> = ({ message }) => {
  return (
    <S.ErrorMessage>
      <S.ErrorIcon />
      <p>{message}</p>
    </S.ErrorMessage>
  );
};

export default UpdateError;
