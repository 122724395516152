import styled from 'styled-components';
import { motion } from 'framer-motion';

interface ContainerPos {
  top: number;
  left: number;
}

interface Selected {
  selected: boolean;
}

export const Background = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const ModalContainer = styled.div<ContainerPos>`
  width: 190px;
  height: fit-content;
  max-height: 80vh;
  background-color: white;
  position: absolute;
  border-radius: 4px;
  top: ${({ top }) => `${top + 38}px`};
  left: ${({ left }) => `${left}px`};
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.14);
  padding: 16px;
`;

export const Label = styled.p`
  margin: 0 0 6px 0;
  font-size: 14px;
`;

export const Clear = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Link = styled.a`
  color: #1a1a1a;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  margin-top: 12px;
`;

export const OptionButton = styled.button`
  width: 100%;
  height: 34px;

  display: flex;
  align-items: center;

  border: 1px solid #e7e7e7;
  border-radius: 4px;
  background-color: white;

  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);

  cursor: pointer;
  margin-bottom: 6px;
`;

export const Square = styled.div<Selected>`
  width: 6px;
  height: 6px;
  border-radius: 1px;
  -moz-outline-radius: 4px;
  background-color: ${({ selected }) => (selected ? '#2fa8d5' : 'white')};
  margin: 0 8px 0 4px;
  border: 2px solid white;
  box-shadow: 0 0 0 2px #2fa8d5;
`;
