import styled from 'styled-components';
import { BiCalendar } from 'react-icons/bi';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  height: calc(100vh - 32px);
  overflow: scroll;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  max-width: 600px;
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled(BiCalendar)`
  width: 24px;
  height: 24px;
  stroke: #2fa8d5;
  fill: #2fa8d5;
  margin-right: 8px;
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  color: #2fa8d5;
`;

export const Description = styled.p`
  color: #333;
  font-size: 14px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-right: -16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 16px;
`;
