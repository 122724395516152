import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #f6f6f6;
  box-sizing: border-box;
`;

export const Header = styled.div`
  box-sizing: border-box;

  width: 100%;
  padding: 24px;
  background-color: #212c3b;

  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

export const PolicyContainer = styled.div`
  width: 100%;

  overflow-y: auto;

  &::-webkit-scrollbar-track {
    margin: 5px 0;

    background-color: #f6f6f6;
  }
`;

export const PolicyWrapper = styled.div`
  margin: 60px auto;
  padding: 0 30px;
  max-width: 900px;
`;

export const Title = styled.h1`
  font-size: 1.625rem;
  color: #2185d0;
  margin: 20px 0;
  text-align: center;
  font-weight: 700;
`;

export const Subtitle = styled.h2`
  font-size: 1.125rem;
  color: #2185d0;
  font-weight: 700;
  line-height: 27px;
  margin: 25px 0 0;
`;

export const Paragraph = styled.p`
  font-weight: 300;
  margin-bottom: 15px;
  line-height: 29px;
  display: block;
  text-align: justify;

  b {
    font-weight: 700;
    color: #111;
  }

  a {
    color: #2185d0;
    text-decoration: none;
    font-weight: 500;
  }
`;
