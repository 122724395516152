import React from 'react';

import moment from 'moment';
import * as S from './styles';

import * as I from './interfaces';

const ConselhoButton: React.FC<I.Props> = ({ value, criacao, ...res }) => {
  return (
    <S.Button {...res}>
      <S.Content>
        <S.Icon />
        <S.Column>
          <S.Text>
            {value}
            {'\n'}
          </S.Text>
          <S.Description>
            {moment(criacao).format('[criado em] DD/MM/YYYY')}
          </S.Description>
        </S.Column>
      </S.Content>
      <S.IconArrow />
    </S.Button>
  );
};

export default ConselhoButton;
