import React, { useCallback, useState } from 'react';

import { InputNumberField, SelectSearch, SelectOne, DelButton } from '..';

import * as S from './styles';

import * as I from './interface';
import ModalConfirmarEspecialidade from '../../domain/Modal/ModalConfirmarEspecialidade';

const Especialidade: React.FC<I.Props> = ({
  index,
  isDisabled = false,
  especialidade,
  options,
  data,
  setData,
  errorData,
  setErrorData,
  handleCreate,
  creatable = false,
}) => {
  const [label, setLabel] = useState('');
  const [create, setCreate] = useState(false);

  const especialidades = data;
  const error = errorData[index];

  const handleChange = (field: string, value: any) => {
    setData({
      especialidades: especialidades.map((exp: any) => {
        if (exp === especialidade) exp[field] = value;
        return exp;
      }),
    });
    setErrorData!([
      ...errorData.slice(0, index),
      {
        ...errorData[index],
        [field]: '',
      },
      ...errorData.slice(index + 1),
    ]);
  };

  const handleCreateLabel = (newValue: string) => {
    setCreate(true);
    setLabel(
      newValue.charAt(0).toUpperCase() + newValue.slice(1).toLowerCase(),
    );
  };

  const toggleBookmark = (bookmark: boolean) => {
    handleChange('favorita', bookmark);
  };

  const bookmarkLength = useCallback(
    () => especialidades.filter((value: any) => value.favorita === true).length,
    [especialidades],
  );

  return (
    <S.Container>
      {creatable ? (
        <SelectSearch
          id={`especialidade-${index}`}
          value={especialidades[index].especialidade}
          handleCreate={handleCreateLabel}
          onChangeValue={(opt) => handleChange('especialidade', opt)}
          labelText="Especialidade"
          width="215px"
          options={options}
          invalid={error ? error.especialidade : ''}
          isDisabled={isDisabled}
        />
      ) : (
        <SelectOne
          id={`especialidade-${index}`}
          value={especialidades[index].especialidade}
          onChangeValue={(opt) => handleChange('especialidade', opt)}
          labelText="Especialidade"
          width="190px"
          options={options}
          invalid={error ? error.especialidade : ''}
          isDisabled={isDisabled}
        />
      )}

      <InputNumberField
        id={`rqe-${index}`}
        value={especialidades[index].rqe}
        onChange={({ value }) => handleChange('rqe', value)}
        placeholder="00000000"
        labelText="RQE"
        width="120px"
        invalid={error ? error.rqe : ''}
        isDisabled={isDisabled}
      />

      {especialidades[index].favorita ? (
        <S.BookmarkFill
          onClick={() =>
            bookmarkLength() > 1 && !isDisabled && toggleBookmark(false)
          }
        />
      ) : (
        <S.Bookmark
          active={bookmarkLength() < 2 ? 'true' : 'false'}
          onClick={() =>
            bookmarkLength() < 2 && !isDisabled && toggleBookmark(true)
          }
        />
      )}

      {especialidades.length > 1 && !isDisabled && (
        <DelButton
          onClick={() => {
            let novasEspecialidades = [];

            // if (especialidades.length === 2) {
            if (especialidades.length === 2) {
              novasEspecialidades = especialidades.filter(
                (e: any) => e !== especialidade,
              );
              novasEspecialidades[0].favorita = true;
            } else {
              novasEspecialidades = especialidades.filter(
                (e: any) => e !== especialidade,
              );
              if (
                novasEspecialidades.filter(
                  (value: any) => value.favorita === true,
                ).length === 0
              )
                novasEspecialidades[0].favorita = true;
            }

            setData({
              especialidades: novasEspecialidades,
            });

            setErrorData!(errorData!.filter((e: any, i: any) => i !== index));
          }}
        />
      )}

      {create && (
        <ModalConfirmarEspecialidade
          titulo={label}
          closeModal={() => setCreate(false)}
          handleCreate={() => {
            handleCreate!(label);
            setCreate(false);
          }}
        />
      )}
    </S.Container>
  );
};

export default Especialidade;
