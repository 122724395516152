import React from 'react';

import logo from '../../assets/images/logo.svg';
import bgImg from '../../assets/images/background.svg';

import RecoveryContainer from '../../domain/Recovery/RecoveryContainer';

import * as S from './styles';

const PasswordRecovery: React.FC = () => {
  return (
    <S.Container>
      <S.ImageContainer>
        <S.BackgroundContainer>
          <S.Logo src={logo} alt="ClickConsultas" />
          <S.Background src={bgImg} alt="cadastro" />
        </S.BackgroundContainer>
      </S.ImageContainer>
      <RecoveryContainer user_type="gerente" />
    </S.Container>
  );
};

export default PasswordRecovery;
