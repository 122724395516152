import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

interface Selected {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Sidebar = styled.nav`
  width: 226px;
  height: calc(100% - 64px);
  background-color: #f6f6f6;
  border-left: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const Header = styled.header`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  margin-bottom: 36px;
`;

export const IconGoBack = styled(BiArrowBack)`
  width: 16px;
  height: 16px;
  fill: rgba(0, 0, 0, 0.5);
  margin-right: 6px;
`;

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
`;

export const MenuItem = styled.li<Selected>`
  display: flex;
  align-items: center;
  height: 28px;
  width: calc(100% + 29px);
  font-size: 14px;
  color: ${({ selected }) => (selected ? '#2fa8d5' : 'rgba(0,0,0,0.5)')};
  border-right: ${({ selected }) => (selected ? '3px solid #2fa8d5' : 'none')};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
`;
