import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import api from '../../services/api';

import {
  SelectOne,
  Pagination,
  StatusButton,
  TableWarning,
} from '../../components';

import {
  SkeletonConsultasTabela,
  ConsultasTabela,
} from '../../domain/Consultas';

import ModalConsulta from '../../domain/Modal/ModalConsulta';

import * as S from './styles';

import * as I from './interface';

const Consultas: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [data, setData] = useState<I.Data[]>([]);
  const [specList, setSpecList] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<any>([...data]);

  const [pagination, setPagination] = useState({
    current: 1,
    maxItensPerPage: 7,
    pageLimit: 5,
  });

  const [filterOptions, setFilterOptions] = useState({
    especialista: 'Todos',
    solicitadas: false,
    agendadas: false,
    pendentes: false,
    canceladas: false,
    finalizadas: false,
  });

  const [modals, setModals] = useState<{ [key: string]: any }>({
    consulta: {
      id: 0,
      state: false,
    },
  });

  const toggleModal = (type: string, id?: number) => {
    return id
      ? setModals({
          ...modals,
          [type]: { id, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  const formatPhoneNumber = (phoneNumberString: string): string => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4}|\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
  };

  const formatTempoEspera = (diasEspera: number, status: string): string => {
    if (
      status === 'Agendada' ||
      status === 'Finalizada' ||
      status === 'Cancelada' ||
      status === 'Pendente'
    ) {
      return '';
    }
    if (moment().diff(moment(diasEspera), 'days') > 0) {
      return `${moment().diff(moment(diasEspera), 'days')} dias`;
    }
    if (moment().diff(moment(diasEspera), 'hours') > 0) {
      return `${moment().diff(moment(diasEspera), 'hours')} horas`;
    }
    if (moment().diff(moment(diasEspera), 'minutes') > 0) {
      return `${moment().diff(moment(diasEspera), 'minutes')} minutos`;
    }
    return 'Agora';
  };

  const extractDays = (str: string) => {
    const match = str.match(/(\d+)\s+(dia|hora|minuto)s?/);
    if (match) {
      const [, num, unit] = match;
      switch (unit) {
        case 'dia':
          return Number(num) * 24 * 60;
        case 'hora':
          return Number(num) * 60;
        case 'minuto':
          return Number(num);
        default:
          return 0;
      }
    }
    return 0;
  };

  const sortByDate = (a: any, b: any) => {
    const aDate = Date.parse(a.data);
    const bDate = Date.parse(b.data);

    if (aDate < bDate) {
      return -1;
    }
    if (aDate > bDate) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenWidth < 900) {
      // largura menor que 768px
      setPagination((state) => ({ ...state, maxItensPerPage: 4 }));
    } else {
      setPagination((state) => ({ ...state, maxItensPerPage: 7 }));
    }
  }, [screenWidth]);

  useEffect(() => {
    const newData = data
      .filter((consulta) =>
        filterOptions.solicitadas ? consulta.status === 'Solicitada' : true,
      )
      .filter((consulta) =>
        filterOptions.agendadas ? consulta.status === 'Agendada' : true,
      )
      .filter((consulta) =>
        filterOptions.pendentes ? consulta.status === 'Pendente' : true,
      )
      .filter((consulta) =>
        filterOptions.canceladas ? consulta.status === 'Cancelada' : true,
      )
      .filter((consulta) =>
        filterOptions.finalizadas ? consulta.status === 'Finalizada' : true,
      )
      .filter((consulta) =>
        filterOptions.especialista !== 'Todos'
          ? consulta.especialista === filterOptions.especialista
          : true,
      );
    if (filterOptions.solicitadas) {
      newData.sort((a, b) => {
        const aDays = extractDays(a.diasEspera);
        const bDays = extractDays(b.diasEspera);

        if (aDays < bDays) {
          return -1;
        }
        if (aDays > bDays) {
          return 1;
        }
        return 0;
      });
      newData.reverse();
    }
    if (filterOptions.agendadas) {
      newData.sort(sortByDate);
      newData.reverse();
    }

    setFilteredData(newData);
    setPagination((state) => ({ ...state, current: 1 }));
  }, [filterOptions, data]);

  const fetchData = async () => {
    setLoading(true);
    setConnectionError(false);

    api
      .get('/medicos/spec-list')
      .then(({ data: medicos }) =>
        setSpecList(
          medicos.map(
            (m: { nome_completo: any }) => `Dr(a). ${m.nome_completo}`,
          ),
        ),
      );
    api
      .get('/consult/list')
      .then(({ data: consultas }) => {
        consultas.sort(sortByDate);
        setData([]);
        consultas.reverse();

        if (consultas.length > 0) {
          consultas.forEach((consulta: any) => {
            if (consulta.novaDataConsulta != null) {
              consulta.dataConsulta = consulta.novaDataConsulta;
            }
            const {
              id,
              data: dataConsulta,
              novaData: novaDataConsulta,
              paciente,
              especialista,
              especialidades,
              telefone,
              status,
              avatarMedico,
              avatarPaciente,
              atualizado,
              diasEspera,
            } = consulta;

            setData((state) => [
              ...state,
              {
                id,
                data: novaDataConsulta
                  ? moment(novaDataConsulta).format(
                      'DD [de] MMMM [de] YYYY, HH:mm',
                    )
                  : moment(dataConsulta).format(
                      'DD [de] MMMM [de] YYYY, HH:mm',
                    ),
                paciente,
                especialista: `Dr(a). ${especialista}`,
                especialidade: `${especialidades[0]}${
                  especialidades[1] ? ` (${especialidades[1]})` : ''
                }`,
                telefone: formatPhoneNumber(telefone),
                status,
                avatarMedico: avatarMedico || '',
                avatarPaciente: avatarPaciente || '',
                atualizado: moment(atualizado).format(
                  'DD [de] MMM [de] YYYY, HH:mm',
                ),
                diasEspera: formatTempoEspera(diasEspera, status),
              },
            ]);
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setConnectionError(true);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>
          <S.Headline>
            <S.Icon />
            <S.Title>Consultas</S.Title>
          </S.Headline>
          <S.Description>
            Encontre consultas em andamento (solicitadas, pendentes ou já
            agendadas).
          </S.Description>
        </S.TitleContainer>
        <S.FilterContainer>
          <SelectOne
            id="especialista"
            value={filterOptions.especialista}
            onChangeValue={(opt) =>
              setFilterOptions({ ...filterOptions, especialista: opt })
            }
            width="180px"
            height="36px"
            options={['Todos', ...specList]}
          />
          <S.ButtonContainer>
            <StatusButton
              value="Solicitada"
              active={filterOptions.solicitadas}
              onClick={() =>
                setFilterOptions({
                  ...filterOptions,
                  solicitadas: !filterOptions.solicitadas,
                  agendadas: false,
                  pendentes: false,
                  canceladas: false,
                  finalizadas: false,
                })
              }
            />
            <StatusButton
              value="Pendente"
              active={filterOptions.pendentes}
              onClick={() =>
                setFilterOptions({
                  ...filterOptions,
                  pendentes: !filterOptions.pendentes,
                  solicitadas: false,
                  agendadas: false,
                  canceladas: false,
                  finalizadas: false,
                })
              }
            />
            <StatusButton
              value="Agendada"
              active={filterOptions.agendadas}
              onClick={() =>
                setFilterOptions({
                  ...filterOptions,
                  agendadas: !filterOptions.agendadas,
                  solicitadas: false,
                  pendentes: false,
                  canceladas: false,
                  finalizadas: false,
                })
              }
            />
            <StatusButton
              value="Cancelada"
              active={filterOptions.canceladas}
              onClick={() =>
                setFilterOptions({
                  ...filterOptions,
                  canceladas: !filterOptions.canceladas,
                  solicitadas: false,
                  agendadas: false,
                  pendentes: false,
                  finalizadas: false,
                })
              }
            />
            <StatusButton
              value="Finalizada"
              active={filterOptions.finalizadas}
              onClick={() =>
                setFilterOptions({
                  ...filterOptions,
                  finalizadas: !filterOptions.finalizadas,
                  solicitadas: false,
                  agendadas: false,
                  pendentes: false,
                  canceladas: false,
                })
              }
            />
          </S.ButtonContainer>
        </S.FilterContainer>
      </S.Header>

      {loading && <SkeletonConsultasTabela />}

      {!loading &&
        // eslint-disable-next-line no-nested-ternary
        (filteredData.length === 0 ? (
          connectionError ? (
            <TableWarning noConnection />
          ) : (
            <TableWarning noData />
          )
        ) : (
          <>
            <ConsultasTabela
              consultas={filteredData}
              current={pagination.current}
              maxItensPerPage={pagination.maxItensPerPage}
              toggleModal={toggleModal}
              enablePagination
            />
            <Pagination
              entriesCounter={filteredData.length}
              current={pagination.current}
              maxItensPerPage={pagination.maxItensPerPage}
              pageLimit={pagination.pageLimit}
              setPagination={setPagination}
            />
          </>
        ))}

      <AnimatePresence>
        {modals.consulta.state && (
          <ModalConsulta
            id={modals.consulta.id}
            closeModal={() => toggleModal('consulta')}
            fetch={() => fetchData()}
          />
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default Consultas;
