import styled from 'styled-components';

import { BsBookmark } from 'react-icons/bs';

export const Container = styled.div`
  width: 86%;
  margin: 7%;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: #2fa8d5;
  margin: 0;
`;

export const Description = styled.h4`
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 16px 0;
  opacity: 0.8;
`;

export const Subtitle = styled.h3`
  margin: 50px 0 0 0;
  size: 20px;
  color: #2a3748;
`;

export const DetailRow = styled.div`
  display: flex;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const SpecContainer = styled.div`
  margin: 38px 0 12px 0;
`;

export const PartConvContainer = styled.div`
  margin: 38px 0 12px 0;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input`
  margin: 16px 6px 6px 0;
`;

export const ButtonContainer = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
`;

export const Bookmark = styled(BsBookmark)`
  margin-bottom: -3px;
`;
