import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineSchedule } from 'react-icons/ai';
import { FaUserCircle, FaRegCalendar } from 'react-icons/fa';
import { RiStethoscopeFill } from 'react-icons/ri';

import ActionButton from '../../../components/ActionButton';

interface InStatus {
  status?: string;
}

export const Background = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: #2a374880;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  width: 415px;
  height: fit-content;
  max-height: 80vh;
  background-color: white;
  position: absolute;
  opacity: 1;
  border-radius: 4px;
  overflow: hidden;
`;

export const Content = styled.div`
  max-height: calc(80vh - 268px);
  overflow: scroll;
  overflow-x: hidden;
`;

export const Header = styled.div`
  width: calc(100% - 60px);
  height: fit-content;
  padding: 30px;
  position: relative;
  border-bottom: 1px solid #e9e9e9;
`;

export const CloseButton = styled(IoMdClose)`
  width: 26px;
  height: 26px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 18px;
  padding: 0;
  margin: 0 0 18px 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Status = styled.div<InStatus>`
  width: fit-content;
  height: fit-content;
  padding: 10px;
  border-radius: 25px;
  background-color: ${({ status }) => {
    switch (status) {
      case 'Solicitada':
        return '#FD9800';
      case 'Agendada':
        return '#37D363';
      case 'Pendente':
        return '#2fa8d5';
      case 'Cancelada':
        return '#CF4647';
      case 'Finalizada':
        return '#333333';
      default:
        return '#e7e7e7';
    }
  }};
  color: white;
  margin-left: 6px;
  font-size: 14px;
`;

export const Details = styled.div`
  width: calc(100% - 60px);
  height: fit-content;
  padding: 30px;
  border-bottom: 1px solid #e9e9e9;
`;

export const Reschedule = styled.div`
  width: calc(100% - 60px);
  height: fit-content;
  padding: 30px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fafafa;
`;

export const ButtonContainer = styled.div`
  width: calc(100% - 32px);
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e9e9e9;
  padding: 0 16px;

  button {
    width: fit-content;
    padding: 0 16px;
  }
`;

export const ButtonCancelar = styled(ActionButton)`
  background-color: white;
  border: 1px solid #f03434;
  color: #f03434;

  &:hover {
    background-color: #f0343410;
  }

  &:active {
    background-color: #f0343420;
  }
`;

export const ButtonFinalizar = styled(ActionButton)`
  background-color: #e0e1e270;
  color: #1f1f1f;

  &:hover {
    background-color: #e0e1e2;
  }

  &:active {
    background-color: #e0e1e2;
  }
`;

export const ButtonRemarcar = styled(ActionButton)`
  background-color: white;
  border: 1px solid #2fa8d5;
  color: #2fa8d5;

  &:hover {
    background-color: #2fa8d510;
  }

  &:active {
    background-color: #2fa8d520;
  }
`;

export const ButtonConfirmar = styled(ActionButton)`
  background-color: #2fa8d5;
  color: white;
  margin-left: 8px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
`;

export const InitialsContainer = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
`;

export const Initials = styled.span`
  text-align: center;
  font-size: 20px;
  color: gray;
`;

export const Name = styled.p``;

export const Info = styled.div`
  display: flex;
  align-items: flex-start;
  /* padding: 16px 0 0 0; */
`;

export const IconPatient = styled(FaUserCircle)`
  width: 24px;
  height: 24px;
  margin: 0 16px 0 0;
  color: #3c4043;
`;

export const PatientDetails = styled.div``;

export const IconCalendar = styled(FaRegCalendar)`
  width: 24px;
  height: 24px;
  margin: 0 16px 0 0;
  color: #3c4043;
`;

export const IconDoctor = styled(RiStethoscopeFill)`
  width: 24px;
  height: 24px;
  margin: 0 16px 0 0;
  color: #3c4043;
`;

export const Text = styled.p`
  font-size: 14px;
`;

export const Label = styled(Text)`
  opacity: 0.7;
  margin: 20px 0 0 0;
`;

export const BoldText = styled(Text)`
  font-weight: bold;
  color: #3c4043;
  margin: 0;
`;

export const ValueText = styled(BoldText)`
  color: #2fa7d5;
  margin: 0 0 0 6px;
`;

export const LightText = styled(Text)`
  opacity: 0.7;
  margin: 0 0 0 6px;
`;

export const SpecText = styled(Text)`
  margin: 6px 0 16px 0;
`;

export const ScheduleDetails = styled.div`
  padding-top: 3px;
`;

export const IconRequest = styled(AiOutlineSchedule)`
  width: 24px;
  height: 24px;
  margin: 0 16px 0 0;
  color: #3c4043;
`;

export const RescheduleTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RescheduleTitle = styled.p`
  font-weight: bold;
  color: #2fa8d5;
  margin: 0;
`;

export const RescheduleTime = styled.p`
  font-size: 14px;
  color: #3c404370;
  margin: 0;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;
