import styled from 'styled-components';
import { MdLocationOn } from 'react-icons/md';
import { AiOutlineArrowUp } from 'react-icons/ai';

export const Button = styled.button`
  width: 100%;
  height: 56px;
  color: rgba(0, 0, 0, 0.7);
  background-color: white;
  border-radius: 4px;
  border: 1px solid #11111115;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 14px;
  margin-bottom: 16px;

  &:hover {
    background-color: #f3f3f310;
  }

  &:active {
    background-color: #f3f3f320;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  font-weight: normal;
  line-height: 0;
`;

export const Text = styled.p`
  text-overflow: ellipsis;
  line-height: initial;
  text-align: start;
  width: 90%;
`;

export const IconLocation = styled(MdLocationOn)`
  width: 20px;
  height: 20px;
  fill: #2fa8d5;
  margin-right: 10px;
`;

export const IconArrow = styled(AiOutlineArrowUp)`
  width: 16px;
  height: 16px;
  fill: #111;
  transform: rotate(45deg);
`;
