import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import api from '../../services/api';

import {
  DetalhesConvenios,
  DetalhesAtendimento,
  DetalhesDadosBasicos,
  DetalhesEspecialidades,
  DetalhesExperienciaFormacao,
  SkeletonDadosBasicos,
} from '../../domain/Detalhes';

import * as S from './styles';

import * as I from './interface';

const Detalhes: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState('dadosBasicos');

  // Valores para estados, conselhos, convenios e especialidades.
  const [estadosList, setEstadosList] = useState<string[]>([]);
  const [conselhosList, setConselhosList] = useState<string[]>([]);
  const [especialidadesList, setEspecialidadesList] = useState<string[]>([]);
  const [conveniosList, setConveniosList] = useState<string[]>([]);

  const [data, setData] = useState({
    idMedico: useParams<I.Params>().id,
    avatar: '',
    conselho: '',
    estado: '',
    numeroRegistro: '',
    nomeCompleto: '',
    cpfMedico: '',
    rgMedico: '',
    emailContato: '',
    telefoneContato: '',
    pessoaContato: '',
    clinica: '',
    emailResponsavel: '',
    emailSecundario: '',
    dom: false,
    seg: false,
    ter: false,
    qua: true,
    qui: false,
    sex: false,
    sab: false,
    rua: '',
    numero: '',
    bairro: '',
    uf: '',
    cidade: '',
    cep: '',
    telefone_cel: '',
    telefone_fixo: '',
    from: '',
    to: '',
    duration: '',
    experiencias: [{ titulo: '', descricao: '', ano: '' }],
    formacoes: [{ instituicao: '', curso: '', ano: '' }],
    especialidades: [{ especialidade: '', rqe: '', favorita: true }],
    valor: '',
    convenios: [''],
    pendente: false,
    dataInclusao: moment().clone(),
  });

  const fetch = () => {
    setLoading(true);
    api.get(`/medicos/${data.idMedico}`).then(async ({ data: medico }) => {
      let avatar = '';

      await api
        .get(`/avatar/${data.idMedico}`)
        .then(({ data: { file_url } }) => {
          avatar = file_url;
        })
        .catch(() => {
          avatar = '';
        });

      setData((state) => ({
        ...state,
        ...medico,
        avatar,
        from: medico.from.replaceAll(':', '').slice(0, 4),
        to: medico.to.replaceAll(':', '').slice(0, 4),
        duration: medico.duration.toString(),
        pendente: medico.is_pending,
      }));
      setLoading(false);
    });
  };

  useEffect(() => {
    fetch();
    axios
      .get<{ sigla: string }[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
      )
      .then((res) => {
        const ufs = res.data.map((uf) => uf.sigla).sort();
        setEstadosList(ufs);
      });
    api
      .get('/lista-especialidades')
      .then(({ data: listaEspecs }) =>
        setEspecialidadesList(
          listaEspecs
            .map((e: { especialidade: string }) => e.especialidade)
            .sort(),
        ),
      );
    api
      .get('/lista-conselhos')
      .then(({ data: listaConselhos }) =>
        setConselhosList(
          listaConselhos.map((c: { conselho: string }) => c.conselho).sort(),
        ),
      );
    api
      .get('/lista-convenios')
      .then(({ data: listaConvenios }) =>
        setConveniosList(
          listaConvenios.map((c: { convenio: string }) => c.convenio).sort(),
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetail = () => {
    switch (option) {
      case 'dadosBasicos':
        return loading ? (
          <SkeletonDadosBasicos />
        ) : (
          <DetalhesDadosBasicos
            id={data.idMedico}
            avatar={data.avatar}
            conselho={data.conselho}
            estado={data.estado}
            numeroRegistro={data.numeroRegistro}
            nomeCompleto={data.nomeCompleto}
            cpfMedico={data.cpfMedico}
            rgMedico={data.rgMedico}
            emailContato={data.emailContato}
            telefoneContato={data.telefoneContato}
            pessoaContato={data.pessoaContato}
            clinica={data.clinica}
            endereco={`${data.rua}, ${data.numero}, ${data.bairro} - ${data.cidade}, ${data.uf}`}
            email={data.emailResponsavel}
            emailSecundario={data.emailSecundario}
            telefone_cel={data.telefone_cel}
            telefone_fixo={data.telefone_fixo}
            conselhosList={conselhosList}
            estadosList={estadosList}
            fetch={() => fetch()}
            especialidades={data.especialidades}
            especialidadesList={especialidadesList}
          />
        );
      case 'atendimento':
        return (
          <DetalhesAtendimento
            id={data.idMedico}
            dom={data.dom}
            seg={data.seg}
            ter={data.ter}
            qua={data.qua}
            qui={data.qui}
            sex={data.sex}
            sab={data.sab}
            from={data.from}
            to={data.to}
            duration={data.duration}
            fetch={fetch}
          />
        );
      case 'experienciaFormacao':
        return (
          <DetalhesExperienciaFormacao
            id={data.idMedico}
            experiencias={data.experiencias}
            formacoes={data.formacoes}
            fetch={fetch}
          />
        );
      case 'especialidades':
        return (
          <DetalhesEspecialidades
            id={data.idMedico}
            especialidades={data.especialidades}
            especialidadesList={especialidadesList}
            fetch={fetch}
            edit={false}
            saving={false}
            setSaving={() => {}}
            setEdit={() => {}}
            setLoading={() => {}}
          />
        );
      case 'convenios':
        return (
          <DetalhesConvenios
            id={data.idMedico}
            valor={data.valor}
            temConvenio={!(data.convenios.length === 0)}
            convenios={data.convenios}
            conveniosList={conveniosList}
            fetch={fetch}
          />
        );
      default:
        return <h2>Erro inesperado. Recarregue a página!</h2>;
    }
  };

  return (
    <S.Container>
      <S.Sidebar>
        <S.Header>
          <S.StyledLink to="/gerente/config">
            <S.IconGoBack />
            Voltar
          </S.StyledLink>
        </S.Header>
        {/* <S.Details>{id} - Dr. John Doe</S.Details> */}
        <S.Menu>
          <S.MenuItem
            onClick={() => !loading && setOption('dadosBasicos')}
            selected={option === 'dadosBasicos'}>
            Dados básicos
          </S.MenuItem>
          <S.MenuItem
            onClick={() => !loading && setOption('atendimento')}
            selected={option === 'atendimento'}>
            Atendimento
          </S.MenuItem>
          <S.MenuItem
            onClick={() => !loading && setOption('experienciaFormacao')}
            selected={option === 'experienciaFormacao'}>
            Experiência & Formação
          </S.MenuItem>
          {/* <S.MenuItem
            onClick={() => !loading && setOption('especialidades')}
            selected={option === 'especialidades'}>
            Especialidades
          </S.MenuItem> */}
          <S.MenuItem
            onClick={() => !loading && setOption('convenios')}
            selected={option === 'convenios'}>
            Convênios
          </S.MenuItem>
        </S.Menu>
      </S.Sidebar>
      {renderDetail()}
    </S.Container>
  );
};

export default Detalhes;
