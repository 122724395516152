import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const LocationButton: React.FC<I.Props> = ({ value, ...res }) => {
  return (
    <S.Button {...res}>
      <S.Content>
        <S.IconLocation />
        <S.Text>{value}</S.Text>
      </S.Content>
      <S.IconArrow />
    </S.Button>
  );
};

export default LocationButton;
