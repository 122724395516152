import React from 'react';
import ReactDom from 'react-dom';
import * as S from './styles';

interface Props {
  id?: string;
  save: () => void;
  closeModal: () => void;
}

const ModalAvisoCadastro: React.FC<Props> = ({ save, closeModal }) => {
  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      <S.ModalContainer>
        <S.Content>
          <S.Header>
            <S.Title>Alterar dados de cadastro</S.Title>
            <S.Text>
              Tem certeza que gostaria de alterar o cadastro desse médico? Ele
              ficará inativo até que o administrador aprove seu cadastro
              novamente.
            </S.Text>
          </S.Header>
        </S.Content>
        <S.LinkContainer>
          <S.Link onClick={closeModal}>Voltar</S.Link>
          <S.Link
            onClick={() => {
              save();
              closeModal();
            }}
            primary>
            Confirmar
          </S.Link>
        </S.LinkContainer>
      </S.ModalContainer>
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalAvisoCadastro;
