import React from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonCadastro: React.FC = () => {
  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Cadastro</S.SubTitle>
        <Skeleton width={70} height={38} />
      </S.SubTitleContainer>
      <br />
      <S.Row>
        <S.ContentContainer>
          <S.Column>
            <Skeleton width={96} height={15} />
            <Skeleton width={210} height={44} />
          </S.Column>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <S.Column>
            <Skeleton width={51} height={15} />
            <Skeleton width={200} height={44} />
          </S.Column>
        </S.ContentContainer>
      </S.Row>
      <S.Row>
        <S.Column>
          <Skeleton width={100} height={15} />
          <Skeleton width={200} height={44} />
        </S.Column>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <S.Column>
          <Skeleton width={100} height={15} />
          <Skeleton width={200} height={44} />
        </S.Column>
      </S.Row>
    </S.Container>
  );
};

export default SkeletonCadastro;
