import styled from 'styled-components';

export const Container = styled.div`
  width: 86%;
  height: 100%;
  margin: 7%;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  color: #2fa8d5;
  margin: 0;
`;

export const Description = styled.h4`
  font-size: 14px;
  font-weight: normal;
  margin: 10px 0 16px 0;
  opacity: 0.8;
`;

export const Subtitle = styled.h3`
  margin: 16px 0 0 0;
`;

export const Span = styled.span`
  font-size: 14px;
  font-weight: normal;
  opacity: 0.8;
`;

export const ContentRow = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  margin-top: 46px;
`;

export const ButtonContainer = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
`;
