import styled from 'styled-components';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { FaInfo } from 'react-icons/fa';

interface InputProps {
  inputWidth: string;
  error: boolean;
  password?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.input<InputProps>`
  width: ${({ inputWidth }) => inputWidth};
  height: 42px;
  padding: 0 16px;
  border: 1px solid ${({ error }) => (error ? 'red' : '#DEDEDF')};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  -webkit-text-security: ${({ password }) => (password ? 'disc' : 'none')};

  ::placeholder {
    color: #b5b5b5;
  }
`;

export const ErrorIcon = styled(FaInfo)`
  width: 50%;
  height: 50%;
  color: white;
`;

export const ErrorMessage = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: calc(50% - 10px);
  right: 42px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  p {
    cursor: default;
    display: none;
    color: #fff;
  }

  &:hover {
    padding: 0 10px;
    width: auto;
    height: auto;
    border-radius: 4px;
    white-space: pre;
    z-index: 1000;

    ${ErrorIcon} {
      display: none;
    }

    p {
      display: inline;
    }
  }
`;

export const ToggleViewButton = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  position: absolute;
  top: calc(50% - 10px);
  right: 16px;
  opacity: 0.2;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
`;

export const Eye = styled(AiFillEye)`
  width: 18px;
  height: 18px;
  position: absolute;
`;

export const EyeInvisible = styled(AiFillEyeInvisible)`
  width: 18px;
  height: 18px;
  position: absolute;
`;
