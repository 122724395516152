import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

const SkeletonHistoricoTabela: React.FC = () => {
  return (
    <S.Container>
      <S.Table>
        <S.THead>
          <S.TRHead>
            <S.TH>
              <Skeleton width={125} height={15} />
            </S.TH>
            <S.TH>
              <Skeleton width={104} height={15} />
            </S.TH>
            <S.TH>
              <Skeleton width={145} height={15} />
            </S.TH>
            <S.TH>
              <Skeleton width={120} height={15} />
            </S.TH>
            <S.TH>
              <Skeleton width={120} height={15} />
            </S.TH>
            <S.TH>
              <Skeleton width={105} height={15} />
            </S.TH>
            <S.TH>
              <Skeleton width={135} height={15} />
            </S.TH>
          </S.TRHead>
        </S.THead>
        <S.TBody>
          {Array(7)
            .fill(0)
            .map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <S.TRBody key={index}>
                <S.TD>
                  <Skeleton width={86} height={15} />
                  <br />
                  <Skeleton width={46} height={15} />
                </S.TD>
                <S.TD>
                  <Skeleton width={96} height={15} />
                </S.TD>
                <S.TD>
                  <S.EspContainer>
                    <S.EspDetails>
                      <Skeleton width={120} height={15} />
                      <Skeleton width={150} height={15} />
                    </S.EspDetails>
                  </S.EspContainer>
                </S.TD>
                <S.TD>
                  <S.EspContainer>
                    <Skeleton width={115} height={15} />
                  </S.EspContainer>
                </S.TD>
                <S.TD>
                  <S.StatusContainer>
                    <Skeleton width={100} height={15} />
                  </S.StatusContainer>
                </S.TD>
                <S.TD>
                  <S.StatusContainer>
                    <Skeleton width={100} height={15} />
                  </S.StatusContainer>
                </S.TD>
                <S.TD>
                  <Skeleton width={85} height={15} />
                </S.TD>
              </S.TRBody>
            ))}
        </S.TBody>
      </S.Table>
    </S.Container>
  );
};

export default SkeletonHistoricoTabela;
