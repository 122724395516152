import React, { useState } from 'react';

import { ActionButton, ConvenioButton } from '../../../components';

import * as S from './styles';

interface Convenio {
  id: number;
  convenio: string;
}

interface Props {
  convenios: Convenio[];
  toggleModal: (type: string, id?: number | undefined) => void;
}

const ConfigConvenios: React.FC<Props> = ({ convenios, toggleModal }) => {
  const [filter, setFilter] = useState('');
  return (
    <S.Container>
      <S.SubTitleContainer>
        <S.SubTitle>Lista de Convênios</S.SubTitle>
        <ActionButton
          onClick={() => toggleModal('novoConvenio')}
          value="Novo Convênio"
        />
      </S.SubTitleContainer>
      <S.DetailsContainer>
        <S.SearchContainer>
          <S.SearchInput
            value={filter}
            onChange={(v) => setFilter(v.target.value)}
          />
          <S.SearchIcon />
        </S.SearchContainer>

        {convenios
          .sort((a, b) => a.convenio.localeCompare(b.convenio))
          .filter((a) =>
            a.convenio
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                filter
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase(),
              ),
          )
          .map((convenio) => (
            <ConvenioButton
              key={convenio.id}
              onClick={() => toggleModal('apagarConvenio', +convenio.id)}
              value={`${convenio.convenio}`}
            />
          ))}
        {convenios
          .sort((a, b) => a.convenio.localeCompare(b.convenio))
          .filter((a) =>
            a.convenio
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                filter
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase(),
              ),
          )
          .map((convenio) => (
            <ConvenioButton
              key={convenio.id}
              onClick={() => toggleModal('apagarConvenio', +convenio.id)}
              value={`${convenio.convenio}`}
            />
          )).length === 0 && (
          <S.TextNotFound>Nenhum convênio encontrado!</S.TextNotFound>
        )}
      </S.DetailsContainer>
    </S.Container>
  );
};

export default ConfigConvenios;
