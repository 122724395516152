import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IoMdClose } from 'react-icons/io';
import { BsBookmark } from 'react-icons/bs';
import { FaInfo } from 'react-icons/fa';

export const Background = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: #2a374880;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  width: 700px;
  height: fit-content;
  max-height: 80vh;
  background-color: white;
  position: absolute;
  opacity: 1;
  border-radius: 4px;
`;

export const Content = styled.div`
  height: 60vh;
  overflow: scroll;
  /* overflow-x: hidden; */
  padding: 30px 50px;
`;

export const Header = styled.div`
  width: calc(100% - 60px);
  height: fit-content;
  padding: 30px;
  position: relative;
  border-bottom: 1px solid #e9e9e9;
`;

export const CloseButton = styled(IoMdClose)`
  width: 26px;
  height: 26px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 22px;
  color: #2fa8d5;
  padding: 0;
  margin: 0 0 28px 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: calc(100% - 32px);
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e9e9e9;
  padding: 0 16px;

  button {
    width: fit-content;
    padding: 0 16px;
    margin-left: 8px;
  }
`;

export const DetailRow = styled.div`
  display: flex;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const Description = styled.h4`
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 16px 0;
  opacity: 0.8;
`;

export const SuccessMessageContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Subtitle = styled.h3`
  font-size: 18px;
  margin: 50px 0 0 0;
  size: 20px;
`;
export const SpecContainer = styled.div`
  margin: 38px 0 12px 0;
`;

export const PartConvContainer = styled.div`
  margin: 38px 0 12px 0;
`;

export const ContentContainer = styled.div`
  margin-top: 46px;
`;

export const ContentRow = styled.div`
  display: flex;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input`
  margin: 16px 6px 6px 0;
`;

export const Bookmark = styled(BsBookmark)`
  margin-bottom: -3px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  margin: 0 0 14px 0;
`;

export const UpdateMessage = styled.span`
  /* position: absolute; */
  display: none;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  line-height: normal;
`;

// export const Input = styled.input`
//   width: 92px;
//   height: 92px;
//   border-radius: 50%;
//   opacity: 0;
//   overflow: hidden;
//   position: absolute;
// `;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  clip-path: circle(35px at center);
`;

export const InitialsContainer = styled.div`
  width: 100%;
  height: 100%;
  clip-path: circle(35px at center);
`;

export const Initials = styled.span`
  text-align: center;
  font-size: 28px;
  color: gray;
`;

export const Input = styled.input`
  width: 71px;
  height: 71px;
  border-radius: 50%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
`;

export const PhotoContainer = styled.div`
  width: 71px;
  height: 71px;
  border-radius: 50%;
  background-color: lightgrey;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 500;
  line-height: 0;

  position: relative;

  &:hover {
    background-color: #e0e1e2;

    ${Input} {
      cursor: pointer;
    }

    ${Photo} {
      filter: grayscale(100%);
      opacity: 0.3;
      position: absolute;
    }

    ${Initials} {
      filter: grayscale(100%);
      opacity: 0.3;
      position: absolute;
    }

    ${UpdateMessage} {
      display: inline-block;
      position: absolute;
    }
  }
`;

export const ErrorIcon = styled(FaInfo)`
  width: 50%;
  height: 50%;
  color: white;
`;

export const ErrorMessage = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 0;
  right: -10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  p {
    cursor: default;
    display: none;
    color: #fff;
  }

  &:hover {
    padding: 0 10px;
    width: auto;
    height: auto;
    border-radius: 4px;
    white-space: pre;
    right: -315px;
    z-index: 1000;

    ${ErrorIcon} {
      display: none;
    }

    p {
      display: inline;
    }
  }
`;
