import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';
import api from '../../../services/api';

import InputField from '../../../components/InputField';
import ActionButton from '../../../components/ActionButton';

import { recoveryContext } from '../RecoveryContainer';
import { recoveryEmailValidator } from '../../../utils/recoveryValidator';

import * as S from './styles';

interface Props {
  nextStep: () => void;
  user_type: string;
}

const RecoveryEmail: React.FC<Props> = ({ nextStep, user_type }) => {
  const reRef: any = useRef<ReCAPTCHA>();
  const { recoveryData, setRecoveryData } = useContext(recoveryContext);

  const history = useHistory();
  const [load, setLoad] = useState<boolean>(false);

  const [errorData, setErrorData] = useState({
    email: '',
  });

  const next = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoad(true);
    if (await recoveryEmailValidator(recoveryData!, setErrorData)) {
      const token = await reRef.current?.executeAsync();
      reRef.current?.reset();
      api
        .post(`/forgot-password/${user_type}`, {
          email: recoveryData?.email,
          token,
        })
        .then(() => {
          nextStep();
        })
        .catch(() => {
          setErrorData({
            ...errorData,
            email: 'Email inválido!',
          });
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  };

  const handleChange = (data: any, value: any) => {
    setErrorData({ ...errorData, [data]: '' });
    setRecoveryData!({ ...recoveryData!, [data]: value });
  };

  return (
    <S.Container>
      <S.Content>
        <S.TitleContainer>
          <S.PrimaryMessage>Recuperar senha</S.PrimaryMessage>
          <S.SecondaryMessage>
            Vamos mandar um código ao seu e-mail para <br /> recuperar sua
            senha.
          </S.SecondaryMessage>
        </S.TitleContainer>

        <S.FormContainer onSubmit={next}>
          <InputField
            id="email"
            value={recoveryData!.email}
            onChange={(e) => handleChange('email', e.target.value)}
            placeholder="nome@dominio.com"
            labelText="Email"
            width="286px"
            invalid={errorData.email}
          />
          <S.BtnContainer>
            <ActionButton
              value="Confirmar"
              primary
              onClick={() => {}}
              loading={load}
            />
            <ActionButton
              value="Cancelar"
              onClick={() => history.push('/auth/login')}
            />
          </S.BtnContainer>
        </S.FormContainer>
      </S.Content>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN!}
        size="invisible"
        ref={reRef}
      />
    </S.Container>
  );
};

export default RecoveryEmail;
