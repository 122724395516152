import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import * as S from './styles';

import WhiteLogo from '../../assets/images/logo-white.svg';
import { ActionButton } from '../../components';
import ModalDeletarConta from '../../domain/Modal/ModalDeletarConta';
import api from '../../services/api';

interface UserProps {
  id_paciente: number;
  nome: string;
  email: string;
  cpf: string;
  telefone?: string;
  nascimento?: string;
  genero?: string;
  cidade?: string;
  avatar?: string;
}

const UsersPolicyLayout: React.FC = () => {
  const [modals, setModals] = useState<{ [key: string]: any }>({
    confirmar: {
      id: '0',
      state: false,
    },
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserProps | null>(null);

  const history = useHistory();

  const fetchData = async () => {
    setLoading(true);
    await api.get('/pacientes/profile').then(async ({ data: dados }) => {
      setData(dados);
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getInitials = (name: string) => {
    name = name.replace('Dr(a).', '').trim();
    const initials = name
      .split(' ')
      .map((n) => n[0])
      .filter((w) => w !== undefined);
    if (!initials[0]) return '';
    if (!initials[1]) return initials[0].toUpperCase();
    return (
      initials[0].toUpperCase() + initials[initials.length - 1].toUpperCase()
    );
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !((dropdownRef.current as unknown) as HTMLElement).contains(
        event.target as Node,
      )
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const logoff = useCallback(() => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('jwt');
    history.push('/auth/login/users-policy');
  }, [history]);

  const toggleModal = (type: string, id?: string) => {
    return id
      ? setModals({
          ...modals,
          [type]: { id, state: !modals[type].state },
        })
      : setModals({
          ...modals,
          [type]: { ...modals[type], state: !modals[type].state },
        });
  };

  return (
    <S.Container>
      <S.Header>
        <img src={WhiteLogo} alt="ClickConsultas Logo" />
        {!loading && data && (
          <S.UserInfo
            onClick={() => setDropdownOpen(!dropdownOpen)}
            ref={dropdownRef}>
            <S.UserInfoData>
              <p>{data.nome}</p>
            </S.UserInfoData>
            <S.UserAvatar>
              {data.avatar ? (
                <img src={data.avatar} alt="Avatar" />
              ) : (
                <p>{getInitials(data.nome)}</p>
              )}
            </S.UserAvatar>
            {dropdownOpen && (
              <S.DropdownMenu>
                <S.DropdownItem onClick={logoff}>Sair</S.DropdownItem>
              </S.DropdownMenu>
            )}
          </S.UserInfo>
        )}
      </S.Header>
      <S.Delete>
        <S.DeleteContainer>
          <h1>Você deseja excluir sua conta e seus dados?</h1>
          <p>
            *Ao excluir a sua conta, seu perfil será desativado imediatamente e
            seus dados serão apagados de forma permanente de nosso sistema
          </p>
          <ActionButton
            onClick={() => toggleModal('confirmar')}
            value="Excluir"
            color="f01e2c"
          />
        </S.DeleteContainer>
      </S.Delete>
      {modals.confirmar.state && data && (
        <ModalDeletarConta
          id={modals.confirmar.id}
          closeModal={() => toggleModal('confirmar')}
          fetch={() => {}}
          user={data.email}
        />
      )}
    </S.Container>
  );
};

export default UsersPolicyLayout;
