/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';

interface ButtonProps {
  primary: boolean;
  color?: string;
}

interface InLoad {
  $loading: boolean;
}

export const Button = styled.button<ButtonProps>`
  width: 127px;
  height: 38px;
  color: ${({ primary, color }) => (primary || color ? 'white' : '#323233')};
  background-color: ${({ primary, color }) =>
    primary ? '#2891b8' : color ? `#${color}` : '#E0E1E2'};
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 0; */

  &:hover {
    background-color: ${({ primary, color }) =>
      primary ? '#4ea2c2' : color ? `#${color}` : '#E0E1E2'};
  }

  &:active {
    background-color: ${({ primary, color }) =>
      primary ? '#2388AE' : color ? `#${color}` : '#E0E1E2'};
  }
`;

export const Load = styled(FaCircleNotch)<InLoad>`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: ${({ $loading }) => ($loading ? 'inline' : 'none')};
  animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
