import styled from 'styled-components';

export const Container = styled.div`
  width: 86%;
  height: 85%;
  margin: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.25s ease-in;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;
`;

export const Logo = styled.img`
  width: 192px;
`;

export const PrimaryMessage = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
`;

export const SecondaryMessage = styled.h4`
  font-size: 1rem;
  font-weight: 400;
  margin: 25px 0 1rem 0;
  opacity: 0.6;
`;

export const ButtonContainer = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const InputContainer = styled.div`
  margin-right: -16px;
`;

export const FormContainer = styled.form``;
