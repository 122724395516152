import React from 'react';

import * as S from './styles';

import * as I from './interfaces';

const SpecialitieButton: React.FC<I.Props> = ({
  value,
  onClickArrow,
  onClickAccept,
  is_pending,
  ...res
}) => {
  return (
    <S.Button {...res}>
      <S.Content>
        <S.Icon color={is_pending ? '#e0e1e2' : ''} />
        <S.Text>{value}</S.Text>
      </S.Content>
      <S.Content>
        {is_pending && <S.IconAccept onClick={onClickAccept} />}
        <S.IconArrow onClick={onClickArrow} />
      </S.Content>
    </S.Button>
  );
};

export default SpecialitieButton;
