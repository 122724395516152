import React from 'react';

import * as S from './styles';

const FormSuccess: React.FC = () => {
  return (
    <S.Container>
      <S.TitleContainer>
        <S.PrimaryMessage>
          Sua conta foi criada com sucesso! <br /> Agora é só aguardar o
          contato.
        </S.PrimaryMessage>
        <S.SecondaryMessage>
          Suas informações foram enviadas ao sistema para verificação dos dados!
          <br />
          Por favor, aguarde nossa resposta. Lembrando que, após sua aprovação,
          você poderá adicionar outros médicos.
        </S.SecondaryMessage>
      </S.TitleContainer>
      <S.Footnote>
        Se você precisar de ajuda, contate o administrador.
      </S.Footnote>
    </S.Container>
  );
};

export default FormSuccess;
