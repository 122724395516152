import React from 'react';

import { InputNumberField, InputField, DelButton } from '..';

import * as S from './styles';

import * as I from './interface';

const Experiencia: React.FC<I.Props> = ({
  index,
  isDisabled = false,
  experiencia,
  data,
  setData,
  errorData,
  setErrorData,
}) => {
  const { experiencias } = data!;

  const error = errorData!.experiencias[index];

  const handleChange = (field: string, value: any) => {
    setData({
      ...data,
      experiencias: experiencias.map((exp) => {
        if (exp === experiencia) exp[field] = value;
        return exp;
      }),
    });
    setErrorData!((state: any) => ({
      ...state,
      experiencias: [
        ...state.experiencias.slice(0, index),
        {
          ...state.experiencias[index],
          [field]: '',
        },
        ...state.experiencias.slice(index + 1),
      ],
    }));
  };

  return (
    <S.Container>
      <InputField
        id={`titulo-${index}`}
        value={experiencias[index].titulo}
        onChange={(e) => handleChange('titulo', e.target.value)}
        placeholder="Título da experiência..."
        labelText="Título"
        width="190px"
        invalid={error ? error.titulo : ''}
        isDisabled={isDisabled}
      />

      <InputField
        id={`descricao-${index}`}
        value={experiencias[index].descricao}
        onChange={(e) => handleChange('descricao', e.target.value)}
        placeholder="Descreva a experiência..."
        labelText="Descrição"
        width="180px"
        invalid={error ? error.descricao : ''}
        isDisabled={isDisabled}
      />

      <InputNumberField
        id={`anoExp-${index}`}
        value={experiencias[index].ano}
        onChange={({ value }) => handleChange('ano', value)}
        placeholder="1999"
        labelText="Ano"
        width="58px"
        invalid={error ? error.ano : ''}
        format="####"
        mask="_"
        isDisabled={isDisabled}
      />

      {experiencias.length > 1 && !isDisabled && (
        <DelButton
          onClick={() => {
            setData({
              ...data,
              experiencias: experiencias.filter((e) => e !== experiencia),
            });
            setErrorData!({
              ...errorData!,
              experiencias: errorData!.experiencias.filter(
                (e, i) => i !== index,
              ),
            });
          }}
        />
      )}
    </S.Container>
  );
};

export default Experiencia;
