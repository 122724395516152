/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import moment from 'moment';
import api from '../../../services/api';

import {
  InputNumberField,
  LoadingSpinner,
  SelectOne,
} from '../../../components';

import { modalRemarcarValidator } from '../../../utils/modalValidator';

import * as S from './styles';

interface Props {
  id?: string;
  idMedico?: number;
  idPaciente?: number;
  closeModal: () => void;
  closeAll: () => void;
}

interface Endereco {
  id_endereco: number;
  endereco: string;
}

const ModalRemarcarConsulta: React.FC<Props> = ({
  id,
  closeModal,
  closeAll,
}) => {
  const [loading, setLoading] = useState(false);
  const [listaEnderecos, setListaEnderecos] = useState<Endereco[]>([
    {
      id_endereco: 0,
      endereco: '',
    },
  ]);

  const [step, setStep] = useState(1);

  const [data, setData] = useState({
    novaData: '',
    novoHorario: '',
    novoEndereco: '',
  });

  const [errorData, setErrorData] = useState({
    novaData: '',
    novoHorario: '',
    novoEndereco: '',
  });

  const handleChange = (key: any, value: any) => {
    setData({ ...data, [key]: value });
    setErrorData({ ...errorData, [key]: '' });
  };

  const confirm = async () => {
    setLoading(true);
    if (await modalRemarcarValidator(data!, setErrorData)) {
      api
        .put(`/consult/remarcar/${id}`, {
          nova_data_consulta: moment(
            data.novaData + data.novoHorario,
            'DDMMYYYYkkmm',
          ),
          id_endereco: listaEnderecos.find(
            (x) => x.endereco === data.novoEndereco,
          )?.id_endereco,
        })
        .then(() => {
          closeModal();
          closeAll();
        });
    }
    setLoading(false);
  };

  const fetchData = () => {
    api.get('/address').then(({ data: enderecos }) => {
      setListaEnderecos(enderecos);
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStep = (opt: number) => {
    switch (opt) {
      case 1:
        return (
          <S.ModalContainer>
            <S.Header>
              <S.Title>Remarcar consulta</S.Title>
              <S.Text>
                Entre em contato com o paciente para combinar uma nova data. Se
                já conversou com o paciente, clique em continuar.
              </S.Text>
            </S.Header>
            <S.LinkContainer>
              <S.Link onClick={closeModal}>Voltar</S.Link>
              <S.Link onClick={() => setStep(2)} primary>
                Continuar
              </S.Link>
            </S.LinkContainer>
          </S.ModalContainer>
        );

      case 2:
        return (
          <S.ModalContainer>
            <S.Header>
              <S.Title>Remarcar consulta</S.Title>
            </S.Header>
            <S.Details>
              <S.Row>
                <InputNumberField
                  id="novaData"
                  value={data.novaData}
                  onChange={({ value }) => handleChange('novaData', value)}
                  placeholder="31/12/2021"
                  labelText="Nova data"
                  width="96px"
                  invalid={errorData.novaData}
                  format="##/##/####"
                  mask="_"
                />
                <InputNumberField
                  id="novoHorario"
                  value={data.novoHorario}
                  onChange={({ value }) => handleChange('novoHorario', value)}
                  placeholder="15:00"
                  labelText="Novo horario"
                  width="60px"
                  invalid={errorData.novoHorario}
                  format="##:##"
                  mask="_"
                />
              </S.Row>
              <SelectOne
                id="endereco"
                value={data.novoEndereco}
                onChangeValue={(value) => handleChange('novoEndereco', value)}
                labelText="Alterar endereço"
                width="320px"
                options={listaEnderecos.map((e) => e.endereco)}
                invalid={errorData.novoEndereco}
              />
            </S.Details>
            <S.LinkContainer>
              {!loading ? (
                <>
                  <S.Link onClick={closeModal}>Cancelar</S.Link>
                  <S.Link onClick={confirm} primary>
                    Enviar
                  </S.Link>
                </>
              ) : (
                <LoadingSpinner color="#2fa8d5" />
              )}
            </S.LinkContainer>
          </S.ModalContainer>
        );
      default:
        return <h1>Ocorreu um erro! Tente novamente.</h1>;
    }
  };

  return ReactDom.createPortal(
    <S.Background
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      onClick={(e) => e.target === e.currentTarget && closeModal()}>
      {renderStep(step)}
    </S.Background>,
    document.getElementById('portal')!,
  );
};

export default ModalRemarcarConsulta;
