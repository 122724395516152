import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  height: fit-content;
  background-color: white;
  border-radius: 8px;
  margin: 0 24px 24px 0;
`;

export const SubTitle = styled.h1`
  width: 100%;
  height: fit-content;
  font-size: 18px;
  margin: 0;
  color: #2a3748;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0 30px;

  button {
    margin: 0 0 0 8px;
    width: fit-content;
    padding: 0 16px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  height: fit-content;
  padding: 14px 30px 30px 30px;
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  height: fit-content;
  padding: 14px 30px 30px 30px;
  border-top: 1px solid #e9e9e9;
`;

export const Row = styled.div`
  display: flex;
  padding: 0 30px 30px 30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
