import React, { useState } from 'react';

import Label from '../Label';

import * as S from './styles';

import * as I from './interfaces';

const InputPasswordField: React.FC<I.Props> = ({
  invalid = '',
  width = '115px',
  id,
  labelText,
  isDisabled,
  doNotSave = false,
  ...otherProps
}) => {
  const [toggle, setToggle] = useState(false);
  const error = invalid.trim() !== '';

  const handleVisibility = () => {
    setToggle(!toggle);
  };

  return (
    <S.Container>
      <Label id={id} labelText={labelText} />

      <S.InputContainer>
        <S.Input
          error={error}
          inputWidth={width}
          name={id}
          id={id}
          type={doNotSave || toggle ? 'text' : 'password'}
          password={!toggle}
          disabled={isDisabled}
          autoComplete={doNotSave ? 'off' : 'current-password'}
          {...otherProps}
        />
        {error && (
          <S.ErrorMessage>
            <S.ErrorIcon />
            <p>{invalid}</p>
          </S.ErrorMessage>
        )}
        {!isDisabled && (
          <S.ToggleViewButton onClick={handleVisibility}>
            {toggle ? <S.Eye /> : <S.EyeInvisible />}
          </S.ToggleViewButton>
        )}
      </S.InputContainer>
    </S.Container>
  );
};

export default InputPasswordField;
