import styled from 'styled-components';

export const Container = styled.label`
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin: 16px 0 6px 0;
  user-select: none;
  display: flex;
  align-items: center;
`;

export const Optional = styled.p`
  color: gray;
  font-size: 11px;
  margin: 0 0 0 4px;
  opacity: 0.7;
`;
